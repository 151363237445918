// components/simple-dropzone.component.js
import React, { useEffect, useState } from "react";
import "./css/custom.css";
import mapplaceholder from "../resources/mapplaceholder.svg";
import spinner from "../resources/spinner.gif";
import { ApiFetch, SyncApiFetch } from "../../utils/ApiFetch";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Leaflet = (props) => {
  const [mapAmbientation, setMapAmbientation] = useState({
    ambientation: null,
    mapping: null,
  });
  const [checkedOnlyImages, setCheckedOnlyImages] = React.useState(false);
  const [showWaitMapOver, setShowWaitMapOver] = React.useState(true);

  const useStyles = makeStyles((theme) => ({
    checkbox: {
      "& .MuiIconButton-root:hover": {
        backgroundColor: "transparent",
      },
      "& .Mui-checked:hover .MuiIconButton-root": {
        backgroundColor: "transparent",
      },
      "& .MuiIconButton-label": {
        pointerEvents: "auto",
      },
      "& .MuiIconButton-root": {
        zIndex: 0,
      },
      "& .Mui-checked .MuiIconButton-root": {
        zIndex: 1,
      },
    },
  }));
  const classes = useStyles();

  const handleChangeOnlyImages = (event) => {
    setCheckedOnlyImages(event.target.checked);
  };

  useEffect(() => {
    if (checkedOnlyImages) {
      window.hideMapAmbientations();
    } else {
      window.showMapAmbientations();
    }
  }, [checkedOnlyImages]);

  useEffect(() => {
    //setTimeout(() => {
      //initMap();
    //}, 2000);
  }, []);

  useEffect(() => {
    initMap();
  }, [JSON.stringify(props.images)]);

  useEffect(() => {
    initAmbientation();
  }, [props.samplingId]);

  useEffect(() => {
    if (
      mapAmbientation.ambientation != null ||
      mapAmbientation.sampling != null
    ) {
      initMap();
    }
  }, [JSON.stringify(mapAmbientation)]);

  const initAmbientation = async () => {
    setShowWaitMapOver(true);
    setMapAmbientation({ ambientation: null, sampling: null });
    let ambientation = null;
    try {
      let ret = await SyncApiFetch(
        "api/map/ambientation/" + props.samplingId,
        true,
        {
          method: "GET",
        }
      );
      if (Object.keys(ret).length === 0) {
        ambientation = null;
      } else {
        ambientation = ret;
      }
    } catch (e) {
      ambientation = null;
    }
    let sampling = null;
    try {
      let ret = await SyncApiFetch(
        "api/map/sampling/" + props.samplingId,
        true,
        {
          method: "GET",
        }
      );
      sampling = ret;
    } catch (e) {
      sampling = null;
    }
    setMapAmbientation({ ambientation, sampling });
    setShowWaitMapOver(false);
  };

  const initMap = async () => {
    if (window.initMap) {
      window.initMap(
        props.images,
        (val) => {
          if (props.onClickImage) {
            props.onClickImage(val?.target?.options?.id);
          }
        },
        mapAmbientation.ambientation,
        mapAmbientation.sampling
      );
    }
  };

  const debug = () => {
    window.addCircle([51.502, -0.111]);
  };
  return (
    <>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.3/dist/leaflet.css"
        integrity="sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI="
        crossorigin=""
      />

      <div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                checked={checkedOnlyImages}
                onChange={handleChangeOnlyImages}
              />
            }
            label="Solo imágenes"
          />
        </FormGroup>

        <div
          id="map"
          style={{
            textAlign: "center",
            borderRadius: "4px",
            border: "1px solid",
          }}
        >
          <p
            style={{
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1000,
              display: showWaitMapOver ? " " : "none",
            }}
          >
            <img
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
              }}
              src={spinner}
            />
          </p>
          <p
            style={{
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 0,
              display: "",
            }}
          >
            <img
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
              }}
              src={spinner}
            />
          </p>
        </div>
      </div>
      {/*<button onClick={debug}>Add Circle</button>*/}
    </>
  );
};

export default Leaflet;
