import React, { useEffect, useState, useContext } from "react";
import Helmet from "react-helmet"
import Favicon from "react-favicon";
import favicon from "../components/icons/favico.png"

const Base = () => {
    const [html, setHtml] = useState({__html:'<div class="ext">Hello!</div>'})

    useEffect(() => {
        document.title = 'Nidera Vuela';
    }, []);

    const createMarkup = () =>{ 
    

    fetch('https://experiencianidera.com/apps/nidvdp/QA/nidera_vuela/reportes-detalle-conteo.html')
    .then(async function(response) {
        // When the page is loaded convert it to text
        let htmlTemp = await response.text()
        htmlTemp = htmlTemp.replaceAll("../vendor/","https://experiencianidera.com/apps/nidvdp/QA/vendor/")
        htmlTemp = htmlTemp.replaceAll("../css/","https://experiencianidera.com/apps/nidvdp/QA/css/")
        htmlTemp = htmlTemp.replaceAll("../img/","https://experiencianidera.com/apps/nidvdp/QA/img/")
        htmlTemp += "<script src='http://localhost:3000/static/reports/custom.js'/>"
        console.log(htmlTemp)
        setHtml({__html:htmlTemp})        
        return response.text()
    })
    .catch(function(err) {  
        console.log('Failed to fetch page: ', err);  
    });
    
           
    }

    useEffect(()=>{
        createMarkup();
    },[])

    return (
        <>
        <Favicon url={favicon} />
        <Helmet>
            
        </Helmet>
        <div dangerouslySetInnerHTML={html}></div>
        </>
    );
};

export default Base;
