const SyncApiFetch = async (uri, isAuth, customOptions) => {

  let token = {};

  if (isAuth) {
    let tokenTemp = ""; // await globalContext.bindValidToken(); // todo: implementar token refresh
    tokenTemp = localStorage.getItem("nideravuela2_jwt_token");
    if (tokenTemp == null) {
      return;
    } else {
      token = { Authorization: tokenTemp };
    }
  }

  const defaultOptions = {
    headers: new Headers({
      "Content-Type": "application/json",
      ...token,
    }),
  };

  const options = {
    ...defaultOptions,
    ...customOptions,
  };

  const completeUri = process.env.REACT_APP_API_URL + uri;

  try {
    const response = await fetch(completeUri, options);
    if (response.status >= 200 && response.status < 300) {
      let ret = await response.json(); // todo: implementar errores controlados desde api (menor)
      return ret;
    } else {
      throw { status: response.status };
    }
  } catch (error) {
    throw error;
  }
};

const ApiFetch = async (
  uri,
  isAuth,
  successCallback,
  failCallback,
  customOptions
) => {
  let token = {};

  if (isAuth) {
    let tokenTemp = ""; // await globalContext.bindValidToken(); // todo: implementar token refresh
    tokenTemp = localStorage.getItem("nideravuela2_jwt_token");
    if (tokenTemp == null) {
      return;
    } else {
      token = { Authorization: tokenTemp };
    }
  }

  const defaultOptions = {
    headers: new Headers({
      "Content-Type": "application/json",
      ...token,
    }),
  };

  const options = {
    ...defaultOptions,
    ...customOptions,
  };

  const completeUri = process.env.REACT_APP_API_URL + uri;

  fetch(completeUri, options).then(
    (res) => {
      if (res.status >= 200 && res.status < 300) {
        successCallback(res);
      } else {
        failCallback(res);
      }
    },
    (res) => {
      failCallback(res);
    }
  );
};

const ApiFetchWithoutSession = async (
  uri,
  successCallback,
  failCallback,
  customOptions
) => {
  const defaultOptions = {
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  const options = {
    ...defaultOptions,
    ...customOptions,
  };

  const completeUri = process.env.REACT_APP_API_URL + uri;

  fetch(completeUri, options).then(
    (res) => {
      if (res.status >= 200 && res.status < 300) {
        successCallback(res);
      } else {
        failCallback(res);
      }
    },
    (res) => {
      failCallback(res);
    }
  );
};

const SyncApiFetchWithoutSession = async (uri, customOptions) => {
  const defaultOptions = {
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  const options = {
    ...defaultOptions,
    ...customOptions,
  };

  const completeUri = process.env.REACT_APP_API_URL + uri;

  try {
    const response = await fetch(completeUri, options);
    if (response.status >= 200 && response.status < 300) {
      let ret = await response.json();
      return ret;
    } else {
      throw "error";
    }
  } catch (error) {
    throw error;
  }
};

export {
  ApiFetch,
  ApiFetchWithoutSession,
  SyncApiFetch,
  SyncApiFetchWithoutSession,
};
