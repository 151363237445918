import React, { useEffect, useState, useContext } from "react";
import { ImagesContext } from '../../utils/ImagesContext'
import { useBus, useListener } from 'react-bus'

const Header = (props) => {

  const { lastSyncSeguimientoNidera, globalUploadCount, campanas, campanaSeleccionada, setCampanaSeleccionada } = useContext(ImagesContext);
  const [userName, setUserName] = useState('');
  const [isSyncFields, setIsSyncFields] = useState(false);

  useListener('syncFields', (e) => {
    setIsSyncFields(e);
  })

  useEffect(() => {
    let user = localStorage.getItem('nideravuela2_user')
    if (user !== null) {
      let userObj = JSON.parse(user)
      setUserName(userObj.first_name + " " + userObj.last_name)
    }

  }, [])

  const logout = () => {
    localStorage.removeItem('nideravuela2_jwt_token')
    window.location.replace('/general');
  }


  return (
    <section
      className="page-header fondoheader page-header-color page-header-primary"
      style={{ marginBottom: "0px", paddingBottom: "10px", minHeight: "100px" }}
    >
      <div className="container mb-2">
        <div style={{ marginRight: "-50px" }} className="row text-left">
          <div className="col-md-1">
            <img src="static/img/ic-header.svg" height={50} alt="" />
          </div>
          <div style={{ marginLeft: "-30px" }} className="col-md-2">
            <h1
              style={{ fontSize: "26px !important" }}
              className="mt-2 shadow"
            >
              NIDERA VUELA {/*<small>{props.debug}</small>*/}
            </h1>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start" }} className="col-md-7">
            <div style={{ marginTop: "-4px", lineHeight: "8px" }}>
              <a>Campaña</a><br />
              <select
                disabled={isSyncFields}
                onChange={(e) => {
                  setCampanaSeleccionada(e.target.value)
                }}
                value={campanaSeleccionada} class="form-control my-select-campana altura" style={{ width: "90px", marginRight: "0px", marginTop: "7px" }}>
                {campanas.map((campana) => (
                  <option value={campana.anio}>{campana.label}</option >
                ))}
              </select>
            </div>

          </div>
          <div
            style={{ marginRight: "-20px", marginTop: "-5px" }}
            align="right"
            className="col-md-2"
          >
            <div className="header-column justify-content-end">
              <div className="header-button d-none d-sm-flex">
                <a
                  style={{
                    marginTop: "10px",
                    backgroundColor: "transparent!important",
                    border: "1px solid #ffffff !important"
                  }}
                  //href="https://experiencianidera.com"
                  data-hash=""
                  data-hash-offset={180}
                  className="btn btn-rounded btn-primary btn-icon-effect-1 text-color-light btn-v-3 btn-h-2"
                >
                  <span className="wrap">
                    <span
                      className="text-color-light"
                      style={{ fontWeight: "700" }}
                    >
                      Volver a Experiencia Nidera
                    </span>
                    <i className="lnr lnr-chevron-right" />
                  </span>

                </a>
              </div>
              <div style={{ marginLeft: "25px", textAlign: "left", minWidth: "250px", color: "white" }}>
                <b>{userName}</b> -
                <a
                  style={{ cursor: "pointer" }}
                  onClick={
                    () => {
                      logout();
                    }
                  }>Desconectar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default Header;
