// components/simple-dropzone.component.js
import React, { useEffect, useState } from "react";
import "./css/custom.css";
import "./css/custom.clusters.css";
import spinner from "../resources/spinner.gif";
import { Checkxox, FormGroup, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ApiFetch, SyncApiFetch } from "../../utils/ApiFetch";
import { useBus, useListener } from "react-bus";

const Leaflet = (props) => {
  useListener("reloadMap", (e) => {
    initMap(
      props.geoJsonGeometriesLots,
      props.onClickLot,
      ambientationGlobal,
      samplingGlobal,
      false,
      setShowWaitMapOver
    );
  });
  useListener("resetMap", (e) => {
    initMap(
      props.geoJsonGeometriesLots,
      props.onClickLot,
      null,
      null,
      true,
      setShowWaitMapOver
    );
  });
  useListener("waitMap", (e) => {
    setShowWaitMapOver(e);
  });

  const [checkedOnlyImages, setCheckedOnlyImages] = React.useState(false);
  const [showWaitMapOver, setShowWaitMapOver] = React.useState(false);
  const [ambientationGlobal, setAmbientationGlobal] = React.useState(null);
  const [samplingGlobal, setSamplingGlobal] = React.useState(null);

  const useStyles = makeStyles((theme) => ({
    checkbox: {
      "& .MuiIconButton-root:hover": {
        backgroundColor: "transparent",
      },
      "& .Mui-checked:hover .MuiIconButton-root": {
        backgroundColor: "transparent",
      },
      "& .MuiIconButton-label": {
        pointerEvents: "auto",
      },
      "& .MuiIconButton-root": {
        zIndex: 0,
      },
      "& .Mui-checked .MuiIconButton-root": {
        zIndex: 1,
      },
    },
  }));
  const classes = useStyles();

  const handleChangeOnlyImages = (event) => {
    setCheckedOnlyImages(event.target.checked);
  };

  useEffect(() => {
    if (checkedOnlyImages) {
      if (window.hideMapAmbientationsPrincipal) {
        window.hideMapAmbientationsPrincipal();
      }
    } else {
      if (window.showMapAmbientationsPrincipal) {
        window.showMapAmbientationsPrincipal();
      }
    }
  }, [checkedOnlyImages]);

  useEffect(() => {
    //setTimeout(() => {
      //initMap();
    //}, 2000);
  }, []);

  useEffect(() => {
    initMap(
      props.geoJsonGeometriesLots,
      props.onClickLot,
      null,
      null,
      true,
      setShowWaitMapOver
    );
  }, [JSON.stringify(props.geoJsonGeometriesLots)]);

  useEffect(() => {
    if (props.samplingId !== null) {
      initAmbientation(props.samplingId);
    } else {
      initMap(
        props.geoJsonGeometriesLots,
        props.onClickLot,
        null,
        null,
        false,
        setShowWaitMapOver
      );
    }
  }, [props.samplingId]);

  const initMap = async (
    geoJsonGeometriesLots,
    onClickLot,
    ambientation,
    sampling,
    reset,
    setShowWaitMapOver
  ) => {
    if (window.initMapPrincipal) {
      setShowWaitMapOver(true);
      // timeout para generar el efecto del wait
      try {
        window.initMapPrincipal(
          geoJsonGeometriesLots,
          onClickLot,
          ambientation,
          sampling,
          reset,
          setShowWaitMapOver
        );
      } catch (e) {
        console.log("error en initmap")
      }
    }
  };

  const initAmbientation = async (samplingId) => {
    let ambientation = null;
    try {
      let ret = await SyncApiFetch("api/map/ambientation/" + samplingId, true, {
        method: "GET",
      });
      if (ret.features) {
        ambientation = ret;
      } else {
        ambientation = null;
      }
    } catch (e) {
      ambientation = null;
    }
    let sampling = null;
    try {
      let ret = await SyncApiFetch("api/map/sampling/" + samplingId, true, {
        method: "GET",
      });
      if (ret.features) {
        sampling = ret;
      } else {
        sampling = null;
      }
    } catch (e) {
      sampling = null;
    }
    setAmbientationGlobal(ambientation);
    setSamplingGlobal(sampling);
    initMap(
      props.geoJsonGeometriesLots,
      props.onClickLot,
      ambientation,
      sampling,
      false,
      setShowWaitMapOver
    );
  };

  // todo: traer localmente estas librerias
  return (
    <>
      <div style={{ width: "100%" }}>
        {/*<FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                checked={checkedOnlyImages}
                onChange={handleChangeOnlyImages}
              />
            }
            label="Solo imágenes"
          />
          </FormGroup>*/}

        <div
          id="map_principal"
          style={{
            textAlign: "center",
            borderRadius: "4px",
            border: "1px solid",
          }}
        >
          <p
            style={{
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1000,
              display: showWaitMapOver ? " " : "none",
            }}
          >
            <img
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
              }}
              src={spinner}
            />
          </p>
          <p
            style={{
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 0,
              display: "",
            }}
          >
            <img
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
              }}
              src={spinner}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default Leaflet;
