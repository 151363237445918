import React, { useEffect, useState, useContext } from "react";
import { ApiFetch, SyncApiFetch } from '../../utils/ApiFetch';
import spinner from "../resources/spinner.gif";
import { ImagesContext } from '../../utils/ImagesContext'
import { useBus, useListener } from 'react-bus'
import { statusClass, statusLabel, statusIcon, reportStatusLabel } from '../images/statusConstants'
import Select from 'react-select'

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { makeStyles } from '@mui/styles';
import { esES } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import 'dayjs/locale/es-mx';
import './styles.css'



const useStylesModalReports = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        outline: 'none',
        borderRadius: 10,
        padding: 5,
        height: '95%',
        width: '95%',
    },
    closeButton: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
});





const Reports = (props) => {

    const classesModalReports = useStylesModalReports();

    const bus = useBus()
    const { setUploadersContext, uploadersContext, campanaSeleccionada, esCampanaActiva } = useContext(ImagesContext);

    const [imagesSets, setImagesSets] = useState([])
    const [isSync, setIsSync] = useState(false)
    const [noReportsMessage, setNoReportsMessage] = useState("")


    const [yearsFiltered, setYearsFiltered] = useState([])
    const [clientsFiltered, setClientsFiltered] = useState([])
    const [statusFiltered, setStatusFiltered] = useState([])


    const [filterSelectedStatus, setFilterSelectedStatus] = useState(-1)
    const [filterSelectedClient, setFilterSelectedClient] = useState(-1)
    const [filterSelectedYear, setFilterSelectedYear] = useState(-1)


    const [filterSelectedDateFrom, setFilterSelectedDateFrom] = React.useState(null);
    const [filterSelectedDateTo, setFilterSelectedDateTo] = React.useState(null);

    const [openModalReports, setOpenModalReports] = React.useState(false);
    const [srcIframeReports, setSrcIframeReports] = React.useState("");

    const [updateDisabled, setUpdateDisabled] = React.useState(false);

    const [showWaitIframeLoad, setShowWaitIframeLoad] = React.useState(false);

    const handleOpenModalReports = (image_set_id) => {
        setSrcIframeReports(process.env.REACT_APP_API_URL + "api/report/" + image_set_id)
        setShowWaitIframeLoad(true);
        setOpenModalReports(true);
    }
    const handleCloseModalReports = () => setOpenModalReports(false);

    const handleFilterSelectedYear = (e) => {
        setFilterSelectedYear(e.target.value)
        setFilterSelectedClient(-1)
        setFilterSelectedStatus(-1)
        filterClients(e.target.value)
    }

    const filterClients = (year) => {
        let clientsFiltered = {}
        if (parseInt(year) === -1) {

            let years = []
            for (let year of yearsFiltered) {
                years.push(parseInt(year.year))
            }

            for (let is of imagesSets) {
                if (years.includes(is.sampling_campaign)) {
                    clientsFiltered[is.client] = ({ clientId: is.client, clientName: is.client })
                }
            }


        } else {
            for (let is of imagesSets) {
                if (parseInt(year) === is.sampling_campaign) {
                    clientsFiltered[is.client] = ({ clientId: is.client, clientName: is.client })
                }
            }


        }
        let clientsFilteredRet = []
        for (let client of Object.keys(clientsFiltered)) {
            clientsFilteredRet.push(clientsFiltered[client])
        }
        setClientsFiltered(clientsFilteredRet);
    }

    const hideSpinner = () => {
        setShowWaitIframeLoad(false)
    }

    const filterStatus = (client) => {
        let statusFiltered = {}
        if (parseInt(client) === -1) {

            let clients = []
            for (let client of clientsFiltered) {
                clients.push(client.clientId)
            }

            for (let is of imagesSets) {
                if (clients.includes(is.client)) {
                    statusFiltered[is.processing_status_front] = ({ statusId: is.processing_status_front, statusName: is.processing_status_front })
                }
            }


        } else {
            for (let is of imagesSets) {
                console.log(is);
                if (client === is.client) {
                    statusFiltered[is.processing_status_front] = ({ statusId: is.processing_status_front, statusName: is.processing_status_front })
                }
            }


        }
        let statusFilteredRet = []
        for (let status of Object.keys(statusFiltered)) {
            statusFilteredRet.push(statusFiltered[status])
        }
        setStatusFiltered(statusFilteredRet);
    }

    const handleFilterSelectedClient = (e) => {
        setFilterSelectedClient(e.target.value)
        setFilterSelectedStatus(-1)
        filterStatus(e.target.value)
    }


    const handleFilterSelectedStatus = (e) => {
        setFilterSelectedStatus(e.target.value);
    }

    const setUpdateDisabledCountdown = () => {
        setUpdateDisabled(true);
        setTimeout(() => {
            setUpdateDisabled(false);
        }, 30000)
    }


    const getAllImagesSets = (withUploaderUpdate) => {
        setIsSync(true)
        setImagesSets([]);
        setNoReportsMessage(<img
            style={{ width: "30px", height: "30px", marginRight: "10px" }}
            src={spinner}
        />)
        ApiFetch('api/images_sets/reports/' + campanaSeleccionada,
            true,
            async (res) => {
                let ret = await res.json()
                setImagesSets(ret);

                ret.length === 0 ? setNoReportsMessage("No existen set de imágenes completos para procesar.") : setNoReportsMessage("")
                setIsSync(false)
                if (withUploaderUpdate) {
                    for (let is of ret) {
                        if (is.processing_status_front === 'OK' || is.processing_status_front === 'ERROR') {
                            for (let u of uploadersContext) {
                                if (u.id === is.image_set_id) {
                                    u.status = 'REPORT_GENERATED'
                                }
                            }
                        }
                        if (is.processing_status_front === 'PROCESSING') {
                            for (let u of uploadersContext) {
                                if (u.id === is.image_set_id) {
                                    u.status = 'PROCESSING'
                                }
                            }
                        }
                        if (is.processing_status_front === 'NOT_PROCESSED') {
                            for (let u of uploadersContext) {
                                if (u.id === is.image_set_id) {
                                    u.status = 'UPLOADED'
                                }
                            }
                        }
                        setUploadersContext(uploadersContext)
                    }
                }
            }, (ret) => {
                console.log(ret);
                setIsSync(false)
                setNoReportsMessage("Error al Sincronizar, reintente nuevamente ...")
                // todo: implementar si hay error
            })
    }

    useEffect(() => {
        filterStatus(filterSelectedClient)
        filterClients(filterSelectedYear)
    }, [JSON.stringify(clientsFiltered)])


    useEffect(() => {
        let yearsTemp = {}
        let clientsTemp = {}
        let statusTemp = {}
        for (let isTemp of imagesSets) {
            yearsTemp[isTemp.sampling_campaign] = true;
            clientsTemp[isTemp.client] = true;
            statusTemp[isTemp.processing_status_front] = true
        }
        let yearsArr = Object.keys(yearsTemp);
        let clientsArr = Object.keys(clientsTemp);
        let statusArr = Object.keys(statusTemp);

        let years = [];
        for (let yearTemp of yearsArr) {
            years.push({ year: yearTemp })
        }
        setYearsFiltered(years)

        let clients = [];
        for (let clientTemp of clientsArr) {
            clients.push({ clientId: clientTemp, clientName: clientTemp })
        }
        setClientsFiltered(clients)

        let status = [];
        for (let statusTemp of statusArr) {
            status.push({ statusId: statusTemp, statusName: statusTemp })
        }
        setStatusFiltered(status)

        //setFilterSelectedYear(-1)
        //setFilterSelectedClient(-1)
        //setFilterSelectedStatus(-1)        

    }, [JSON.stringify(imagesSets)])

    useEffect(() => {
        if (uploadersContext.length > 0) {
            //getAllImagesSets(false);
        }
    }, [JSON.stringify(uploadersContext)])

    useListener('refreshReports', (e) => {
        if (e) {
            getAllImagesSets(false);
        }
    })



    const StatusReportIcon = (props) => {
        if (props.status === 'OK') {
            return (<i
                style={{ color: "#28a745" }}
                className="fa fa-check-circle"
                aria-hidden="true"
            />
            )
        }
        if (props.status === 'PROCESSING') {
            return (
                <i
                    style={{ color: "#ffc107" }}
                    className="fa fa-play-circle"
                    aria-hidden="true"
                />
            )
        }
        if (props.status === 'NOT_PROCESSED') {
            return (
                <i
                    style={{ color: "#CCCCCC" }}
                    className="fa fa-stop-circle"
                    aria-hidden="true"
                />
            )
        }
        if (props.status === 'ERROR') {
            return (
                <i
                    style={{ color: "red" }}
                    className="fa fa-exclamation-circle"
                    aria-hidden="true"
                />
            )
        }

    }

    const styleModalReports = {
        position: 'absolute',
        top: '5%',
        left: '5%',
        transform: 'translate(-5%, -5%)',
        width: "90%",
        height: "90%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 0,
        p: 0,
    };


    const useStyles = makeStyles(theme => ({
        "& .MuiInputBase-input": {
            border: "1px solid red",
        }
    }));

    const toLocalDateTime = (arg) => {
        try {
            var offset = new Date().getTimezoneOffset();
            let date = new Date(arg)
            date.setMinutes(date.getMinutes() - offset)
            let dateToString = date.toLocaleDateString('en-CA') + " " + date.toLocaleTimeString('es-AR')
            return dateToString
        } catch (e) {
            return "-";
        }
    }

    return (
        <div style={{ display: props.visible ? '' : 'none' }}>
            {/** CUSTOM HEAD*/}
            <head>
                <link rel="stylesheet" href="static/css/components/reports.css" />
            </head>
            {/** CUSTOM HEAD*/}

            <div className="container">
                {/*
                <div
                    className="tab-pane fade pt-2 pb-0 active show"
                    id="default-process"
                    role="tabpanel"
                    aria-labelledby="default-process-tab"
                >
                    <div className="alert alert-warning">
                        <strong>Importante: </strong>una vez generado el reporte, recordá <b>primero visualizarlo haciendo clic en el ícono del ojo.</b><br />Luego de revisarlo, ingresá a Seguimiento Nidera para generar el reporte y compartirlo. <br />En caso de que suceda un error en la generación del reporte, el Equipo de Soporte te contactará a tu mail.
                    </div>
                </div>
                */}
                <div
                    style={{ paddingLeft: "0px !important", marginTop: "10px" }}
                    ref={(el) => {
                        if (el) {
                            el.style.setProperty('padding-left', '0px', 'important');
                        }
                    }}
                    align="left"
                    className="row"
                >
                    <div
                        style={{ paddingLeft: "5px !important", paddingRight: "0px" }}
                        ref={(el) => {
                            if (el) {
                                el.style.setProperty('padding-left', '5px', 'important');
                            }
                        }}
                        className="col-md-12"
                    >
                        <div className="table-responsive">
                            <table
                                style={{
                                    marginTop: "-5px !important",
                                    marginBottom: "-5px !important"
                                }}
                                ref={(el) => {
                                    if (el) {
                                        el.style.setProperty('margin-top', '-5px', 'important');
                                        el.style.setProperty('margin-bottom', '-5px', 'important');
                                    }
                                }}
                                className="table mb-0"
                            >
                                <thead>
                                    {/**
                    <tr style={{opacity:true?0.5:1, pointerEvents:true?"none":""}}>
                        <th 
                        style={{ borderBottom: "0px !important" }}
                        ref={(el) => {
                            if (el) {                              
                              el.style.setProperty('border-bottom', '0px', 'important');
                            }
                          }}
                        >
                        <select
                            className="form-control my-select altura"
                            name="año"
                            id="año"
                        >
                            <option value="">Año</option>
                            <option value={2022} selected="">
                            2022
                            </option>
                            <option value={2023}>2023</option>
                        </select>
                        </th>
                        <th 
                        style={{ borderBottom: "0px !important" }}
                        ref={(el) => {
                            if (el) {                              
                              el.style.setProperty('border-bottom', '0px', 'important');
                            }
                          }}
                        >
                        <select
                            className="form-control my-select altura"
                            name="region"
                            id="region"
                        >
                            <option value="">Fecha desde</option>
                        </select>
                        </th>
                        <th 
                        style={{ borderBottom: "0px !important" }}
                        ref={(el) => {
                            if (el) {                              
                              el.style.setProperty('border-bottom', '0px', 'important');
                            }
                          }}
                        >
                        <select
                            className="form-control my-select altura"
                            name="region"
                            id="region"
                        >
                            <option value="">Fecha hasta</option>
                        </select>
                        </th>
                        <th 
                        style={{ borderBottom: "0px !important" }}
                        ref={(el) => {
                            if (el) {                              
                              el.style.setProperty('border-bottom', '0px', 'important');
                            }
                          }}
                        >
                        <select
                            className="form-control my-select altura"
                            name="responsable"
                            id="responsable"
                        >
                            <option value="">Cliente</option>
                            <option value={0}>Cliente 1</option>
                            <option value={1}>Cliente 2</option>
                        </select>
                        </th>
                        <th 
                        style={{ borderBottom: "0px !important" }}
                        ref={(el) => {
                            if (el) {                              
                              el.style.setProperty('border-bottom', '0px', 'important');
                            }
                          }}
                        >
                        <select
                            className="form-control my-select altura"
                            name="distribuidor"
                            id="distribuidor"
                        >
                            <option value="">Muestreo</option>
                            <option value={0}>Muestreo 1</option>
                            <option value={1}>Muestreo 2</option>
                        </select>
                        </th>
                        <th 
                        style={{ borderBottom: "0px !important" }}
                        ref={(el) => {
                            if (el) {                              
                              el.style.setProperty('border-bottom', '0px', 'important');
                            }
                          }}
                        >
                        <select
                            className="form-control my-select altura"
                            name="estado"
                            id="Estado"
                        >
                            <option value="">Estado</option>
                            <option value={0}>En proceso</option>
                            <option value={1}>Procesado</option>
                        </select>
                        </th>
                        <th 
                        style={{ maxWidth: "80px", borderBottom: "0px !important" }}
                        ref={(el) => {
                            if (el) {                              
                              el.style.setProperty('border-bottom', '0px', 'important');
                            }
                          }}
                        >
                        <a
                            href="javascript:void(0);"
                            onclick="buscar();"
                            style={{ marginTop: "-1px" }}
                            className="btn btn-dark btn-h-1 btn-v-2 font-weight-semibold text-1 fx animated wow fadeInUp"
                        >
                            FILTRAR
                        </a>
                        </th>
                    </tr>
                     */}
                                    <tr>
                                        {esCampanaActiva() && false &&
                                            <th
                                                style={{ borderBottom: "0px !important" }}
                                                ref={(el) => {
                                                    if (el) {
                                                        el.style.setProperty('border-bottom', '0px', 'important');
                                                    }
                                                }}
                                            >
                                                Año <select
                                                    className="form-control my-select altura"
                                                    name="eje"
                                                    id="eje"
                                                    value={filterSelectedYear}
                                                    onChange={handleFilterSelectedYear}
                                                >
                                                    <option value={-1}>Todos</option>
                                                    {yearsFiltered.map((year) => (
                                                        <option value={year.year}>{year.year}</option>
                                                    ))}
                                                </select>
                                            </th>
                                        }

                                        <th
                                            style={{ borderBottom: "0px !important" }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('border-bottom', '0px', 'important');
                                                }
                                            }}
                                        >
                                            Clientes <select
                                                className="form-control my-select altura"
                                                name="eje"
                                                id="eje"
                                                value={filterSelectedClient}
                                                onChange={handleFilterSelectedClient}
                                            >
                                                <option value={-1}>Todos</option>
                                                {clientsFiltered.map((client) => (
                                                    <option value={client.clientId}>{client.clientName}</option>
                                                ))}
                                            </select>
                                            {/*<Select                            
                            value={
                                parseInt(filterSelectedClient)
                                ===
                                -1
                                ?
                                {value:-1,label:'Todos'}
                                :
                                {value:filterSelectedClient,label:clientsFiltered.find((client) => client.clientId===filterSelectedClient).clientName}
                            }
                            onChange={(value)=>{
                                handleFilterSelectedClient({target:{value:value.value}})                                
                            }}
                            options={
                                [{value:-1,label:'Todos'},...clientsFiltered.map((client)=>(
                                    {value:client.clientId,label:client.clientName}
                                ))]
                            } />
                        */}
                                        </th>
                                        <th
                                            style={{ borderBottom: "0px !important" }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('border-bottom', '0px', 'important');
                                                }
                                            }}
                                        >
                                            Estados<select
                                                className="form-control my-select altura"
                                                name="estado"
                                                id="Estado"
                                                value={filterSelectedStatus}
                                                onChange={handleFilterSelectedStatus}
                                            >
                                                <option value={-1}>Todos</option>
                                                {statusFiltered.map((statusRow) => (
                                                    <option value={statusRow.statusId}>{reportStatusLabel[statusRow.statusName]}</option>
                                                ))}
                                            </select>
                                        </th>
                                        <th
                                            style={{ borderBottom: "0px !important", display: "none" }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('border-bottom', '0px', 'important');
                                                }
                                            }}
                                        >
                                            <input
                                                placeholder="Buscar por cliente"
                                                onkeypress="javascript: if(event.keyCode == 13) buscar();"
                                                type="text"
                                                name="texto"
                                                className="form-control"
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "250px",
                                                    display: "inline-block",
                                                    background: "rgba(0, 0, 0, 0.1)",
                                                    opacity: "1",
                                                    height: "auto !important",
                                                    fontSize: "13px",
                                                    borderRadius: "0.25rem",
                                                    padding: "10px"
                                                }}
                                                ref={(el) => {
                                                    if (el) {
                                                        el.style.setProperty('height', 'auto', 'important');
                                                    }
                                                }}
                                            />
                                        </th>

                                        <th style={{ borderBottom: "0px !important" }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('border-bottom', '0px', 'important');
                                                }
                                            }}>
                                            Desde
                                            {
                                                filterSelectedDateFrom != null
                                                    ?
                                                    <>
                                                        &nbsp;<button
                                                            type="button"
                                                            style={{ cursor: "pointer" }}
                                                            className="btn btn-secondary font-weight-bold btn-1 btn-fs-1  text-1 mb-1"
                                                            onClick={() => {
                                                                setFilterSelectedDateFrom(null);
                                                            }}
                                                        >
                                                            BORRAR
                                                        </button>
                                                    </>
                                                    :
                                                    ''
                                            }

                                            <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>

                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        slotProps={{ textField: { size: "small" } }}
                                                        value={filterSelectedDateFrom}
                                                        className={useStyles}
                                                        //defaultValue={dayjs('2022-04-17')}
                                                        onAccept={(range) => {
                                                            setFilterSelectedDateFrom(range);
                                                        }}
                                                        onChange={(range) => {
                                                            setFilterSelectedDateFrom(range);
                                                        }}

                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>



                                        </th>
                                        <th style={{ borderBottom: "0px !important" }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('border-bottom', '0px', 'important');
                                                }
                                            }}>
                                            Hasta
                                            {
                                                filterSelectedDateTo != null
                                                    ?
                                                    <>
                                                        &nbsp;<button
                                                            type="button"
                                                            style={{ cursor: "pointer" }}
                                                            className="btn btn-secondary font-weight-bold btn-1 btn-fs-1  text-1 mb-1"
                                                            onClick={() => {
                                                                setFilterSelectedDateTo(null);
                                                            }}
                                                        >
                                                            BORRAR
                                                        </button>
                                                    </>
                                                    :
                                                    ''
                                            }

                                            <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        slotProps={{ textField: { size: "small" } }}
                                                        value={filterSelectedDateTo}
                                                        onAccept={(range) => {
                                                            setFilterSelectedDateTo(range);
                                                        }}
                                                        onChange={(range) => {
                                                            setFilterSelectedDateTo(range);
                                                        }}
                                                        className={useStyles}
                                                    //defaultValue={dayjs('2022-04-17')}
                                                    //maxDate={dayjs("2022-12-31")}
                                                    //minDate={dayjs("2022-01-31")}


                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>



                                        </th>

                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    <p />
                </div>
            </div>
            <section
                className="page-header mb-10"
                style={{ backgroundColor: "white", padding: "0px 0" }}
            >
                <div className="container pt-3 mt-2">
                    <div className="row">
                        <div
                            align="left"
                            className="col-md-12 appear-animation animated fadeInLeftShorter appear-animation-visible"
                            data-appear-animation="fadeInLeftShorter"
                            data-appear-animation-delay={100}
                            style={{ animationDelay: "100ms" }}
                        >
                            <div
                                style={{ paddingLeft: "0px", paddingRight: "0px" }}
                                className="col-md-12 mb-2 mb-md-4"
                            >
                                <ul
                                    className="nav nav-tabs nav-tabs-default nav-tabs-primary"
                                    id="tabColorPrimary"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            style={{
                                                color: "#4e5153",
                                                textAlign: "left",
                                                fontWeight: 700
                                            }}
                                            className="nav-link active"
                                        >
                                            Detalle de reportes
                                        </a>
                                    </li>
                                    <div
                                        style={{
                                            textAlign: "right",
                                            marginTop: "10px",
                                            marginLeft: "60px !important",
                                            position: "relative",
                                            left: "75px"
                                        }}
                                        ref={(el) => {
                                            if (el) {
                                                el.style.setProperty('margin-left', '60px', 'important');
                                            }
                                        }}
                                        className="col-md-9 mb-1 mb-md-0"
                                    >
                                        <StatusReportIcon status={"NOT_PROCESSED"} />
                                        <a
                                            style={{
                                                lineHeight: "14px",
                                                marginRight: "10px !important",
                                                fontSize: "14px",
                                                marginLeft: "-3px !important"
                                            }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('margin-left', '-3px', 'important');
                                                    el.style.setProperty('margin-right', '10px', 'important');
                                                }
                                            }}
                                            className="px-1 hidden-xs m-auto"
                                        >
                                            {" "}
                                            Sin Procesar
                                        </a>
                                        <StatusReportIcon status={"PROCESSING"} />
                                        <a
                                            style={{
                                                lineHeight: "14px",
                                                marginRight: "10px !important",
                                                marginLeft: "-3px !important",
                                                fontSize: "14px"
                                            }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('margin-left', '-3px', 'important');
                                                    el.style.setProperty('margin-right', '10px', 'important');
                                                }
                                            }}
                                            className="px-1 hidden-xs m-auto"
                                        >
                                            {" "}
                                            En Proceso
                                        </a>
                                        <StatusReportIcon status={"OK"} />
                                        <a
                                            style={{
                                                lineHeight: "14px",
                                                marginRight: "10px !important",
                                                fontSize: "14px",
                                                marginLeft: "-3px !important"
                                            }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('margin-left', '-3px', 'important');
                                                    el.style.setProperty('margin-right', '10px', 'important');
                                                }
                                            }}
                                            className="px-1 hidden-xs m-auto"
                                        >
                                            {" "}
                                            Procesado
                                        </a>

                                        <StatusReportIcon status={"ERROR"} />
                                        <a
                                            style={{
                                                lineHeight: "14px",
                                                marginRight: "10px !important",
                                                marginLeft: "-3px !important",
                                                fontSize: "14px"
                                            }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('margin-left', '-3px', 'important');
                                                    el.style.setProperty('margin-right', '10px', 'important');
                                                }
                                            }}
                                            className="px-1 hidden-xs m-auto"
                                        >
                                            {" "}
                                            Error
                                        </a>
                                        {/*<i
                        style={{ color: "black" }}
                        className="lnr lnr-location"
                        aria-hidden="true"
                        />
                        <a
                        style={{
                            lineHeight: "14px",
                            marginRight: "10px !important",
                            marginLeft: "-3px !important",
                            fontSize: "14px"
                        }}
                        ref={(el) => {
                            if (el) {                              
                              el.style.setProperty('margin-left', '-3px', 'important');
                              el.style.setProperty('margin-right', '10px', 'important');
                            }
                          }}
                        className="px-1 hidden-xs m-auto"
                        >
                        {" "}
                        Compartido
                        </a>
                        <i
                        style={{ color: "#dc3545" }}
                        className="lnr lnr-download"
                        aria-hidden="true"
                        />
                        <a
                        style={{
                            lineHeight: "14px",
                            marginRight: "10px !important",
                            fontSize: "14px",
                            marginLeft: "-3px !important"
                        }}
                        ref={(el) => {
                            if (el) {                              
                              el.style.setProperty('margin-left', '-3px', 'important');
                              el.style.setProperty('margin-right', '10px', 'important');
                            }
                          }}
                        className="px-1 hidden-xs m-auto"
                        >
                        {" "}
                        Descargado
                        </a>
                        */}

                                        {esCampanaActiva() &&
                                            <button
                                                type="button"
                                                disabled={updateDisabled}
                                                onClick={() => {
                                                    getAllImagesSets(true);
                                                    setUpdateDisabledCountdown();
                                                }}
                                                className={"btn align-items-center line-height-1 py-2 mb-0 mt-0 mr-1 " + (isSync ? 'btn-warning' : 'btn-success')}
                                                style={{ cursor: updateDisabled ? 'text' : 'pointer' }}
                                            >
                                                {!isSync &&
                                                    <i
                                                        style={{ color: "#1c1f21 !important", textAlign: "center" }}
                                                        ref={(el) => {
                                                            if (el) {
                                                                el.style.setProperty('color', '#1c1f21', 'important');
                                                            }
                                                        }}
                                                        className="fa fa-sync mr-3"
                                                    />
                                                }
                                                {isSync &&
                                                    <img
                                                        style={{ width: "14px", height: "14px", marginRight: "10px" }}
                                                        src={spinner}
                                                    />
                                                }
                                                {" "}
                                                <div>
                                                    {isSync ? 'Actualizando' : 'Actualizar'}<br />
                                                </div>
                                            </button>
                                        }
                                    </div>
                                </ul>
                                <div className="tab-content" id="tabColorPrimaryContent">
                                    <div
                                        className="tab-pane fade pt-2 pb-4 active show"
                                        id="color-primary-categoria"
                                        role="tabpanel"
                                        aria-labelledby="color-primary-categoria-tab"
                                    >
                                        <div className="table-responsive">
                                            <table className="shop-cart-table w-100">
                                                <thead>
                                                    <tr>
                                                        <th className="product">ID</th>
                                                        <th className="product">Cliente</th>
                                                        <th className="product">Tipo Muestreo</th>
                                                        <th className="product">Campo</th>
                                                        <th style={{ minWidth: "80px" }} className="product">
                                                            Lote
                                                        </th>
                                                        <th style={{ minWidth: "60px" }} className="product">
                                                            Fecha de generación
                                                        </th>
                                                        <th className="product">Tipo de reporte</th>
                                                        <th className="product">
                                                            <strong />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {imagesSets.map(
                                                        (imagesSet) => {
                                                            if (imagesSet.processing_status_front === filterSelectedStatus || parseInt(filterSelectedStatus) === -1) {
                                                                if (imagesSet.client === filterSelectedClient || parseInt(filterSelectedClient) === -1) {
                                                                    if (parseInt(imagesSet.sampling_campaign) === parseInt(filterSelectedYear) || parseInt(filterSelectedYear) === -1) {
                                                                        if (dayjs(filterSelectedDateFrom).isValid() === false || filterSelectedDateFrom == null || dayjs(imagesSet.creation_datetime.substring(0, 10)).isAfter(filterSelectedDateFrom)) {
                                                                            if (dayjs(filterSelectedDateTo).isValid() === false || filterSelectedDateTo == null || dayjs(imagesSet.creation_datetime.substring(0, 10)).isBefore(filterSelectedDateTo)) {
                                                                                return (
                                                                                    <tr className="cart-item">
                                                                                        <td className="product">
                                                                                            <a>
                                                                                                {" "}
                                                                                                <StatusReportIcon status={imagesSet.processing_status_front == 'NOT_PROCESSED' ? 'PROCESSING' : imagesSet.processing_status_front} />
                                                                                                {" "}
                                                                                                #{imagesSet.report_id}
                                                                                            </a>
                                                                                        </td>
                                                                                        <td className="product">
                                                                                            <button
                                                                                                style={{
                                                                                                    backgroundColor: "#e1e1e1",
                                                                                                    borderColor: "#e1e1e1",
                                                                                                    color: "black",
                                                                                                    fontWeight: "600",
                                                                                                    cursor: "text"
                                                                                                }}
                                                                                                type="button"
                                                                                                className="btn btn-primary align-items-center line-height-1 py-1 mb-0 mt-2 mr-1"
                                                                                            >
                                                                                                {imagesSet.client}{" "}
                                                                                            </button>
                                                                                        </td>
                                                                                        <td className="product">
                                                                                            <a>{imagesSet.sampling_name}</a>
                                                                                            <br />
                                                                                            <small>{imagesSet.sampling_crop_name} - {imagesSet.sampling_genotype_name}</small>
                                                                                        </td>
                                                                                        <td className="product">
                                                                                            <a>{imagesSet.farm_name}</a>
                                                                                        </td>
                                                                                        <td className="product">
                                                                                            <a>{imagesSet.lot_name}</a>
                                                                                        </td>
                                                                                        <td className="product">
                                                                                            <a>{toLocalDateTime(imagesSet.creation_datetime)}</a>
                                                                                        </td>
                                                                                        <td className="product">
                                                                                            <button
                                                                                                type="button"
                                                                                                style={{ cursor: "text" }}
                                                                                                className="btn btn-warning font-weight-bold btn-1 btn-fs-1  text-1 mb-0"
                                                                                            >
                                                                                                CONTEO DE PLANTAS
                                                                                            </button>{" "}
                                                                                            {imagesSet.processing_status_front === 'OK' &&

                                                                                                <a style={{ cursor: "pointer", verticalAlign: "middle" }}>
                                                                                                    <i
                                                                                                        onClick={() => {
                                                                                                            handleOpenModalReports(imagesSet.image_set_id)
                                                                                                        }}
                                                                                                        style={{ color: "black", fontSize: "18px" }}
                                                                                                        className="lnr lnr-eye ml-2 mr-2"
                                                                                                        aria-hidden="true"
                                                                                                    />{" "}
                                                                                                </a>

                                                                                            }

                                                                                            {/*
                                            <a href={process.env.REACT_APP_API_URL + "api/map/report/"+imagesSet.image_set_id} target="_blank"
                                                style={{marginTop:"12px"}}>
                                                <i
                                                    style={{ color: "#dc3545", cursor:"pointer" }}
                                                    className="lnr lnr-download"
                                                    aria-hidden="true"
                                                />
                                            </a>
                                            */}

                                                                                        </td>



                                                                                        <td className="product">
                                                                                            {imagesSet.processing_status_front === 'OK' && false &&
                                                                                                <a
                                                                                                    style={{ cursor: "text" }}
                                                                                                    href="reportes-detalle.html">
                                                                                                    {" "}
                                                                                                    <i
                                                                                                        style={{ fontSize: "16px" }}
                                                                                                        className="fas fa-plus-circle"
                                                                                                    />
                                                                                                </a>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )

                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    )
                                                    }

                                                    {/**
                            <tr className="cart-item">
                                <td className="product">
                                <a>
                                    {" "}
                                    <i
                                    style={{ color: "#28a745" }}
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                    />{" "}
                                    #42
                                </a>
                                </td>
                                <td className="product">
                                <button
                                    style={{
                                    backgroundColor: "#e1e1e1",
                                    borderColor: "#e1e1e1",
                                    color: "black",
                                    fontWeight: "600"
                                    }}
                                    type="button"
                                    className="btn btn-primary align-items-center line-height-1 py-1 mb-0 mt-2 mr-1"
                                >
                                    SIERRAS DE SAN ANTONIO S.A.
                                </button>
                                </td>
                                <td className="product">
                                <a>I. REPRODUCTIVO MAÍZ</a>
                                <br />
                                <small>AX7761VT3PRO</small>
                                </td>
                                <td className="product">
                                <a>Juan Real</a>
                                </td>
                                <td className="product">
                                <a>3</a>
                                </td>
                                <td className="product">
                                <a>07/12/2022</a>
                                </td>
                                <td className="product">
                                <button
                                    type="button"
                                    className="btn btn-success font-weight-bold btn-1 btn-fs-1  text-1 mb-0"
                                >
                                    COBERTURA DE MALEZAS
                                </button>{" "}
                                <i
                                    style={{ color: "black" }}
                                    className="lnr lnr-location ml-2 mr-2"
                                    aria-hidden="true"
                                />{" "}
                                <i
                                    style={{ color: "#dc3545" }}
                                    className="lnr lnr-download"
                                    aria-hidden="true"
                                />
                                </td>
                                <td className="product">
                                <a href="reportes-detalle.html">
                                    {" "}
                                    <i
                                    style={{ fontSize: "16px" }}
                                    className="fas fa-plus-circle"
                                    />
                                </a>
                                </td>
                            </tr>
                            <tr className="cart-item">
                                <td className="product">
                                <a>
                                    {" "}
                                    <i
                                    style={{ color: "#ffc107" }}
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                    />{" "}
                                    #37
                                </a>
                                </td>
                                <td className="product">
                                <button
                                    style={{
                                    backgroundColor: "#e1e1e1",
                                    borderColor: "#e1e1e1",
                                    color: "black",
                                    fontWeight: "600"
                                    }}
                                    type="button"
                                    className="btn btn-primary align-items-center line-height-1 py-1 mb-0 mt-2 mr-1"
                                >
                                    CIGNETTI HNOS.S.A.
                                </button>
                                </td>
                                <td className="product">
                                <a>I. VEGETATIVO MAÍZ/GIRASOL</a>
                                <br />
                                <small>AX7761VT3PRO</small>
                                </td>
                                <td className="product">
                                <a>Las Marías</a>
                                </td>
                                <td className="product">
                                <a>12</a>
                                </td>
                                <td className="product">
                                <a>23/11/2022</a>
                                </td>
                                <td className="product">
                                <button
                                    type="button"
                                    className="btn btn-info font-weight-bold btn-1 btn-fs-1  text-1 mb-0"
                                >
                                    ALTIMETRÍA
                                </button>
                                </td>
                                <td className="product">
                                <a href="#"></a>
                                </td>
                            </tr>
                            <tr className="cart-item">
                                <td className="product">
                                <a>
                                    {" "}
                                    <i
                                    style={{ color: "#ffc107" }}
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                    />{" "}
                                    #33
                                </a>
                                </td>
                                <td className="product">
                                <button
                                    style={{
                                    backgroundColor: "#e1e1e1",
                                    borderColor: "#e1e1e1",
                                    color: "black",
                                    fontWeight: "600"
                                    }}
                                    type="button"
                                    className="btn btn-primary align-items-center line-height-1 py-1 mb-0 mt-2 mr-1"
                                >
                                    REY MARCELO FERNANDO
                                </button>
                                </td>
                                <td className="product">
                                <a>I. REPRODUCTIVO MAÍZ</a>
                                <br />
                                <small>AX7761VT3PRO</small>
                                </td>
                                <td className="product">
                                <a>S</a>
                                </td>
                                <td className="product">
                                <a>Lote 1</a>
                                </td>
                                <td className="product">
                                <a>20/11/2022</a>
                                </td>
                                <td className="product">
                                <button
                                    type="button"
                                    className="btn btn-success font-weight-bold btn-1 btn-fs-1  text-1 mb-0"
                                >
                                    COBERTURA DE MALEZAS
                                </button>
                                </td>
                                <td className="product"></td>
                            </tr>
                            <tr className="cart-item">
                                <td className="product">
                                <a>
                                    {" "}
                                    <i
                                    style={{ color: "#28a745" }}
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                    />{" "}
                                    #27
                                </a>
                                </td>
                                <td className="product">
                                <button
                                    style={{
                                    backgroundColor: "#e1e1e1",
                                    borderColor: "#e1e1e1",
                                    color: "black",
                                    fontWeight: "600"
                                    }}
                                    type="button"
                                    className="btn btn-primary align-items-center line-height-1 py-1 mb-0 mt-2 mr-1"
                                >
                                    MAULION AGROPECUARIA S.A
                                </button>
                                </td>
                                <td className="product">
                                <a>I. VEGETATIVO MAÍZ/GIRASOL</a>
                                <br />
                                <small>AX7761VT3PRO</small>
                                </td>
                                <td className="product">
                                <a>MAULION</a>
                                </td>
                                <td className="product">
                                <a>FRIGORIFICO</a>
                                </td>
                                <td className="product">
                                <a>17/12/2022</a>
                                </td>
                                <td className="product">
                                <button
                                    type="button"
                                    className="btn btn-quaternary font-weight-bold btn-1 btn-fs-1  text-1 mb-0"
                                >
                                    NDVI
                                </button>{" "}
                                <i
                                    style={{ color: "black" }}
                                    className="lnr lnr-location ml-2 mr-2"
                                    aria-hidden="true"
                                />{" "}
                                <i
                                    style={{ color: "#dc3545" }}
                                    className="lnr lnr-download"
                                    aria-hidden="true"
                                />
                                </td>
                                <td className="product">
                                <a href="reportes-detalle.html">
                                    {" "}
                                    <i
                                    style={{ fontSize: "16px" }}
                                    className="fas fa-plus-circle"
                                    />
                                </a>
                                </td>
                            </tr>
                             */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            align="center"
                            style={{
                                textAlign: "center",
                                marginTop: "-10px !important",
                                margin: "auto"
                            }}
                            ref={(el) => {
                                if (el) {
                                    el.style.setProperty('margin-top', '-10px', 'important');
                                }
                            }}
                        >
                            {/** 
                <button
                    style={{ paddingTop: "10px", height: "42px !important" }}
                    ref={(el) => {
                        if (el) {                              
                          el.style.setProperty('height', '42px', 'important');                          
                        }
                      }}
                    className="btn-outline btn-dark altura"
                    id="btn-ver-mas"
                    type="button"
                >
                    MOSTRAR MÁS REPORTES
                </button>
                */}
                            {imagesSets.length === 0 &&
                                <>
                                    <h5>{noReportsMessage}</h5>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>


            <div>

                <Modal
                    open={openModalReports}
                    onClose={handleCloseModalReports}
                    className={classesModalReports.modal}
                >
                    <div className={classesModalReports.modalContent}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                position: 'absolute',
                                top: 25,
                                right: 45,
                                zIndex: "9999"
                            }}
                            onClick={handleCloseModalReports}
                        >
                            X
                        </Button>
                        {
                            showWaitIframeLoad
                                ?
                                <p
                                    style={{
                                        position: "relative",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        zIndex: 1000,
                                        textAlign: "center"
                                        //display: showWaitMapOver ? " " : "none",
                                    }}
                                >
                                    <img
                                        style={{ width: "50px", height: "50px", marginRight: "10px" }}
                                        src={spinner}
                                    />
                                </p>
                                :
                                ""
                        }
                        <iframe
                            onLoad={hideSpinner}
                            style={{
                                position: "fixed",
                                //top:"0", 
                                //left:"0", 
                                //bottom:"0", 
                                //right:"0", 
                                width: "93%",
                                height: "90%",
                                border: "none",
                                //margin:"0", 
                                //padding:"0", 
                                //overflow:"hidden", 
                                zIndex: "999"
                            }}

                            src={srcIframeReports}></iframe>
                    </div>
                </Modal>


            </div>

        </div>
    );
};

export default Reports;  
