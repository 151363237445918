import React, { useEffect, useState, useContext } from "react";
import {ImagesContext} from '../../utils/ImagesContext'
import spinner from '../resources/spinner.gif'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageLoadStatus = (props) => {
    const { globalUploadCount, globalCancelUploaderFlag, globalInitUploadFlag } = useContext(ImagesContext);
    const [showFinalizedMessage, setShowFinalizedMessage] = useState(false)
    const [totalUploaded, setTotalUploaded] = useState(0)
    

    useEffect(()=>{

      if (
          (
            (globalUploadCount.total===globalUploadCount.uploaded) 
            || 
            (globalUploadCount.uploading===0)
          ) 
          &&
          globalInitUploadFlag === true
      )
      {
        setShowFinalizedMessage(true);
      }  

    },[globalUploadCount.total,globalUploadCount.uploaded,globalUploadCount.uploading])


    useEffect(()=>{

      //if (((globalUploadCount.total===globalUploadCount.uploaded) && globalUploadCount.total>0)) {
      //  setShowFinalizedMessage(true);
      //  
      //} else {
      //  setTotalUploaded(globalUploadCount.total)
      //}
      setTotalUploaded(globalUploadCount.total)

    },[globalUploadCount.uploading])


    return (   
    <>

  <Dialog
        //open={showFinalizedMessage}
        open={false}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>{}}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>{"Proceso de Carga"}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {globalCancelUploaderFlag===false &&
            <>
            La carga del/de los set/s de imágenes ha finalizado correctamente.
            </>
          }
          {globalCancelUploaderFlag &&
            <>
            Se han cancelado todas las cargas en curso.
            </>
          }
        </DialogContentText>
        </DialogContent>
        <DialogActions>        
        <Button onClick={
                ()=>{
                  setShowFinalizedMessage(false);
                }
            }>Aceptar</Button>
        </DialogActions>
    </Dialog>

    <div 
    id="floating-button" 
    style={{cursor:"text",display:((globalUploadCount.total===globalUploadCount.uploaded) || (globalUploadCount.uploading===0))?'none':''}}
    >
      {globalUploadCount.uploading>0 &&
        <img style={{width:"18px",height:"18px",marginRight: "10px"}} src={spinner}/>
      }
      {globalUploadCount.uploading===0 &&
        <div style={{marginRight:"10px"}}></div>
      }
      <span>
        Cargando imágenes... <span style={{ fontWeight: "600" }}>( {globalUploadCount.uploading} {"restante"}{globalUploadCount.uploading>1?"s":""} )
          </span>
      </span>
      {false && <>({globalUploadCount.uploaded}/{globalUploadCount.total})</>}
    </div>
    <div id="left-panel">
      <div style={{ padding: "10px 15px" }} className="row mt-0">
        <div style={{ marginTop: 4 }} className="col-md-12">
          <button
            style={{ textDecoration: "none !important" }}
            className="btn btn-sm  btn-block text-center shadow-none"
            type="button"
          >
            <strong>Estado de carga de imágenes:</strong>
          </button>
        </div>
      </div>
      <div style={{ padding: "0px 15px" }} className="row mt-0">
        <div style={{ marginTop: "4px" }} className="col-md-12">
          <p
            style={{
              color: "#4e5153",
              textAlign: "left",
              paddingLeft: "0px !important"
            }}
            className="nav-link mb-0"
          >
            Set » Carga Vuelo Lote Central 10/01{" "}
          </p>
          <strong>Imágenes totales: </strong>100.
          <br />
          <strong>Imágenes cargadas: </strong>45.
          <br />
        </div>
        <div className="col-md-12 mt-1">
          <p className="mb-1">Progreso de carga:</p>
          <div className="progress-bar-wrapper">
            <div className="progress mb-2">
              <div
                className="progress-bar bg-warning"
                role="progressbar"
                data-to={45}
                data-delay={500}
                aria-valuenow={45}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: "45%", height: "25px", color: "black" }}
              >
                <strong>45%</strong>
              </div>
            </div>
          </div>
          <p className="mb-1">
            <i
              style={{ color: "#1c1f21 !important" }}
              className="lnr lnr-history mr-1"
            />{" "}
            <strong>Inicio carga:</strong> 15/03/2021 10:45
            <br />
          </p>
          <a href="carga-imagenes-cargando.html">
            <button
              type="button"
              className="btn floating-buttons-map btn-rounded  align-items-center btn-grey line-height-1 py-1 mb-3 mt-0 mr-1"
            >
              <i
                style={{ color: "#1c1f21 !important" }}
                className="fa fa-plus-circle mr-1"
              />{" "}
              Ver detalle
            </button>
          </a>
          <hr
            style={{
              height: "0.2px !important",
              textAlign: "left",
              marginLeft: "-1px"
            }}
          />
        </div>
      </div>
      <div style={{ padding: "0px 15px" }} className="row mt-0">
        <div style={{ marginTop: "4px" }} className="col-md-12">
          <p
            style={{
              color: "#4e5153",
              textAlign: "left",
              paddingLeft: "0px !important"
            }}
            className="nav-link mb-0"
          >
            Set » Carga Vuelo Lote Azul{" "}
          </p>
          <strong>Imágenes totales: </strong>60.
          <br />
          <strong>Imágenes cargadas: </strong>52.
          <br />
        </div>
        <div className="col-md-12 mt-1">
          <p className="mb-1">Progreso de carga:</p>
          <div className="progress-bar-wrapper">
            <div className="progress mb-2">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                data-to={45}
                data-delay={500}
                aria-valuenow={87}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: "87%", height: "25px", color: "white" }}
              >
                <strong>87%</strong>
              </div>
            </div>
          </div>
          <p className="mb-1">
            <i
              style={{ color: "#1c1f21 !important" }}
              className="lnr lnr-history mr-1"
            />{" "}
            <strong>Inicio carga:</strong> 15/03/2021 11:23
            <br />
          </p>
          <a href="carga-imagenes-cargando.html">
            <button
              type="button"
              className="btn floating-buttons-map btn-rounded  align-items-center btn-grey line-height-1 py-1 mb-3 mt-0 mr-1"
            >
              <i
                style={{ color: "#1c1f21 !important" }}
                className="fa fa-plus-circle mr-1"
              />{" "}
              Ver detalle
            </button>
          </a>
          <hr
            style={{
              height: "0.2px !important",
              textAlign: "left",
              marginLeft: "-1px"
            }}
          />
        </div>
      </div>
    </div>
    </>

);
};

export default ImageLoadStatus;