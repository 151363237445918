import React, { useEffect, useState, useContext } from "react";
import { useBus, useListener } from 'react-bus'
import {ImagesContext} from '../../utils/ImagesContext'
import { ApiFetch, SyncApiFetch } from "../../utils/ApiFetch";

const Sync = (props) => {
  
  const {setLastSyncSeguimientoNidera} = useContext(ImagesContext);  
  const bus = useBus()
  
  const [isSync, setIsSync] = useState(false)
  
  // lo defino para los casos en que la sincronizacion se inicio previo a una recarga general
  const [isSyncProcessing, setIsSyncProcessing] = useState(false)

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const syncSeguimientoNidera = async (actualStatus)=>{
    const res = await SyncApiFetch(
      "api/isSyncSeguimientoNidera",
      true,
      {
        method: "GET",
      }
    );
    if (res.length===0) {
      setIsSync(true)
      bus.emit('syncSeguimientoNidera', 'new')
      setIsSyncProcessing(false)
    }
    if (res.length>0) {
      if (res[0].status==='PROCESSING') {
        setIsSync(true)
        setLastSyncSeguimientoNidera(res[0]) 
        bus.emit('syncSeguimientoNidera', 'processing')     
        setIsSyncProcessing(true)
        await delay(10000);
        syncSeguimientoNidera('processing')
      } else {
        if (actualStatus==='processing') {
          // solo ejecuta la recarga cuando la plataforma habia sido puesta en espera.
          bus.emit('reloadFieldsAndImagesSets', true)  
        }
        setIsSync(false)
        setLastSyncSeguimientoNidera(res[0]) 
        setIsSyncProcessing(false)     
        bus.emit('syncSeguimientoNidera', 'processed')
        
      }
    }
  }

  // se ejecuta cuando viene de otra session (o simple F5). Es recursivo y ni bien detecta fin de ejecucion cancela
  /*const intervalExe = () => {
    if (isSyncProcessing) {
      setTimeout(()=>{      
          syncSeguimientoNidera()
          intervalExe();  
      },1000)
    }
  }*/


  /*useEffect(()=>{
    if (isSyncProcessing) {
      intervalExe();
    }
  },[isSyncProcessing])*/

  useEffect(()=>{syncSeguimientoNidera('init')},[])

  useListener('syncSeguimientoNidera', (e)=>{
    if (e==='new') {
      setIsSync(e)
      ApiFetch(
        "api/syncSeguimientoNidera",
        true,
        async (res)=>{
          let obj = await res.json()
          if (obj.length>0) {            
            setIsSync(false)
            bus.emit('syncSeguimientoNidera', 'processed')
            bus.emit('reloadFieldsAndImagesSets', true)   
            setLastSyncSeguimientoNidera(obj[0])      
          } 
        },
        (res)=>{
          // todo: implementar cuando de error.      
          console.log(res);
        }        
      );      
    }
  })  
  //bus.emit('changeMenu', option)

  
  
  
  return (    
    <></>
  
  );
};

export default Sync;
