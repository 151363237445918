import React, { useEffect, useState } from "react";

const Head = (props) => {    
    
    const cssPaths = [
      'static/vendor/bootstrap/css/bootstrap.min.css',
      'static/vendor/font-awesome/css/fontawesome-all.min.css',
      'static/vendor/animate/animate.min.css',
      'static/vendor/linear-icons/css/linear-icons.min.css',
      'static/vendor/owl.carousel/assets/owl.carousel.min.css',
      'static/vendor/owl.carousel/assets/owl.theme.default.min.css',
      'static/vendor/magnific-popup/magnific-popup.min.css',
      'static/css/theme.css',
      'static/css/theme-elements.css',
      'static/vendor/rs-plugin/css/settings.css',
      'static/vendor/rs-plugin/css/layers.css',
      'static/vendor/rs-plugin/css/navigation.css',
      'static/css/skins/default.css?v=5',
      'static/css/proyectoscustom.css?v=6',
      'static/leafletlabels/leaflet.label.css',
      'https://unpkg.com/leaflet@1.9.3/dist/leaflet.css',
      'https://unpkg.com/leaflet.markercluster@1.4.1/dist/MarkerCluster.css'

    ]

    useEffect(()=>{
      props.setCssTotal(cssPaths.length)
    },[])
    
    return (    
<head>
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <title>Experiencia Nidera | Nidera Vuela</title>
    <meta name="keywords" content="Experiencia Nidera | Nidera Vuela" />
    <meta name="description" content="Experiencia Nidera | Nidera Vuela" />
    <meta name="author" content="Experiencia Nidera | Nidera Vuela" />
    <meta name="robots" content="noindex,nofollow" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, minimum-scale=1.0, shrink-to-fit=no"
    />    
    <link
      href="https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900"
      rel="stylesheet"
    />    
    {cssPaths.map((path)=>(
      <link rel="stylesheet" href={path} onLoad={()=>{props.onLoadCss()}} />    
    ))}
    
    <link
      rel="icon"
      href="https://www.experiencianidera.com/apps/images/favicon_new.png"
      type="image/x-icon"
    />
   
</head>

);
};

export default Head;