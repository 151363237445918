import React, { useEffect, useState, useContext } from "react";
import Leaflet from "../mapprincipal/leaflet"
import { ApiFetch, SyncApiFetch } from '../../utils/ApiFetch';
import { statusClass, statusLabel, statusIcon, statusIconLabel } from '../images/statusConstants'
import { ImagesContext } from '../../utils/ImagesContext'
import { useBus, useListener } from 'react-bus'
import spinner from "../resources/spinner.gif";

const General = (props) => {
  const bus = useBus()

  const SpinnerTag = (props) => (
    <img
      style={{
        width: props.size + "px",
        height: props.size + "px",
        marginRight: "0px",
      }}
      src={spinner}
    />
  )

  const { fields, uploadersContext, sincronizedUploadersContext, campanaSeleccionada, esCampanaActiva } = useContext(ImagesContext);
  const [debugInteger, setDebugInteger] = useState(0);
  const [geoJsonLots, setGeoJsonLots] = useState([])
  const [geoJsonLotsFiltered, setGeoJsonLotsFiltered] = useState([])
  const [selectedLot, setSelectedLot] = useState(null)
  const [samplingIdChecked, setSamplingIdChecked] = useState(null)

  const [lots, setLots] = useState([])
  const [farms, setFarms] = useState([])
  const [clients, setClients] = useState([])
  const [status, setStatus] = useState([])

  const [lotsFiltered, setLotsFiltered] = useState([])
  const [farmsFiltered, setFarmsFiltered] = useState([])
  const [clientsFiltered, setClientsFiltered] = useState([])
  const [statusFiltered, setStatusFiltered] = useState([])

  const [filterSelectedStatus, setFilterSelectedStatus] = useState(-1)
  const [filterSelectedLot, setFilterSelectedLot] = useState(-1)
  const [filterSelectedFarm, setFilterSelectedFarm] = useState(-1)
  const [filterSelectedClient, setFilterSelectedClient] = useState(-1)

  const [countStatus, setCountStatus] = useState({})
  const [associatedSamplings, setAssociatedSamplings] = useState(<SpinnerTag size="20" />)
  const [countImagesProcessed, setCountImagesProcessed] = useState(<SpinnerTag size="20" />)
  const [countImagesProcessing, setCountImagesProcessing] = useState(<SpinnerTag size="20" />)
  const [associatedLots, setAssociatedLots] = useState(<SpinnerTag size="20" />)
  const [imagesSetCompleted, setImagesSetCompleted] = useState(<SpinnerTag size="20" />)
  const [reportsGenerated, setReportsGenerated] = useState(<SpinnerTag size="20" />)



  useEffect(() => {
    /*setTimeout(()=>{
        let debugIntegerTemp = debugInteger;
        debugIntegerTemp++;
        setDebugInteger(debugIntegerTemp)
    },1000)*/
  }, [debugInteger]);

  useEffect(() => {
    setCountImagesProcessed(<SpinnerTag size="20" />);
    setCountImagesProcessing(<SpinnerTag size="20" />);

    ApiFetch('api/images_processing_stats/' + campanaSeleccionada, true,
      async (ret) => {
        let obj = await ret.json()
        setCountImagesProcessed(obj.countProcessed);
        setCountImagesProcessing(obj.countProcessing);
      }, (ret) => {
        console.log(ret);
        // todo: implementar
      })
  }, [imagesSetCompleted, reportsGenerated])

  useEffect(() => {
    if (samplingIdChecked !== null) {

    }
  }, [samplingIdChecked]);

  useEffect(() => {
    setDebugInteger(1)
  }, []);

  useEffect(() => {
    if (props.visible) {
      bus.emit("reloadMap", true);
    }
  }, [props.visible])

  useEffect(() => {
    let geoJsonLotsTemp = []
    let clients = {}
    let farms = {}
    let lots = {}
    let samplings = {}

    let clientsRet = []
    let farmsRet = []
    let lotsRet = []

    for (let field of fields) {
      for (let lot of field.lots) {

        clients[field.client] = { clientId: field.client, clientName: field.client }
        farms[field.farm_id] = { farmId: field.farm_id, farmName: field.farm_name, clientId: field.client }
        lots[lot.lot_id] = { lotId: lot.lot_id, lotName: lot.lot_name, farmId: field.farm_id }

        let lotExt = { ...lot }
        let fieldMin = { ...field }
        delete fieldMin.lots
        lotExt["field"] = fieldMin

        geoJsonLotsTemp.push({ geometry: lot.geojson, properties: lotExt })

        if (lot.samplings) {
          for (let samplingTemp of lot.samplings) {
            samplings[samplingTemp.sampling_id] = true;
          }
        }

      }
    }

    for (let client of Object.keys(clients)) {
      clientsRet.push(clients[client])
    }
    for (let lot of Object.keys(lots)) {
      lotsRet.push(lots[lot])
    }
    for (let farm of Object.keys(farms)) {
      farmsRet.push(farms[farm])
    }

    setGeoJsonLots(geoJsonLotsTemp)
    setGeoJsonLotsFiltered(geoJsonLotsTemp)

    setClients(clientsRet)
    setClientsFiltered(clientsRet)
    setFarms(farmsRet)
    setLots(lotsRet)

    setAssociatedSamplings(Object.keys(samplings).length)

  }, [JSON.stringify(fields)])


  useEffect(() => {

    if (uploadersContext.length === 0 && sincronizedUploadersContext === false) return;
    // status
    let status = {}
    let statusRet = []
    let samplingsObj = {}
    let lotsObj = {}
    let imagesSetCompleted = 0;
    let reportsGenerated = 0;
    var imagesProcessedCount = 0;
    var imagesProcessingCount = 0;
    for (let uploader of uploadersContext) {
      // contabilizo cantidad de imagenes procesadas
      if (uploader?.imagesSet?.Images?.length > 0) {
        for (let imageTemp of uploader.imagesSet.Images) {
          if (imageTemp?.processing_status !== 'NOT_PROCESSED' && uploader?.imagesSet?.is_completed === 1) {
            imagesProcessedCount++;
          }
          if (imageTemp?.processing_status === 'NOT_PROCESSED' && uploader?.imagesSet?.is_completed === 1) {
            imagesProcessingCount++;
          }
        }
      }
      //
      status[uploader.status] = { status: uploader.status }
      lotsObj[uploader.imagesSet.lot_id] = true;
      samplingsObj[uploader.imagesSet.sampling_id] = true;
      if (uploader.status === 'UPLOADED' || uploader.status === 'PROCESSING' || uploader.status === 'REPORT_GENERATED') {
        imagesSetCompleted++
      }
      if (uploader.status === 'REPORT_GENERATED') {
        reportsGenerated++
      }
    }
    //setCountImagesProcessed(imagesProcessedCount)
    //setCountImagesProcessing(imagesProcessingCount)

    for (let id of Object.keys(status)) {
      statusRet.push({ ...status[id] })
    }

    let countSamplings = 0;
    for (let sampling of Object.keys(samplingsObj)) {
      countSamplings++;
    }

    let countLots = 0;
    for (let lot of Object.keys(lotsObj)) {
      countLots++;
    }

    setStatus(statusRet)
    //setAssociatedSamplings(countSamplings)
    //setAssociatedLots(countLots)
    setImagesSetCompleted(imagesSetCompleted)
    setReportsGenerated(reportsGenerated)

  }, [JSON.stringify(uploadersContext), sincronizedUploadersContext])

  useEffect(() => {
    filterFarms(filterSelectedClient)
    filterLots(filterSelectedFarm)
    filterStatus(filterSelectedLot)

    // si se suprime del combo la opcion seleccionada setea esa opcion en "todos"
    let res = statusFiltered.find(status => status.status === filterSelectedStatus)
    if (res === undefined && filterSelectedStatus !== -1) {
      spreadChangeFilterSelectedStatus(-1)
      return;
    }
    let resLot = lotsFiltered.find(lot => parseInt(lot.lotId) === parseInt(filterSelectedLot))
    if (resLot === undefined && filterSelectedLot !== -1) {
      spreadChangeFilterSelectedLot(-1)
      return;
    }
    let resFarm = farmsFiltered.find(farm => parseInt(farm.farmId) === parseInt(filterSelectedFarm))
    if (resFarm === undefined && filterSelectedFarm !== -1) {
      spreadChangeFilterSelectedFarm(-1)
      return;
    }
    let resClient = clientsFiltered.find(client => client.clientId === filterSelectedClient)
    if (resClient === undefined && filterSelectedClient !== -1) {
      spreadChangeFilterSelectedClient(-1)
      return;
    }
  }, [
    JSON.stringify(farms),
    JSON.stringify(farmsFiltered),
    JSON.stringify(clients),
    JSON.stringify(clientsFiltered),
    JSON.stringify(status),
    JSON.stringify(statusFiltered),
    JSON.stringify(lots),
    JSON.stringify(lotsFiltered)
  ])


  useEffect(() => {
    let jsonsClients = []
    if (parseInt(filterSelectedClient) !== -1) {
      for (let json of geoJsonLots) {
        if (json.properties.field.client === filterSelectedClient) {
          jsonsClients.push(json)
        }
      }
    } else {
      jsonsClients = [...geoJsonLots]
    }
    let jsonsFarms = []
    if (parseInt(filterSelectedFarm) !== -1) {
      for (let json of jsonsClients) {
        if (parseInt(json.properties.field.farm_id) === parseInt(filterSelectedFarm)) {
          jsonsFarms.push(json)
        }
      }
    } else {
      jsonsFarms = [...jsonsClients]
    }

    let jsonsLots = []
    if (parseInt(filterSelectedLot) !== -1) {
      for (let json of jsonsFarms) {
        if (parseInt(json.properties.lot_id) === parseInt(filterSelectedLot)) {
          jsonsLots.push(json)
        }
      }
    } else {
      jsonsLots = [...jsonsFarms]
    }

    let jsonsStatus = []
    let countStatus = {}
    if (parseInt(filterSelectedStatus) !== -1 && filterSelectedStatus !== 'WITHOUT_IMAGESSET') {

      let jsonStatusObj = {}
      for (let json of jsonsLots) {
        for (let uploader of uploadersContext) {
          if (uploader.status === filterSelectedStatus) {

            if (json.properties.lot_id === uploader.imagesSet.lot_id) {
              jsonStatusObj[json.properties.lot_id] = json

              // contabilizo los estados
              if (!countStatus[uploader.status]) {
                countStatus[uploader.status] = 0;
              }
              countStatus[uploader.status]++;

            }
          }
        }
      }
      for (let jsonTemp of Object.keys(jsonStatusObj)) {
        jsonsStatus.push(jsonStatusObj[jsonTemp]);
      }
    } else if (filterSelectedStatus === 'WITHOUT_IMAGESSET') { // casos que no tienen imagesset procesados o en proceso
      for (let json of jsonsLots) {
        // analizo todos los samplings que no estan en ningun tipo de proceso
        let flagExist = false;
        [countStatus, flagExist] = updateCountSamplingsWithoutImagesSet(json, uploadersContext, countStatus)
        if (flagExist) {
          jsonsStatus.push(json)
        }
      }
    } else {
      jsonsStatus = [...jsonsLots]

      // analizo todos los samplings que no estan en ningun tipo de proceso
      for (let json of jsonsStatus) {
        let flagExist
        [countStatus, flagExist] = updateCountSamplingsWithoutImagesSet(json, uploadersContext, countStatus)
      }

      // tambien cuento los que tienen un estado igual a "sin imagenes asociadas"
      for (let json of jsonsStatus) {
        for (let uploader of uploadersContext) {
          if (json.properties.lot_id === uploader.imagesSet.lot_id) {
            if (!countStatus[uploader.status]) {
              countStatus[uploader.status] = 0;
            }
            countStatus[uploader.status]++;
          }
        }
      }
    }
    setGeoJsonLotsFiltered(jsonsStatus)
    setCountStatus(countStatus)
    setAssociatedLots(geoJsonLots.length)
  }, [
    geoJsonLots,
    JSON.stringify(uploadersContext),
    filterSelectedClient,
    filterSelectedLot,
    filterSelectedStatus,
    filterSelectedFarm
  ]);

  const updateCountSamplingsWithoutImagesSet = (json, uploaders, countStatus) => {
    let flagExist = false;
    for (let sampling of json.properties?.samplings) {
      let exist = false
      for (let uploader of uploaders) {
        if (sampling.sampling_id === uploader.imagesSet.sampling_id) {
          exist = true;
        }
      }
      if (exist === false) {
        // contabilizo los estados
        if (!countStatus["WITHOUT_IMAGESSET"]) {
          countStatus["WITHOUT_IMAGESSET"] = 0;
        }
        countStatus["WITHOUT_IMAGESSET"]++;
        flagExist = true
      }
    }
    return [countStatus, flagExist];
  }

  const filterFarms = (clientId) => {
    let farmsFiltered = []
    if (parseInt(clientId) === -1) {

      let clientsId = []
      for (let client of clientsFiltered) {
        clientsId.push(client.clientId)
      }

      for (let farm of farms) {
        if (clientsId.includes(farm.clientId)) {
          farmsFiltered.push(farm)
        }
      }

      setFarmsFiltered(farmsFiltered);
    } else {
      for (let farm of farms) {
        if (farm.clientId === clientId) {
          farmsFiltered.push(farm)
        }
      }
      setFarmsFiltered(farmsFiltered);
    }
  }

  const filterLots = (farmId) => {
    let lotsFiltered = []
    if (parseInt(farmId) === -1) {

      let farmsId = []
      for (let farm of farmsFiltered) {
        farmsId.push(parseInt(farm.farmId))
      }

      for (let lot of lots) {
        if (farmsId.includes(lot.farmId)) {
          lotsFiltered.push(lot)
        }
      }

      setLotsFiltered(lotsFiltered);
    } else {
      for (let lot of lots) {
        if (lot.farmId === parseInt(farmId)) {
          lotsFiltered.push(lot)
        }
      }
      setLotsFiltered(lotsFiltered);
    }
  }

  const filterStatus = (lotId) => {
    let statusFiltered = []
    // inicializo un estado para filtrar por los Lotes que no tienen ninguno set de imagenes
    statusFiltered.push({ status: "WITHOUT_IMAGESSET" })
    if (parseInt(lotId) === -1) {

      let lotsId = []
      for (let lot of lotsFiltered) {
        lotsId.push(parseInt(lot.lotId))
      }

      let statusTemp = {}
      for (let uploader of uploadersContext) {
        if (lotsId.includes(uploader.imagesSet.lot_id)) {
          statusTemp[uploader.status] = { status: uploader.status }
        }
      }
      for (let statusRow of Object.keys(statusTemp)) {
        statusFiltered.push(statusTemp[statusRow])
      }

      setStatusFiltered(statusFiltered);
    } else {

      let statusTemp = {}
      for (let uploader of uploadersContext) {
        if (parseInt(lotId) === uploader.imagesSet.lot_id) {
          statusTemp[uploader.status] = { status: uploader.status }
        }
      }
      for (let statusRow of Object.keys(statusTemp)) {
        statusFiltered.push(statusTemp[statusRow])
      }

      setStatusFiltered(statusFiltered);
    }

  }

  const handleFilterSelectedClient = (e) => {
    spreadChangeFilterSelectedClient(e.target.value)
  }

  const spreadChangeFilterSelectedClient = (client_id) => {
    setFilterSelectedClient(client_id)
    setFilterSelectedFarm(-1)
    setFilterSelectedLot(-1)
    setFilterSelectedStatus(-1)
    filterFarms(client_id)

    setSelectedLot(null);
    setSamplingIdChecked(null);
  }

  const handleFilterSelectedStatus = (e) => {
    spreadChangeFilterSelectedStatus(e.target.value)
  }

  const spreadChangeFilterSelectedStatus = (status) => {
    setFilterSelectedStatus(status)

    setSelectedLot(null);
    setSamplingIdChecked(null);
  }

  const handleFilterSelectedLot = (e) => {
    spreadChangeFilterSelectedLot(e.target.value)
  }

  const spreadChangeFilterSelectedLot = (lot_id) => {
    setFilterSelectedLot(lot_id)
    setFilterSelectedStatus(-1)
    filterStatus(lot_id)

    setSelectedLot(null);
    setSamplingIdChecked(null);
  }

  const handleFilterSelectedFarm = (e) => {
    spreadChangeFilterSelectedFarm(e.target.value)
  }

  const spreadChangeFilterSelectedFarm = (farm_id) => {
    setFilterSelectedFarm(farm_id)
    setFilterSelectedLot(-1)
    setFilterSelectedStatus(-1)
    filterLots(farm_id)

    setSelectedLot(null);
    setSamplingIdChecked(null);
  }

  const onClickLot = (lot) => {
    setSelectedLot(lot)
  }

  const onChangeCheckboxSampling = (e) => {
    if (e.target.value) {
      setSamplingIdChecked(e.target.samplingId)
    } else {
      setSamplingIdChecked(null)
    }
  }

  const StatusSamplingProcessed = (props) => { // para los casos que ya tienen al menos subido un images set
    let status = "WITHOUT_IMAGESSET";
    let uploaderContext = null;
    for (let uploader of uploadersContext) {
      if (uploader.imagesSet.sampling_id === props.samplingId) {
        status = uploader.status;
        uploaderContext = uploader;
      }
    }
    if (status === 'REPORT_GENERATED' && esCampanaActiva() === true) {
      return (
        <>
          <div
            style={{ whiteSpace: "nowrap" }}
          >
            <div style={{ display: "inline-block" }}>
              <i
                style={{ color: statusIconLabel['PROCESSED_WITHOUT_IMAGESSET'].color }}
                className="fa fa-stop ml-0 hidden-xs"
                aria-hidden="true"
              />
            </div>
            <div style={{ display: "inline-block", marginLeft: "5px" }}>
              <button
                type="button"
                onClick={() => {

                  bus.emit('changeMenu', 'images')
                  bus.emit('newUploader', props)


                }}
                className="btn floating-buttons-map btn-rounded  align-items-center line-height-1 py-1 mb-0 mt-2 mr-1"
              >
                <i
                  style={{ color: "#1c1f21 !important" }}
                  ref={(el) => {
                    if (el) {
                      el.style.setProperty('color', '#1c1f21', 'important');
                    }
                  }}
                  className={"lnr " + statusIconLabel['PROCESSED_WITHOUT_IMAGESSET'].icon + " mr-1"}
                />{" "}
                {statusIconLabel['PROCESSED_WITHOUT_IMAGESSET'].labelAction}
              </button>


            </div>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
  const StatusSampling = (props) => {
    let status = "WITHOUT_IMAGESSET";
    let uploaderContext = null;
    for (let uploader of uploadersContext) {
      if (uploader.imagesSet.sampling_id === props.samplingId) {
        status = uploader.status;
        uploaderContext = uploader;
      }
    }
    return (
      <>
        <div
          style={{ whiteSpace: "nowrap" }}
        >
          <div style={{ display: "inline-block" }}>
            <i
              style={{ color: statusIconLabel[status].color }}
              className="fa fa-stop ml-0 hidden-xs"
              aria-hidden="true"
            />
          </div>
          <div style={{ display: "inline-block", marginLeft: "5px" }}>
            {(esCampanaActiva() === true || status === 'UPLOADED' || status === 'PROCESSING' || status === 'REPORT_GENERATED') &&
              <button
                type="button"
                onClick={() => {
                  if (status === 'PROCESSED_WITHOUT_IMAGESSET') { // estado adicional para los casos que ya subieron un set
                    bus.emit('changeMenu', 'images')
                    bus.emit('newUploader', props)
                  }
                  if (status === 'WITHOUT_IMAGESSET') {
                    bus.emit('changeMenu', 'images')
                    bus.emit('newUploader', props)
                  }
                  if (status === 'UPLOADED') {
                    bus.emit('changeMenu', 'reports')
                  }
                  if (status === 'REPORT_GENERATED') {
                    bus.emit('changeMenu', 'reports')
                  }
                  if (status === 'PROCESSING') {
                    bus.emit('changeMenu', 'reports')
                  }
                  if (status === 'PAUSED') {
                    bus.emit('changeMenu', 'images')
                    bus.emit('changeUploaderSelected', uploaderContext)
                  }
                  if (status === 'UPLOADING') {
                    bus.emit('changeMenu', 'images')
                    bus.emit('changeUploaderSelected', uploaderContext)
                  }
                  if (status === 'READY') {
                    bus.emit('changeMenu', 'images')
                    bus.emit('changeUploaderSelected', uploaderContext)
                  }
                  if (status === 'EMPTY') {
                    bus.emit('changeMenu', 'images')
                    bus.emit('changeUploaderSelected', uploaderContext)
                  }


                }}
                className="btn floating-buttons-map btn-rounded  align-items-center line-height-1 py-1 mb-0 mt-2 mr-1"
              >
                <i
                  style={{ color: "#1c1f21 !important" }}
                  ref={(el) => {
                    if (el) {
                      el.style.setProperty('color', '#1c1f21', 'important');
                    }
                  }}
                  className={"lnr " + statusIconLabel[status].icon + " mr-1"}
                />{" "}
                {statusIconLabel[status].labelAction}
              </button>
            }


          </div>
        </div>
      </>
    )

  }



  return (
    <div style={{ display: props.visible ? '' : 'none' }}>
      {/** CUSTOM HEAD*/}
      <head>
        <link rel="stylesheet" href="static/css/components/general.css" />
      </head>
      {/** CUSTOM HEAD*/}

      <div className="container">
        <ul className="nav nav-tabs mb-2 mt-2" role="tablist">
          <li className="nav-item">
            <a
              style={{
                color: "#4e5153",
                textAlign: "left",
                fontWeight: "700",

              }}
              ref={(el) => {
                if (el) {
                  el.style.setProperty('padding-left', '0px', 'important');
                }
              }}
              class="nav-link"
            >
              INDICADORES GENERALES
            </a>
          </li>
        </ul>
        <div
          style={{ paddingLeft: "0px !important", marginTop: "0px", opacity: true ? 0.5 : 1, pointerEvents: true ? "none" : "" }}
          ref={(el) => {
            if (el) {
              el.style.setProperty('padding-left', '0px', 'important');
            }
          }}
          align="left"
          className="row"
        >
          <div style={{ paddingLeft: "5px !important" }} className="col-md-12"
            ref={(el) => {
              if (el) {
                el.style.setProperty('padding-left', '5px', 'important');
              }
            }}
          >
            {/*
              <div className="table-responsive">
                <table
                  style={{
                    marginTop: "-5px !important",
                    marginBottom: "-5px !important"
                  }}
                  ref={(el) => {
                    if (el) {
                      el.style.setProperty('margin-top', '-5px', 'important');
                      el.style.setProperty('margin-bottom', '-5px', 'important');
                    }
                  }}
                  className="table mb-0"
                >
                  <thead>
                    <tr>
                      <th style={{ borderBottom: "0px !important" }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty('border-bottom', '0px', 'important');                          
                        }
                      }}                        
                      >
                        <select
                          className="form-control my-select altura"
                          name="año"
                          id="año"
                        >
                          <option value="">Año</option>
                          <option value={2022} selected="">
                            2022
                          </option>
                          <option value={2023}>2023</option>
                        </select>
                      </th>
                      <th style={{ borderBottom: "0px !important" }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty('border-bottom', '0px', 'important');                          
                        }
                      }}
                      >
                        <select
                          className="form-control my-select altura"
                          name="region"
                          id="region"
                        >
                          <option value="">Región</option>
                          <option value={0}>Córdoba</option>
                          <option value={1}>Litoral</option>
                        </select>
                      </th>
                      <th style={{ borderBottom: "0px !important" }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty('border-bottom', '0px', 'important');                          
                        }
                      }}>
                        <select
                          className="form-control my-select altura"
                          name="responsable"
                          id="responsable"
                        >
                          <option value="">Responsable zonal</option>
                          <option value={0}>Responsable 1</option>
                          <option value={1}>Responsable 2</option>
                        </select>
                      </th>
                      <th style={{ borderBottom: "0px !important" }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty('border-bottom', '0px', 'important');                          
                        }
                      }}
                      >
                        <select
                          className="form-control my-select altura"
                          name="distribuidor"
                          id="distribuidor"
                        >
                          <option value="">Distribuidor</option>
                          <option value={0}>Distrubuidor 1</option>
                          <option value={1}>Distribuidor 2</option>
                        </select>
                      </th>
                      <th style={{ borderBottom: "0px !important" }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty('border-bottom', '0px', 'important');                          
                        }
                      }}>
                        <select
                          className="form-control my-select altura"
                          name="cliente"
                          id="cliente"
                        >
                          <option value="">Cliente</option>
                          <option value={0}>Cliente 1</option>
                          <option value={1}>Cliente 2</option>
                        </select>
                      </th>
                      <th
                        style={{
                          maxWidth: "56px",
                          borderBottom: "0px !important",
                          position: "relative",
                          top: "-2px"
                        }}
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty('border-bottom', '0px', 'important');                          
                          }
                        }}
                      >
                        <a
                          href="javascript:void(0);"
                          onclick="buscar();"
                          style={{ marginTop: "-1px" }}
                          className="btn btn-dark btn-h-1 btn-v-2 font-weight-semibold text-1 fx animated wow fadeInUp"
                        >
                          FILTRAR
                        </a>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            */}
          </div>

        </div>
        <div className="container pl-0 pr-0 mt-0 mb-2">
          <div className="row  mb-0 pb-0">
            <div

              className="col-md-4 mb-5 mb-md-0 appear-animation animated fadeInUpShorter appear-animation-visible"
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay={100}
              style={{ animationDelay: "100ms" }}
            >
              <div className="icon-box icon-box-style-9">
                <div className="icon-box-icon">
                  <i
                    style={{ marginRight: "5px", color: "#ffc00e !important" }}
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty('color', '#ffc00e', 'important');
                      }
                    }}
                    className="lnr icono lnr-layers text-color-primary"
                  />
                  <span className="number">
                    {
                      associatedLots
                    }
                  </span>
                </div>
                <div className="icon-box-info">
                  <div className="icon-box-info-title">
                    <h4>Lotes registrados</h4>
                  </div>
                  <button
                    style={{
                      backgroundColor: "#e1e1e1",
                      borderColor: "#e1e1e1",
                      color: "black",
                      cursor: "text"
                    }}
                    type="button"
                    className="btn btn-primary align-items-center line-height-1 py-1 mb-0 mt-2 mr-1"
                  >
                    <strong>{associatedSamplings}</strong>&nbsp;muestreos asociados{" "}
                  </button>
                </div>
              </div>
            </div>
            <div

              className="col-md-4 mb-5 mb-md-0 appear-animation animated fadeInUpShorter appear-animation-visible"
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay={200}
              style={{ animationDelay: "200ms" }}
            >
              <div className="icon-box mb-1 icon-box-style-9">
                <div className="icon-box-icon">
                  <i
                    style={{ marginRight: "5px", color: "#ffc00e" }}
                    className="lnr icono lnr-picture text-color-primary"
                  />
                  <span className="number">{imagesSetCompleted}</span>
                </div>
                <div className="icon-box-info">
                  <div className="icon-box-info-title">
                    <h4>Sets de Imágenes cargados</h4>
                  </div>
                </div>
              </div>
              {false &&
                <a
                  style={{ color: "#265d82 !important", fontWeight: "600" }}
                  ref={(el) => {
                    if (el) {
                      el.style.setProperty('color', '#265d82', 'important');
                    }
                  }}

                >
                  {" "}
                  <button
                    type="button"
                    onClick={() => {
                      bus.emit('changeMenu', 'images')
                    }}
                    className="btn floating-buttons-y btn-rounded  align-items-center line-height-1 py-2 mb-0 mt-1 mr-1"
                  >
                    <i
                      style={{ color: "#1c1f21 !important" }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty('color', '#1c1f21', 'important');
                        }
                      }}
                      className="fa fa-plus-circle mr-1"
                    />{" "}
                    Ir a sets de imágenes
                  </button>
                </a>

              }
              <br />
            </div>
            <div
              className="col-md-4 mb-5 mb-md-0 appear-animation animated fadeInUpShorter appear-animation-visible"
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay={300}
              style={{ animationDelay: "300ms" }}
            >
              <div className="icon-box icon-box-style-9">
                <div className="icon-box-icon">
                  <i
                    style={{ marginRight: "5px", color: "#ffc00e" }}
                    className="lnr icono lnr-chart-bars text-color-primary"
                  />
                  <span className="number">{reportsGenerated}</span>
                </div>
                <div className="icon-box-info">
                  <div className="icon-box-info-title">
                    <h4>Reportes generados</h4>
                    <button
                      style={{
                        backgroundColor: "#e1e1e1",
                        borderColor: "#e1e1e1",
                        color: "black",
                        cursor: "text"
                      }}
                      type="button"
                      className="btn btn-primary align-items-center line-height-1 py-1 mb-2 mt-2 mr-2"
                    >
                      <strong>{countImagesProcessed}</strong>&nbsp;imgs. procesadas
                      {/*((
                      {
                        (countImagesProcessed+countImagesProcessing>0)
                        ?
                        (countImagesProcessed * 100 / (countImagesProcessed+countImagesProcessing)).toFixed(1)
                        :
                        ''
                      }%) </strong>&nbsp;procesadas{" "}*/}
                    </button>
                    <i
                      style={{ color: "#dc3545" }}
                      className="fa fa-exclamation-circle ml-2 hidden-xs"
                      aria-hidden="true"
                    />
                    <a
                      style={{
                        lineHeight: "14px",
                        marginRight: "10px !important",
                        marginLeft: "-4px !important",
                        fontSize: "14px"
                      }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty('margin-right', '10px', 'important');
                          el.style.setProperty('margin-left', '-4px', 'important');
                        }
                      }}
                      className="px-1 hidden-xs m-auto"
                    >
                      {" "}
                      {countImagesProcessing} imgs. en proceso

                      {/*(
                      {
                        (countImagesProcessed+countImagesProcessing>0)
                        ?
                        (countImagesProcessing * 100 / (countImagesProcessed+countImagesProcessing)).toFixed(1)
                        :
                        ''
                      }%) */}
                    </a>
                  </div>
                  {false &&
                    <button
                      style={{
                        backgroundColor: "#e1e1e1",
                        borderColor: "#e1e1e1",
                        color: "black",
                        cursor: "text"
                      }}
                      type="button"
                      className="btn btn-primary align-items-center line-height-1 py-1 mb-2 mt-2 mr-1"
                    >
                      {" "}
                      <i
                        style={{ color: "black" }}
                        className="lnr lnr-location mr-2 hidden-xs"
                        aria-hidden="true"
                      />
                      <strong>--</strong>&nbsp;compartidos{" "}
                    </button>
                  }
                  {false &&
                    <button
                      style={{
                        backgroundColor: "#e1e1e1",
                        borderColor: "#e1e1e1",
                        color: "black",
                        cursor: "text"
                      }}
                      type="button"
                      className="btn btn-primary align-items-center line-height-1 py-1 mb-2 mt-2 mr-1"
                    >
                      {" "}
                      <i
                        style={{ color: "black" }}
                        className="lnr lnr-download mr-2 hidden-xs"
                        aria-hidden="true"
                      />
                      <strong>--</strong>&nbsp;descargados{" "}
                    </button>
                  }
                </div>
              </div>
              {false &&
                <a
                  style={{ color: "#265d82 !important", fontWeight: "600" }}
                  ref={(el) => {
                    if (el) {
                      el.style.setProperty('color', '#265d82', 'important');
                    }
                  }}

                >
                  {" "}
                  <button
                    type="button"
                    onClick={() => {
                      bus.emit('changeMenu', 'reports')
                    }}
                    className="btn floating-buttons-y btn-rounded  align-items-center line-height-1 py-2 mb-0 mt-1 mr-1"
                  >
                    <i
                      style={{ color: "#1c1f21 !important" }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty('color', '#1c1f21', 'important');
                        }
                      }}
                      className="fa fa-plus-circle mr-1"
                    />{" "}
                    Ir a reportes
                  </button>
                </a>
              }
            </div>
          </div>
        </div>
      </div>
      <section
        className="page-header mb-10"
        style={{ backgroundColor: "white", padding: "0px 0px" }}
      >
        <div className="container pt-3 mt-2">
          <div className="row">
            <div
              align="left"
              className="col-md-12 appear-animation animated fadeInDownShorter appear-animation-visible"
              data-appear-animation="fadeInDownShorter"
              data-appear-animation-delay={100}
              style={{ animationDelay: "100ms" }}
            >
              <div
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
                className="col-md-12 mb-2 mb-md-2"
              >
                <ul
                  className="nav nav-tabs nav-tabs-default nav-tabs-primary"
                  id="tabColorPrimary"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      style={{
                        color: "#4e5153",
                        textAlign: "left",
                        fontWeight: "700"
                      }}
                      className="nav-link active"
                    >
                      Detalle de lotes registrados y muestreos
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    style={{ marginLeft: "25px", marginTop: "6px" }}
                  >
                    {/*<a
                        style={{
                          color: "#4e5153",
                          textAlign: "left",
                          fontWeight: "500"
                        }}
                      >
                        Buscá la ubicación en el mapa y hacé clic en el lote para
                        ver más información
                      </a>*/}
                  </li>
                </ul>
                <div className="tab-content" id="tabColorPrimaryContent">
                  <div className="row">
                    <div className="col-md-3 pr-0 mt-2 mb-1 mb-md-0">
                      Cliente
                      <select
                        className="form-control my-select altura"
                        name="estado"
                        id="estado"
                        value={filterSelectedClient}
                        onChange={handleFilterSelectedClient}
                      >
                        <option value={-1}>Todos</option>
                        {clientsFiltered.map((client) => (
                          <option value={client.clientId}>{client.clientName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 pr-0 mt-2 mb-1 mb-md-0">
                      Campo
                      <select
                        className="form-control my-select altura"
                        name="estado"
                        id="estado"
                        value={filterSelectedFarm}
                        onChange={handleFilterSelectedFarm}
                      >
                        <option value={-1}>Todos</option>
                        {farmsFiltered.map((farm) => (
                          <option value={farm.farmId}>{farm.farmName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2 pr-0 mt-2 mb-1 mb-md-0">
                      Lote
                      <select
                        className="form-control my-select altura"
                        name="estado"
                        id="estado"
                        value={filterSelectedLot}
                        onChange={handleFilterSelectedLot}
                      >
                        <option value={-1}>Todos</option>
                        {lotsFiltered.map((lot) => (
                          <option value={lot.lotId}>{lot.lotName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2 pr-0 mt-2 mb-1 mb-md-0">
                      Estado
                      <select
                        className="form-control my-select altura"
                        name="estado"
                        id="estado"
                        value={filterSelectedStatus}
                        onChange={handleFilterSelectedStatus}
                      >
                        <option value={-1}>Todos</option>
                        {statusFiltered.map((status) => (
                          <option value={status.status}>{statusLabel[status.status]}</option>
                        ))}
                      </select>
                    </div>
                    <div style={{ display: "" }} className="col-md-2 mt-2 mb-1 mb-md-0">
                      {" "}<br />
                      <button
                        onClick={() => {
                          setFilterSelectedClient(-1)
                          spreadChangeFilterSelectedClient(-1)
                          setFilterSelectedFarm(-1)
                          spreadChangeFilterSelectedFarm(-1)
                          setFilterSelectedLot(-1)
                          spreadChangeFilterSelectedLot(-1)
                          setFilterSelectedStatus(-1)
                          filterFarms(-1)

                          bus.emit("resetMap", true);
                          setSelectedLot(null);
                          setSamplingIdChecked(null);
                        }}
                        style={{ marginTop: "0px", marginLeft: "10px" }}
                        className="btn btn-dark btn-h-1 btn-v-2 font-weight-semibold text-1 fx animated wow fadeInUp"
                      >
                        Resetear
                      </button>
                    </div>
                  </div>
                  <div
                    style={{ paddingTop: "0px", marginBottom: "15px" }}
                    className="row mt-2"
                  >
                    <div className="col-sm-12">
                      <span style={{ fontWeight: "bold" }}>Muestreos:</span><br /> {Object.keys(statusIconLabel).map((status) => {
                        if (status === 'PROCESSED_WITHOUT_IMAGESSET') return <></>
                        return (
                          <span style={{ fontSize: "14px", marginRight: "15px" }}>
                            <i
                              style={{ color: statusIconLabel[status].color }}
                              className="fa fa-stop ml-0 hidden-xs"
                              aria-hidden="true"
                            />{" "}
                            {statusLabel[status]} (
                            <span id="nro_sin_imagenes">{countStatus[status] ? countStatus[status] : (Object.keys(countStatus).length === 0) ? <SpinnerTag size={15} /> : 0}</span>)
                          </span>
                        )
                      })}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5 pb-4 w-100 m-auto">
            <Leaflet geoJsonGeometriesLots={geoJsonLotsFiltered} images={{}} reinitHandler={() => { }} samplingId={samplingIdChecked} onClickLot={onClickLot} />
            <div
              className="accordion p-absolute p-2"
              id="accordionExample"
              style={{
                position: "absolute",
                top: "140px",
                width: "400px",
                textAlign: "left",
                paddingLeft: "10px !important"
              }}
              ref={(el) => {
                if (el) {
                  el.style.setProperty('padding-left', '10px', 'important');
                }
              }}
            >
              <div className="card" style={{ background: "transparent", display: selectedLot !== null ? '' : 'none', zIndex: "1000", marginTop: "50px" }}>
                <div
                  style={{ padding: "0px" }}
                  className="card-header box-map bg-white"
                  id="headingOne"
                >
                  <h2
                    style={{ textDecoration: "none !important" }}
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty('text-decoration', 'none', 'important');
                      }
                    }}
                    className="mb-0"
                  >
                    <button
                      style={{ textDecoration: "none !important" }}
                      className="btn btn-sm  btn-block text-center shadow-none"
                      type="button"
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty('text-decoration', 'none', 'important');
                        }
                      }}
                    >
                      <strong>Lote seleccionado <a onClick={() => {
                        setSelectedLot(null);
                        setSamplingIdChecked(null);
                      }}>(cerrar)</a></strong>
                    </button>
                  </h2>
                </div>
                <div
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div
                    className="card-body box-map bg-white"
                    style={{
                      marginTop: "3px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      height: "480px",
                      overflow: "scroll"
                    }}
                  >
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item pl-0">
                        <div
                          style={{ paddingLeft: "0.65rem !important" }}
                          ref={(el) => {
                            if (el) {
                              el.style.setProperty('padding-left', '0.65rem', 'important');
                            }
                          }}
                          className="form-check"
                        >
                          <strong>Cliente:</strong> {selectedLot?.properties?.field?.client}
                          <br />
                          <strong>Cultivo:</strong> {selectedLot?.properties?.plantings[0]?.crop_name}
                          <br />
                          <strong>Material: </strong> {selectedLot?.properties?.plantings[0]?.genotype_name}
                          <br />
                          <strong>Campo:</strong> {selectedLot?.properties?.field?.farm_name}
                          <br />
                          <strong>Lote:</strong> {selectedLot?.properties?.lot_name}
                          <br />
                          <strong>Muestreos:</strong>
                          <br />
                          {selectedLot?.properties?.samplings.map((sampling) => (
                            <>
                              <div style={{
                                display: "flex",
                                justifyContent: "space-between", marginTop: "15px"
                              }}>
                                <input onChange={(e) => {
                                  bus.emit("waitMap", true);
                                  onChangeCheckboxSampling({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked,
                                      samplingId: sampling.sampling_id
                                    },
                                  });
                                }}
                                  checked={samplingIdChecked === sampling.sampling_id} type="checkbox" />
                                <div style={{ lineHeight: "1.1em", width: "100%", paddingLeft: "10px" }}>{sampling.sampling_name}
                                  <br />
                                  <small><strong>{sampling.sampling_metadata_date}</strong></small>
                                  <br />
                                  <StatusSampling changeModuleHandler={props.changeModuleHandler} selectedLot={selectedLot} samplingId={sampling.sampling_id} />
                                  <StatusSamplingProcessed changeModuleHandler={props.changeModuleHandler} selectedLot={selectedLot} samplingId={sampling.sampling_id} />
                                </div>

                                <a href={process.env.REACT_APP_API_URL + "api/map/sampling/kml/" + sampling.sampling_id} target="_blank"
                                  style={{ marginTop: "12px" }}>

                                  <button
                                    type="button"
                                    className="btn floating-buttons-map btn-rounded  align-items-center btn-grey line-height-1 py-1 mb-2 mt-0 mr-1"
                                  >
                                    <i
                                      style={{ color: "#1c1f21 !important" }}
                                      ref={(el) => {
                                        if (el) {
                                          el.style.setProperty('color', '#1c1f21', 'important');
                                        }
                                      }}
                                      className="fa fa-download mr-1"
                                    />{" "}
                                    Descargar KML
                                  </button>
                                </a>
                              </div>
                              <hr style={{ borderTop: "1px" }} />
                            </>
                          )
                          )}


                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
};

export default General;