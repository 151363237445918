import React, { useEffect, useState, useContext } from "react";
import Leaflet from "../mapprincipal/leaflet"
import {ImagesContext} from '../../utils/ImagesContext'
import { ApiFetchWithoutSession, SyncApiFetchWithoutSession } from '../../utils/ApiFetch';
import Favicon from "react-favicon";
import favicon from "../icons/favico.png"

const SSO = (props) => {

    const [debug, setDebug] = useState('')
    const searchParams = new URLSearchParams(document.location.search)

    useEffect(async () => {                        
        try {
            let init = await SyncApiFetchWithoutSession(
                "api/sso_init/"+searchParams.get('sso_session_token'),
                {
                    method: "GET",                
                    headers: {
                    "Content-Type": "application/json",
                    },
                }
                ); 
                setDebug(JSON.stringify(init))
                localStorage.setItem('nideravuela2_jwt_token', init.token);
                localStorage.setItem('nideravuela2_user',JSON.stringify(init.user))
                
                window.location.replace('/general');        
        } catch (e) {
            //todo: implementar            
            return false;
        }
        
    },[]);


    useEffect(() => {
        document.title = 'Nidera Vuela';
    }, []);
  

    return (   
        <>
        <Favicon url={favicon} />
        {false && 
            <div>
                iniciando ...
                <br/>
                {searchParams.get('sso_session_token')}
                <br/>
                {debug}
            </div>
        }
        </>

);
};

export default SSO;