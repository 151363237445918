import React, { useEffect, useState, useContext } from "react";
import { ImagesContext } from '../../utils/ImagesContext'
import { useBus, useListener } from 'react-bus'
import spinner from "../resources/spinner.gif";
import { makeStyles } from '@mui/styles';
import { Box, Button, Modal, Typography } from '@mui/material';

const useStylesModalReports = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
  },
  justifyContent: 'center',
  modalContent: {
    backgroundColor: 'white',
    outline: 'none',
    borderRadius: 10,
    padding: 5,
    height: '30%',
    width: '30%',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
});

const styleModalErrors = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TopMenu = (props) => {
  const classesModalReports = useStylesModalReports();
  const { lastSyncSeguimientoNidera, globalUploadCount, campanas, campanaSeleccionada, setCampanaSeleccionada, esCampanaActiva, fieldsErrors } = useContext(ImagesContext);
  const bus = useBus()
  const [option, setOption] = useState('general')
  const [isSync, setIsSync] = useState(false)
  const [lastSync, setLastSync] = useState('')

  const [openModalErrors, setOpenModalErrors] = useState(false);
  const handleOpenModalErrors = () => setOpenModalErrors(true);
  const handleCloseModalErrors = () => setOpenModalErrors(false);


  useEffect(() => {
    if (lastSyncSeguimientoNidera?.access_datetime) {
      var offset = new Date().getTimezoneOffset();
      let date = new Date(lastSyncSeguimientoNidera.access_datetime)
      date.setMinutes(date.getMinutes() - offset)
      let dateToString = date.toLocaleDateString('en-CA') + " " + date.toLocaleTimeString('es-AR')
      setLastSync({ ...lastSyncSeguimientoNidera, access_datetime: dateToString })
    }
  }, [lastSyncSeguimientoNidera])

  useListener('syncSeguimientoNidera', (e) => {
    if (e === 'new' || e === 'processing') {
      setIsSync(true)
    } else {
      setIsSync(false)
    }
  })

  const setTopMenu = (option) => {
    setOption(option)
    bus.emit('changeMenu', option)
  }

  useListener('changeMenu', (e) => {
    setOption(e)
  })


  return (
    <header
      id="header"
      data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyStartAt': 147, 'stickySetTop': '-147px', 'stickyChangeLogo': false}"
      style={{ minHeight: "80px" }}
    >
      <div
        className="header-body"
        style={{ top: "0px", position: "static", background: "#f9f9f98f" }}
      >
        <div style={{ minHeight: "68px" }} className="header-container container">
          <div className="header-row py-0">
            <div className="header-column">
              <a className="item-menu" onClick={() => { setTopMenu('general') }}>
                <div
                  style={{
                    paddingBottom: "8px",
                    borderBottom: option === 'general' ? "3px solid #FECF2F" : "0px solid #FECF2F",
                    marginRight: "40px !important"
                  }}
                  className="icon-box icon-box-style-1 d-none d-md-flex mr-4"
                >
                  <div
                    style={{ marginTop: "-4px" }}
                    className="icon-box-icon pr-2"
                  >
                    <i
                      style={{ color: "#ffc00e !important" }}
                      className="lnr lnr-chart-bars text-color-primary text-5"
                    />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="icon-box-info">
                    <div className="icon-box-info-title">
                      <span className="icon-box-sub-title">
                        Resumen general
                      </span>
                      <p className="text-1 line-height-1 mb-0">
                        <strong>INICIO</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
              {esCampanaActiva() &&
                <a className="item-menu" onClick={() => { setTopMenu('images') }}>
                  <div
                    style={{
                      paddingBottom: "8px",
                      borderBottom: option === 'images' ? "3px solid #FECF2F" : "0px solid #FECF2F",
                      marginRight: "40px !important"
                    }}
                    className="icon-box icon-box-style-1 d-none d-sm-flex mr-4"
                  >
                    <div className="icon-box-icon pr-2">
                      <i
                        style={{ color: "#ffc00e !important" }}
                        className="lnr lnr-picture text-color-primary text-5"
                      />
                    </div>
                    <div className="icon-box-info"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="icon-box-info-title">
                        {" "}
                        <span className="icon-box-sub-title">Sets de</span>
                        <p className="text-1 line-height-1 mb-0">
                          <strong>IMÁGENES</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              }
              <a className="item-menu" onClick={() => { setTopMenu('reports') }}>
                <div
                  style={{
                    paddingBottom: "8px",
                    borderBottom: option === 'reports' ? "3px solid #FECF2F" : "0px solid #FECF2F",
                    marginRight: "40px !important"
                  }}
                  className="icon-box icon-box-style-1 d-none d-sm-flex mr-4"
                >
                  <div className="icon-box-icon pr-2">
                    <i
                      style={{ color: "#ffc00e !important" }}
                      className="lnr lnr-chart-bars text-color-primary text-5"
                    />
                  </div>
                  <div className="icon-box-info"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="icon-box-info-title">
                      <span className="icon-box-sub-title">Mis</span>
                      <p className="text-1 line-height-1 mb-0">
                        <strong>REPORTES</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            {/*<select
              onChange={(e) => {
                setCampanaSeleccionada(e.target.value)
              }}
              value={campanaSeleccionada} class="form-control my-select altura" style={{ marginRight: "10px" }}>
              {campanas.map((campana) => (
                <option value={campana.anio}>{campana.label}</option >
              ))}
            </select>*/}
            {fieldsErrors.length > 0 && esCampanaActiva() &&
              <><i onClick={() => { handleOpenModalErrors() }}
                style={{ color: "#dc3545", marginRight: "5px" }}
                className="fa fa-exclamation-circle ml-2 hidden-xs"
                aria-hidden="true"
              /></>
            }
            {esCampanaActiva() &&
              <button
                type="button"
                disabled={isSync ? true : ((globalUploadCount.total === globalUploadCount.uploaded) || (globalUploadCount.uploading === 0)) ? false : true}
                onClick={() => {
                  bus.emit('syncSeguimientoNidera', 'new')
                  setIsSync(true)
                }}
                className={"btn align-items-center line-height-1 py-2 mb-0 mt-0 mr-1 " + (isSync ? 'btn-warning' : lastSync?.status === 'ERROR' ? 'btn-danger' : 'btn-success')}
              >
                {!isSync &&
                  <i
                    style={{ color: "#1c1f21 !important", textAlign: "center" }}
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty('color', '#1c1f21', 'important');
                      }
                    }}
                    className="fa fa-sync mr-3"
                  />
                }
                {isSync &&
                  <img
                    style={{ width: "14px", height: "14px", marginRight: "10px" }}
                    src={spinner}
                  />
                }
                {" "}

                <div>
                  {isSync ? 'Sincronizando' : 'Sincronizar'} con Seguimiento Nidera<br />
                  {isSync ? '' : <small>{lastSync !== null ? (lastSync?.status === 'ERROR' ? "Ultima actualización falló el" : "Ultima actualización realizada") + " " + lastSync?.access_datetime : ""}</small>}
                </div>

              </button>
            }
          </div>
        </div>
      </div>

      <Modal
        open={openModalErrors}
        onClose={handleCloseModalErrors}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={styleModalErrors}>
          <Typography id="modal-title" variant="h6" component="h3" align="center">
            <i
              style={{ color: "#dc3545", fontSize: "0.9em" }}
              className="fa fa-exclamation-circle ml-2 hidden-xs"
              aria-hidden="true"
            />Errores de sincronización con Seguimiento Nidera
          </Typography>

          <div className="table-responsive" style={{ overflowY: "auto", height: "250px" }}>
            <table className="shop-cart-table w-100">
              <thead>
                <tr>
                  <th className="product">Campo</th>
                  <th className="product">Lote</th>
                  <th className="product">Errores</th>
                </tr>
              </thead>
              <tbody>
                {fieldsErrors.map((lot) => {
                  return (
                    < tr className="cart-item" >
                      <td className="product">
                        <a>
                          {lot.farm}
                        </a>
                      </td>
                      <td className="product">
                        <a>
                          {lot.lot.lot_name}
                        </a>
                      </td>
                      <td className="product">
                        <a>
                          {lot.errors.map((error) => (<>{"* " + error}<br /></>))}
                        </a>
                      </td>
                    </tr>

                  )

                }
                )}


              </tbody>
            </table>
          </div>


          <Typography id="modal-description" sx={{ mt: 2 }}>
            {/*<pre>{JSON.stringify(fieldsErrors.map((lot) => {
              return { "lote": lot.lot.lot_name, "campo": lot.farm, "errors": lot.errors }
            }), null, 2)}
            </pre>
            */}
          </Typography>

          <Typography id="modal-title" variant="h6" component="h2" align="center">
            <Button
              className="btn btn-dark btn-h-1 btn-v-2 font-weight-semibold text-1 fx animated wow fadeInUp"
              onClick={handleCloseModalErrors} sx={{ mt: 2 }}>
              Cerrar
            </Button>
          </Typography>
        </Box>
      </Modal>


    </header >

  );
};

export default TopMenu;
