// components/simple-dropzone.component.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import exifr from "exifr";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import "./dropzoner.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import {
  imageStatusIcon,
  imageStatusLabel,
  imageStatusClass,
} from "../images/statusConstants";
import { formatBytes } from "../images/helperFormat";
import spinner from "../resources/spinner.gif";
import { ApiFetch, SyncApiFetch } from "../../utils/ApiFetch";

const { REACT_APP_API_URL } = process.env;

const DropzoneR = (props) => {
  const [flagShow, setFlagShow] = useState(true);
  const [imagesPersisted, setImagesPersisted] = useState([]);
  const [exteriorErrorFiles, setExteriorErrorFiles] = useState([]);

  useEffect(() => {
    if (props.imagesPersisted !== undefined) {
      setImagesPersisted(props.imagesPersisted);
    }
  }, [props.imagesPersisted]);

  useEffect(() => {
    if (props.exteriorErrorFiles !== undefined) {
      setExteriorErrorFiles(props.exteriorErrorFiles);
    }
  }, [props.exteriorErrorFiles]);

  // Payload data and url to upload files
  const getUploadParams = async ({ file, meta }) => {
    try {
      // creo nueva clave en meta, para que lo atienda el handler de dropzoneRW en onUploading
      let latlon = await exifr.gps(file);
      let args = {
        image_set_id: props.survey,
        image_size_bytes: meta.size,
        actual_name: meta.name,
        image_location: latlon,
        image_id: meta?.persistedImage?.image_id,
      };

      const json = await SyncApiFetch("api/presignedUploadURL", true, {
        method: "POST",
        body: JSON.stringify(args),
      });

      meta.s3FileName = json.s3_name;
      //return { url: json.response };

      return {
        body: file,
        url: json.url,
        meta: {
          fileUrl: json.url,
          image_id: json.image_id,
          persistedImage: json,
        },
        method: "PUT",
      };
    } catch (e) {
      return { url: "xhttps://httpbin.org/post" };
    }
  };

  // Return the current status of files being uploaded
  //const handleChangeStatus = ({ meta, file }, status) => {
  const handleChangeStatus = (arg, status) => {
    let { meta, file } = arg;
    props.handleChangeStatus(arg, status, meta, file, props.survey);
  };

  const hide = () => {
    setFlagShow(false);
  };
  const show = () => {
    setFlagShow(true);
  };

  // Return array of uploaded files after submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    axios.post(
      "https://8000-bdf9af20-e0be-445c-b887-0c937feeeb06.ws-us02.gitpod.io/upload",
      data,
      {}
    );
  };

  const InputMin = ({ onFiles, getFilesFromEvent }) => (
    <input
      type="file"
      multiple
      id={Math.floor(Math.random() * 1000000)}
      onChange={async (e) => {
        const target = e.target;
        const chosenFiles = await getFilesFromEvent(e);
        onFiles(chosenFiles);
        //@ts-ignore
        target.value = null;
      }}
    />
  );
  const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length > 0 ? "Agregar mas Imágenes" : "Elegir Imágenes";

    return (
      <label
        style={{
          //backgroundColor: "#007bff",
          color: "#fff",
          cursor: "pointer",
          padding: 0,
          borderRadius: 3,
          marginTop: "0px",
        }}
      >
        <div style={{ marginTop: "0px" }} align="center" class="row">
          <div class="col-md-12 mb-4 mb-md-0">
            <img src="static/img/drag.png" width="370px" alt="" />
          </div>
        </div>
        <input
          style={{ display: "none" }}
          type="file"
          onClick={() => {
            console.log("click sobre el input");
          }}
          //accept={accept}
          multiple
          id={Math.floor(Math.random() * 1000000)}
          onInput={async (e) => {
            const target = e.target;
            const chosenFiles = await getFilesFromEvent(e);
            onFiles(chosenFiles);
            //@ts-ignore
            target.value = null;
          }}
          onChange={async (e) => {
            const target = e.target;
            const chosenFiles = await getFilesFromEvent(e);
            onFiles(chosenFiles);
            //@ts-ignore
            target.value = null;
          }}
        />
        {text}
      </label>
    );
  };

  const Layout = (arg) => {
    let {
      input,
      previews,
      submitButton,
      dropzoneProps,
      files,
      extra: { maxFiles },
    } = arg;
    return (
      <div style={{ padding: "0px" }} className="table-responsive">
        {(files.length > 0 || imagesPersisted.length > 0) && (
          <table className="shop-cart-table w-100">
            <thead>
              <tr>
                <th
                  className="product"
                  style={{ paddingLeft: "5px", width: "30%" }}
                >
                  Imagen
                </th>
                <th className="product" style={{ width: "10%" }}>
                  Tamaño
                </th>
                <th className="product" style={{ width: "30%" }}>
                  Proceso de Carga
                </th>
                <th className="product" style={{ width: "0%" }}></th>
                <th className="product" style={{ width: "40%" }}>
                  Estado
                </th>
              </tr>
            </thead>

            <tbody>
              {imagesPersisted.map((image) => {
                return PreviewPersisted({
                  meta: {
                    name: image.actual_name,
                    percent: 100,
                    size: image.image_size_bytes,
                    status: "saved",
                  },
                });
              })}
              {previews}
            </tbody>
          </table>
        )}
        <div {...dropzoneProps}>
          {files.length < maxFiles &&
            (props.showInput != undefined ? props.showInput : true) &&
            input}
        </div>
        {files.length > 0 && submitButton}
      </div>
    );
  };

  const Preview = (props) => {
    const {
      className,
      imageClassName,
      style,
      imageStyle,
      fileWithMeta: { cancel, remove, restart },
      meta: {
        id,
        name = "",
        percent = 0,
        size = 0,
        previewUrl,
        status,
        duration,
        validationError,
      },
      isUpload,
      canCancel,
      canRemove,
      canRestart,
      extra: { minSizeBytes },
    } = props;

    let statusCustom = exteriorErrorFiles.includes(id)
      ? "exception_upload"
      : status;

    return (
      <tr className="cart-item">
        <td className="product" style={{ paddingLeft: "5px" }}>
          <a>{name}</a>
        </td>
        <td className="product">
          <a>{formatBytes(size)}</a>
        </td>
        <td className="product">
          <div
            className="progress-bar bg-success"
            role="progressbar"
            data-to={100}
            data-delay={500}
            aria-valuenow={100}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{
              width:
                Math.round(percent) < 10
                  ? "10%"
                  : Math.round(percent) === 100
                  ? "99" + "%"
                  : Math.round(percent) + "%",
              height: "20px",
              color: "white",
            }}
          >
            <strong>
              {Math.round(percent) === 100
                ? statusCustom === "done"
                  ? 100 + "%"
                  : 99 + "%"
                : Math.round(percent) + "%"}                
            </strong>            
          </div>          
        </td>
        <td style={{ minWidth: "20px" }} className="product"></td>
        <td className="product">
          <button
            style={{ padding: "4px 8px !important", cursor:"text" }}
            ref={(el) => {
              if (el) {
                el.style.setProperty("padding", "4px 8px", "important");
              }
            }}
            type="button"
            className={
              "btn " +
              (imageStatusClass[statusCustom]
                ? imageStatusClass[statusCustom]
                : statusCustom) +
              " estado-carga font-weight-bold btn-1 btn-fs-1  text-2 mb-0"
            }
          >
            {" "}
            {statusCustom === "uploading" && (
              <img
                style={{ width: "14px", height: "14px", marginRight: "10px" }}
                src={spinner}
              />
            )}
            {statusCustom !== "uploading" && (
              <i
                style={{ marginRight: "10px" }}
                className={
                  "fa fa-icon  " +
                  (imageStatusIcon[statusCustom]
                    ? imageStatusIcon[statusCustom]
                    : statusCustom) +
                  " ml-1 mr-1"
                }
              />
            )}{" "}
            {
              Math.round(percent) === 0 && statusCustom === 'uploading' 
              ? " iniciando carga"
              : imageStatusLabel[statusCustom]
              ? imageStatusLabel[statusCustom]
              : statusCustom}
          </button>
          {statusCustom === "ready" && (
            <button
              style={{ padding: "4px 8px !important" }}
              ref={(el) => {
                if (el) {
                  el.style.setProperty("padding", "4px 8px", "important");
                }
              }}
              type="button"
              className="btn btn-danger estado-carga font-weight-bold btn-1 btn-fs-1 mt-0 ml-2 text-2 mb-0"
              onClick={() => {
                //imageSelected.remove();
                //setImageSelected(null);
                remove();
              }}
            >
              {" "}
              <i
                style={{ marginRight: "10px" }}
                className="fa fa-trash ml-1 mr-1"
              />{" "}
              Eliminar imagen
            </button>
          )}
        </td>
      </tr>
    );
  };

  const PreviewPersisted = (props) => {
    const {
      meta: { name = "", percent = 0, size = 0, status },
    } = props;

    return (
      <tr className="cart-item" style={{ backgroundColor: "#edf2fa" }}>
        <td className="product" style={{ paddingLeft: "5px" }}>
          <a>{name}</a>
        </td>
        <td className="product">
          <a>{formatBytes(size)}</a>
        </td>
        <td className="product"></td>
        <td style={{ minWidth: "20px" }} className="product"></td>
        <td className="product">
          <button
            style={{ padding: "4px 8px !important" }}
            ref={(el) => {
              if (el) {
                el.style.setProperty("padding", "4px 8px", "important");
              }
            }}
            type="button"
            className={
              "btn " +
              (imageStatusClass[status] ? imageStatusClass[status] : status) +
              " estado-carga font-weight-bold btn-1 btn-fs-1  text-2 mb-0"
            }
          >
            {" "}
            <i
              style={{ marginRight: "10px" }}
              className={
                "fa fa-icon  " +
                (imageStatusIcon[status] ? imageStatusIcon[status] : status) +
                " ml-1 mr-1"
              }
            />{" "}
            {imageStatusLabel[status] ? imageStatusLabel[status] : status}
          </button>
        </td>
      </tr>
    );
  };

  const getFilesFromEvent = (e) => {
    console.log("debug getfiles", e);
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const handleFileSelected = (e) => {
    const files = Array.from(e.target.files);
    return files;
  };

  return (
    <Dropzone      
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      //onSubmit={handleSubmit}
      autoUpload={false}
      submitButtonDisabled={true}
      accept="image/*"
      PreviewComponent={Preview}
      InputComponent={Input}
      //getFilesFromEvent={getFilesFromEvent}
      LayoutComponent={Layout}
      initialFiles={props.initialFiles}
    />
  );
};

export default DropzoneR;
