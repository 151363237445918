import React, { useEffect, useState, useContext } from "react";
import Head from "../head/head"
import Header from "../header/header"
import SyncSeguimientoNidera from "../syncseguimientonidera/sync"
import TopMenu from "../topmenu/topmenu"
import General from "../general/general"
import Reports from "../reports/reports"
import ImageList from "../images/imagelist"
import ImageUpload from "../images/imageupload"
import Footer from "../footer/footer"
import ImageLoadStatus from "../images/imageloadstatus"
import Leaflet from "../map/leaflet"
import { useBus, useListener } from 'react-bus'
import spinner from "../resources/spinner.gif";

import Favicon from "react-favicon";
import favicon from "../icons/favico.png"


const { REACT_APP_API_URL,  REACT_APP_SSO} = process.env;

const Base = (props) => {
    
  const bus = useBus();
  const [topMenu, setTopMenu] = useState('general')
  const [time, setTime] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [cssCounter, setCssCounter] = useState(0)
  const [cssTotal, setCssTotal] = useState(0)
  const [isSync, setIsSync] = useState(false) // sincronizacion contra auravant
  const [isSyncFields, setIsSyncFields] = useState(false) // campos contra base propia
  const [isSyncImagesSets, setIsSyncImagesSets] = useState(true) // imagessets contra base propia
  const [mapLibs, setMapLibs] = useState({}) // imagessets contra base propia

  /*useEffect(() => {
    window.addEventListener('load', ()=>{});
    return () => {
      window.removeEventListener('load', ()=>{})  
    };
  });*/
  
  useListener('changeMenu', (e)=>{
    setTopMenu(e)
  })  

  useListener('syncFields', (e)=>{
    setIsSyncFields(e);
  })
  
  useListener('syncImagesSets', (e)=>{
    setIsSyncImagesSets(e);
  })

  useListener('syncSeguimientoNidera', (e)=>{    
    if (e==='new' || e==='processing') {
      setIsSync(true)
    }  else {
      setIsSync(false)
    }    
  })

  // estrategia para detectar cuando todos los CSS estan cargados.
  useEffect(
    ()=>{
      if (cssCounter===cssTotal && cssCounter>0) {
        setLoaded(true);
      }
    }
    ,[cssCounter,cssTotal])

  useEffect(
    ()=>{
      console.log("maplib",JSON.stringify(mapLibs))
      if (
        mapLibs['static/leaflet/js/principal.js?v=15']
        && 
        mapLibs['https://unpkg.com/leaflet@1.9.3/dist/leaflet.js']
        && 
        mapLibs['static/leafletlabels/leaflet.label.js']
        && 
        mapLibs['https://unpkg.com/leaflet.markercluster@1.4.1/dist/leaflet.markercluster.js']
        ) {
        reloadMap();
      }
    }
    ,[JSON.stringify(mapLibs)])

  useEffect(() => {

    let jwt_token = localStorage.getItem('nideravuela2_jwt_token');
    if (jwt_token===null) {
      window.location.replace(REACT_APP_SSO);
    }

    appendScript("static/leaflet/js/principal.js?v=15",registerMapLib);     
    appendScript("https://unpkg.com/leaflet@1.9.3/dist/leaflet.js", registerMapLib);
    appendScript("static/leafletlabels/leaflet.label.js", registerMapLib);
    appendScript("https://unpkg.com/leaflet.markercluster@1.4.1/dist/leaflet.markercluster.js", registerMapLib);

    appendScript("static/vendor/jquery/jquery.min.js");
    appendScript("static/vendor/modernizr/modernizr.min.js");    
    appendScript("static/vendor/jquery.appear/jquery.appear.min.js");
    appendScript("static/vendor/jquery.easing/jquery.easing.min.js");
    appendScript("static/vendor/jquery-cookie/jquery-cookie.min.js");
    appendScript("static/vendor/bootstrap/js/bootstrap.bundle.min.js");
    appendScript("static/vendor/common/common.min.js");
    appendScript("static/vendor/jquery.validation/jquery.validation.min.js");
    appendScript("static/vendor/jquery.easy-pie-chart/jquery.easy-pie-chart.min.js");
    appendScript("static/vendor/jquery.gmap/jquery.gmap.min.js");
    appendScript("static/vendor/jquery.lazyload/jquery.lazyload.min.js");
    appendScript("static/vendor/isotope/jquery.isotope.min.js");
    appendScript("static/vendor/owl.carousel/owl.carousel.min.js");
    appendScript("static/vendor/magnific-popup/jquery.magnific-popup.min.js");
    appendScript("static/vendor/vide/vide.min.js");
    appendScript("static/vendor/vivus/vivus.min.js");          
    appendScript("static/js/theme.js");          
    appendScript("static/vendor/rs-plugin/js/jquery.themepunch.tools.min.js");
    appendScript("static/vendor/rs-plugin/js/jquery.themepunch.revolution.min.js");          
    appendScript("static/js/custom.js");          
    
    appendScript("static/js/theme.init.js");

    // todo: pasar a local        
    appendScript("static/leaflet/js/custom.js?v=40"); 
    
    getTime();
     
  }, []);

  useEffect(() => {
      document.title = 'Nidera Vuela';
  }, []);

  useEffect(() => {  
    //todo_recursive: modificar la forma de obtener la url actual
    let url = window.location.protocol+"//"+window.location.host+"/"+topMenu
    window.history.pushState({}, "", url);   
    getTime(); 
  },[topMenu])
  

  const addCssCounter = () => {
    setCssCounter(prev => prev+1)
  }

  const onChangeMenu = (menu) => {
    setTopMenu(menu)
  }

  const getTime = () => {
    try {
    fetch(REACT_APP_API_URL+'api/time')
      .then((response) => {
        return response.json()
      })
      .then((res) => {
        try {
          setTime(res.time);
        } catch (e) {
          console.log("ERROR GET TIME:",e)
        }
      })
    } catch (e) {

    }
    
    /*try {
      fetch(REACT_APP_API_URL+'api/presignedUploadURL')
        .then((response) => {
          return response.json()
        })
        .then((res) => {
          try {
            setTime(res.time);
          } catch (e) {
            console.log("ERROR GET TIME:",e)
          }
        })
      } catch (e) {
  
      }*/

  }

  const appendScript = (scriptToAppend, handlerLoaded=false) => {
    const script = document.createElement("script");
    if (handlerLoaded!=false) {
      script.addEventListener("load", function(event) {
        handlerLoaded(scriptToAppend);
      });
    }
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
  };

  const changeModule = (module, context) => {
    setTopMenu(module)
  }

  const SpinnerTag = (props) => (
    <img
    style={{
      width: props.size+"px",
      height: props.size+"px",
      marginRight: "0px",
    }}
    src={spinner}
  />
  )
  
  const registerMapLib = (lib) => {
    setMapLibs((old)=>{
      old[lib]=true;
      return old;
    })
  }

  const reloadMap = () => {
    bus.emit("reloadMap",true); 
  }
  
  

  return (
    <>     
      <Favicon url={favicon} />
      <Head setCssTotal={setCssTotal} onLoadCss={()=>{        
        addCssCounter()
        }}/>
      {loaded && 
      <>
        <body>      
        <div className="body">
          <div role="main" className="main">
            <SyncSeguimientoNidera/>
            <Header debug={time}/>
            <TopMenu/>
            <div style={{opacity:isSync||isSyncFields||isSyncImagesSets?0.4:1, pointerEvents:isSync||isSyncFields||isSyncImagesSets?"none":""}}>
            <General visible={topMenu==='general'}/>
            <Reports visible={topMenu==='reports'}/>        
            <ImageList visible={topMenu==='images'}/>                
            </div>
          </div>
          <Footer/>      
        </div>
        </body>
        <ImageLoadStatus/>     
        </>     
      }
      {loaded===false &&        
          <div>
            <p
                style={{
                marginTop:"100px",
                textAlign:"center",
                top: "50%",                
                zIndex: 0,                
                }}
            >
                <SpinnerTag size="50"/>
            </p>
          </div>        
      }

    {(isSyncFields || isSync || isSyncImagesSets) &&        
          <div style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#f0f0f0",
            padding:"20px",
            borderRadius: "5px",
            boxShadow: "0 10px 10px rgba(0, 0, 0, 0.3)",
            border:"2px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth:"250px"
            
          }}>
            <div style={{textAlign:"center"}}>
              <p style={{fontWeight:"bold", marginTop:"10px"}}>            
                  {isSync?"Sincronizando ...":(isSyncFields||isSyncImagesSets)?"Iniciando ...":""} <SpinnerTag size="30"/>
              </p>
            </div>
          </div>        
      }
      
    </>    
  );
};

export default Base;
