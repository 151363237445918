const formatBytes = (value) => {
  try {
    if (value < 1000000) {
      return (value / 1000).toFixed(2) + " kb";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + " mb";
    }
  } catch (e) {
    return value;
  }
};

export { formatBytes };
