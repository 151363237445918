import React, { useEffect, useState } from "react";

const Footer = (props) => {
  return (    
    <>
    {false &&
    <footer
    id="footer"
    className="footer-hover-links-light"
    style={{ marginTop: "0px" }}
    >
        <div className="container">
            <div className="row">
            {false &&
            <div className="col-lg-4 mb-0 mb-lg-0">
                <a href="#" className="logo">
                <h4 style={{ marginTop: "15px" }}>
                    Nidera <strong>Vuela</strong>
                </h4>
                </a>
                <p className="text-color-light">
                Una plataforma digital desarrollada por Nidera Semillas. v0.4
                </p>
                <br />
                <br />
            </div>
            }
            <div className="col-lg-6 mb-0 mb-lg-0" />
            {false &&
            <div className="col-lg-2">
                <div className="row" style={{ paddingLeft: "15px", paddingRight: "0px" }}>
                <a style={{ width: "100px" }} className="logo">
                    <img
                    alt="Nidera"
                    className="img-fluid mb-2"
                    src="static/img/logo-footer.svg"
                    />
                </a>
                </div>
            </div>
            }
            </div>
            
        </div>
    </footer>
    }
    </>
  
  );
};

export default Footer;
