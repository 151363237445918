exports.imageMetadataFields = {
  DESCARTAR:["Si","No","Revisar"],
  //CULTIVO: ["Maíz", "Girasol"],
  MALEZA: ["0%", "1-10%", "11-30%", "+30%"],
  "TAMAÑO PLANTAS": ["Muy pequeñas","No se tocan", "Apenas se tocan", "Se tocan mucho"],
  LABRANZA: ["No tiene", "Tiene"],
  SOMBRAS: ["No", "Poca sombra", "Sombra larga"],
  FOCO: ["Bueno","Intermedio", "Malo"],
  "LINEAS DE SIEMBRA": ["Ok", "No definidas", "Torcidas", "Bordes / Alambrado"],
  "COLOR SUELO": ["No se ve", "Negro", "Marrón", "Gris", "Rojo"],
  RASTROJO: ["No", "Poco", "Medio", "Alto"],
};
