import React, { useEffect, useState, useContext } from "react";
import DropzoneRW from "../uploader/DropzoneRW"
import Leaflet from "../map/leaflet"
import { isJSDocUnknownType } from "typescript";
import { statusClass, statusLabel, statusIcon } from './statusConstants'
import spinner from '../resources/spinner.gif'
import { formatBytes } from "../images/helperFormat";
import { ApiFetch, SyncApiFetch } from '../../utils/ApiFetch';
import { ImagesContext } from '../../utils/ImagesContext'
import { makeStyles } from '@mui/styles'

const useStylesEnabledDropzone = makeStyles({
    enabled: {
        //pointerEvents: "none",
        //opacity: 0.4
    },
    disabled: {
        pointerEvents: "none",
        opacity: 0.7
    }
});

const ImageUpload = (props) => {

    const classesEnabledDropzone = useStylesEnabledDropzone();
    const { fields, setGlobalCancelUploaderFlag, setGlobalInitUploadFlag } = useContext(ImagesContext);

    const [dropzoneRWRef, setDropzoneRWRef] = useState({ uploading: () => { } })
    const [viewMode, setViewMode] = useState('uploadPanel');
    const [imageSelected, setImageSelected] = useState(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [imagesErrorList, setImagesErrorList] = useState('')
    const [globalStatus, setGlobalStatus] = useState('READY')
    const [uploadStatus, setUploadStatus] = useState('EMPTY')
    const [siteMapLabel, setSiteMapLabel] = useState('-');
    const [editHeaderMode, setEditHeaderMode] = useState(false)
    const [imagesSetHeaderName, setImagesSetHeaderName] = useState(null)
    const [lastKeyPressedImagesSetHeaderName, setLastKeyPressedImagesSetHeaderName] = useState([]);
    const [showWaitPersisting, setShowWaitPersisting] = useState(false);
    const [imagesHandler, setImagesHandler] = useState({});
    const [isCompleted, setIsCompleted] = useState(false);
    const [farmId, setFarmId] = useState(-1);
    const [lotId, setLotId] = useState(-1);
    const [samplingId, setSamplingId] = useState(-1);
    const [lots, setLots] = useState([]);
    const [samplings, setSamplings] = useState([]);
    const [lastDatetimeSaved, setLastDatetimeSaved] = useState(null)
    const [leafletReinit, setLeafletReinit] = useState(() => { })
    const [clients, setClients] = useState([])
    const [client, setClient] = useState(null);
    const [farms, setFarms] = useState([])
    const [headerDisabled, setHeaderDisabled] = useState(true)
    const [successSaved, setSuccessSaved] = useState(true);
    const [successSavedTryAgainArgs, setSuccessSavedTryAgainArgs] = useState({ checkKeyTimeout: false, values: {} });
    const [isCompletedTryAgain, setIsCompletedTryAgain] = useState(false);
    const [statusCountLocal, setStatusCountLocal] = useState({})
    const [dropzoneEnabled, setDropzoneEnabled] = useState(true)

    useEffect(() => {
        let clientsObj = {}
        let clients = [];
        for (let field of fields) {
            clientsObj[field["client"]] = true;
        }
        for (let client of Object.keys(clientsObj)) {
            clients.push(client)
        }
        setClients(clients);
    }, [fields])

    useEffect(() => {
        if (['EMPTY', 'READY', 'UPLOADING', 'PAUSED'].includes(uploadStatus) === false) {
            setHeaderDisabled(true)
        } else {
            setHeaderDisabled(false)
        }
    }, [uploadStatus])


    useEffect(() => {
        console.log(imagesHandler)
    }, [JSON.stringify(imagesHandler)])

    useEffect(() => {
        let farms = []
        for (let field of fields) {
            if (field.client === client) {
                farms.push(field)
            }
        }
        setFarms(farms)
    }, [client, clients])

    useEffect(() => {
        for (let lot of lots) {
            if (lot.lot_id === parseInt(lotId)) {
                setSamplings(lot.samplings);
            }
        }
    }, [lotId, lots])

    useEffect(() => {
        for (let field of fields) {
            if (field.farm_id === parseInt(farmId)) {
                setLots(field.lots);
            }
        }
    }, [farmId, farms])

    useEffect(() => {
        let error = false;
        let images = [];
        if (props?.imagesSet?.Images?.length) {
            for (let image of props.imagesSet?.Images) {
                if (image.is_uploaded === 0) {
                    error = true;
                    images.push(image.actual_name)
                }
            }
        }
        setImagesErrorList(images.join(", "))
        setShowErrorMessage(error);
    }, [props?.imagesSet?.Images?.length]);


    useEffect(() => {
        if (props.onStatusChange && props.imagesSet) {
            let imagesLength = countWithoutKeyFromImagesHandler('removed');
            let imagesUploadingLength = countWithKeyFromImagesHandler('uploading') + countWithKeyFromImagesHandler('getting_upload_params');
            let imagesUploadLength = countWithKeyFromImagesHandler('done');
            let imagesErrorLength = countWithKeyFromImagesHandler('exception_upload') + countWithKeyFromImagesHandler('error_upload');


            let statusCount = {
                imagesLength,
                imagesUploadingLength,
                imagesUploadLength,
                imagesErrorLength
            }

            let STATUS = 'EMPTY';

            if (imagesLength === 0) {
                STATUS = 'EMPTY';
            }
            else if (imagesLength > 0 && imagesUploadingLength === 0 && imagesUploadLength === 0 && imagesErrorLength === 0) {
                STATUS = 'READY';
            }
            else if (imagesLength > 0 && imagesUploadingLength > 0 && imagesUploadingLength <= imagesLength) {
                STATUS = 'UPLOADING';
            }
            else if (imagesLength > 0 && imagesErrorLength > 0 && imagesUploadingLength === 0) {
                STATUS = 'PAUSED';
            }
            else if (imagesLength > 0 && imagesUploadLength === imagesLength) {
                STATUS = 'UPLOADED'
                setIsCompleted(true);
            }

            if (STATUS === 'EMPTY' || STATUS === 'READY') {
                if (props.statusCountPersisted.imagesErrorLength > 0) {
                    if (imagesLength > 0 && imagesErrorLength === 0 && imagesUploadingLength === 0) {
                        STATUS = 'READY';
                    } else {
                        STATUS = 'PAUSED';
                    }
                } else {
                    if (
                        props.statusCountPersisted.imagesUploadLength > 0
                        &&
                        props.statusCountPersisted.imagesLength > 0
                        &&
                        props.statusCountPersisted.imagesUploadLength === props.statusCountPersisted.imagesLength
                    ) {
                        //STATUS = 'UPLOADED';
                    }
                }
            }
            if (props.imagesSet.is_completed && props.imagesSet.processing_status === 'NOT_PROCESSED') {
                STATUS = 'UPLOADED';
            }
            if ([
                'ERROR_CROP',
                'ERROR_NO_IMAGES',
                'ERROR_NO_OK_IMAGES',
                'ERROR_UNDEFINED',
                'ERROR_MANUAL_DELETION',
                'OK'
            ].includes(props.imagesSet.processing_status)
            ) {
                STATUS = 'REPORT_GENERATED';
            }
            if ([
                'RECALCULATE',
                'MANUAL_CHECK',
                'MANUAL_CHECKING',
                'PROCESSING'
            ].includes(props.imagesSet.processing_status)) {
                STATUS = 'PROCESSING';
            }

            props.onStatusChange(props.imagesSet.image_set_id, STATUS, statusCount)
            setStatusCountLocal(statusCount)
            setUploadStatus(STATUS);

        }
        console.log(1);
    },
        [
            JSON.stringify(imagesHandler),
            //props.status              
        ])

    useEffect(() => {
        if (imagesSetHeaderName === null) return;
    },
        [imagesSetHeaderName]
    )

    useEffect(() => {
        //setImagesSetHeaderName(props.imagesSet?.name);  todo: fix
    }, [props.imagesSet?.name])

    useEffect(() => {
        if (props.mode === 'NEW') {
            setSiteMapLabel('Nuevo Set')
            setEditHeaderMode(true)
        } else if (props.mode === 'EDIT') {
            setSiteMapLabel(props.imagesSet?.name + ((client != null && client != '') ? ' » ' + client : ''))
            setEditHeaderMode(true)
            bindHeaderToEdit()
        }
    }, [props.mode])

    useEffect(() => {
        setSiteMapLabel(props.imagesSet?.name + ((client != null && client != '') ? ' » ' + client : ''))
    }, [props.imagesSet?.name, client])


    useEffect(async () => {
        if (isCompleted) {
            // todo: ajustar async
            let ret = await setImagesSetCompleted();
            let STATUS = '';
            if (ret === false) {
                setIsCompleted(false);
                STATUS = 'UPLOADING';
                setIsCompletedTryAgain(true);
            } else {
                STATUS = 'UPLOADED';
                setIsCompletedTryAgain(false);
            }
            setUploadStatus(STATUS);
            props.onStatusChange(props.imagesSet.image_set_id, STATUS, statusCountLocal)
        }
    }, [isCompleted]);

    useEffect(() => {
        //dispatchToPersistence()
    }, [samplingId])

    const SpinnerTag = (props) => (
        <img
            style={{
                width: props.size + "px",
                height: props.size + "px",
                marginRight: "0px",
            }}
            src={spinner}
        />
    )

    const setReinitHandler = (func) => {
        setLeafletReinit(func);
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const handleClientChange = (e) => {
        setClient(e.target.value);
        setFarmId(-1)
        setLotId(-1)
        setSamplingId(-1)
        let values = {
            lotId,
            farmId,
            samplingId,
            imagesSetHeaderName,
            client
        }
        dispatchToPersistenceWithValues(false, { ...values, client: e.target.value })
    }

    const handleFarmChange = (e) => {
        setFarmId(e.target.value);
        setLotId(-1)
        setSamplingId(-1)
        let values = {
            lotId,
            farmId,
            samplingId,
            imagesSetHeaderName,
            client
        }
        dispatchToPersistenceWithValues(false, { ...values, farmId: e.target.value })
    }

    const handleLotChange = (e) => {
        setLotId(e.target.value);
        setSamplingId(-1)
        let values = {
            lotId,
            farmId,
            samplingId,
            imagesSetHeaderName,
            client
        }
        dispatchToPersistenceWithValues(false, { ...values, lotId: e.target.value })
    }

    const handleSamplingChange = (e) => {
        setSamplingId(e.target.value);
        let values = {
            lotId,
            farmId,
            samplingId,
            imagesSetHeaderName,
            client
        }
        dispatchToPersistenceWithValues(false, { ...values, samplingId: e.target.value })
    }

    const setImagesSetCompleted = async () => {
        try {
            let ret = await SyncApiFetch(
                "api/complete_images_set",
                true,
                {
                    method: "POST",
                    body: JSON.stringify({ id: props.imagesSet.image_set_id }),
                }
            );
            return true;

        } catch (e) {
            return false;
        }
    }

    const countWithKeyFromImagesHandler = (key) => {
        let count = 0;
        for (let id of Object.keys(imagesHandler)) {
            if (imagesHandler[id].status === key) {
                count++;
            }
        }
        return count;
    }
    const countWithoutKeyFromImagesHandler = (key) => {
        let count = 0;
        for (let id of Object.keys(imagesHandler)) {
            if (imagesHandler[id].status !== key) {
                count++;
            }
        }
        return count;
    }

    const dispatchToPersistence = async () => {
        let now = new Date().getTime();
        if (now - lastKeyPressedImagesSetHeaderName[lastKeyPressedImagesSetHeaderName.length - 1] >= 500) {
            if (props.onImagesSetHeaderChange) {
                setShowWaitPersisting(true);
                setLastDatetimeSaved("Guardando ...")
                // los calculo dinamicamente para los filtros
                let lotName = lots.find(({ lot_id }) => lot_id === parseInt(lotId))?.lot_name;
                let farmName = farms.find(({ farm_id }) => farm_id === parseInt(farmId))?.farm_name;
                //
                let res = await props.onImagesSetHeaderChange(props.imagesSet.image_set_id,
                    {
                        // genero un  objeto ampliado, similar al que trae la api una vez persistido...
                        name: imagesSetHeaderName,
                        samplingId: samplingId,
                        reportsTypes: ['plant_count'],
                        farmId: parseInt(farmId),
                        farmName: farmName,
                        lotId: parseInt(lotId),
                        lotName: lotName,
                        samplingId: parseInt(samplingId),
                        clientName: client
                    })
                // todo: ajustar tiempos
                if (res) {
                    //setTimeout(()=>{
                    setLastDatetimeSaved("Guardado la última vez: " + new Date().toLocaleString() + "")
                    setShowWaitPersisting(false);
                    //},1000)                    
                } else {
                    //setTimeout(()=>{
                    setLastDatetimeSaved("No pudo guardar: " + new Date().toLocaleString() + "")
                    setShowWaitPersisting(false);
                    //},1000)
                }
            }
        }
    }



    const dispatchToPersistenceWithValues = async (checkKeyTimeout, values) => {
        setDropzoneEnabled(false)
        let now = new Date().getTime();
        if (now - lastKeyPressedImagesSetHeaderName[lastKeyPressedImagesSetHeaderName.length - 1] >= 500 || checkKeyTimeout === false) {
            if (props.onImagesSetHeaderChange) {
                setShowWaitPersisting(true);
                setLastDatetimeSaved("Guardando ...")
                // los calculo dinamicamente para los filtros
                let lotName = lots.find(({ lot_id }) => lot_id === parseInt(values.lotId))?.lot_name;
                let farmName = farms.find(({ farm_id }) => farm_id === parseInt(values.farmId))?.farm_name;
                //
                let id = props.imagesSet.image_set_id;
                let arg = {
                    // genero un  objeto ampliado, similar al que trae la api una vez persistido...
                    name: values.imagesSetHeaderName,
                    samplingId: values.samplingId,
                    reportsTypes: ['plant_count'],
                    farmId: parseInt(values.farmId),
                    farmName: farmName,
                    lotId: parseInt(values.lotId),
                    lotName: lotName,
                    samplingId: parseInt(values.samplingId),
                    clientName: values.client
                }

                // persisto en el parent
                props.onImagesSetHeaderChange(id, arg)
                // todo: ajustar tiempos

                // persisto en api
                let res = false;
                try {
                    let persistedImagesSet = await SyncApiFetch(
                        "api/update_images_set",
                        true,
                        {
                            method: "POST",
                            body: JSON.stringify({ ...arg, id: id }),
                        }
                    );
                    // todo: implementar errores controlados (menor)                         
                    res = true;
                } catch (e) {
                    res = false;
                }

                //await delay(4000)

                if (res) {
                    //setTimeout(()=>{
                    setLastDatetimeSaved("Guardado la última vez: " + new Date().toLocaleString() + "")
                    setShowWaitPersisting(false);
                    setSuccessSaved(true);
                    //},1000)                    
                } else {
                    //setTimeout(()=>{
                    // implemento reintento
                    setLastDatetimeSaved("No se pudo guardar. Click para Reintentar")
                    setSuccessSaved(false);
                    setShowWaitPersisting(false);
                    setSuccessSavedTryAgainArgs({ checkKeyTimeout, values })
                    //},1000)
                }


            }
        }
        setDropzoneEnabled(true)
    }

    const bindImageSelected = (image) => {
        setImageSelected(imagesHandler[image]);
    }

    const nextImage = (image) => {

        let i = 0;
        let nextImageIndex = 0;
        let imagesIds = Object.keys(imagesHandler);
        let imagesIdsWithoutRemoved = [];
        for (let idTemp of imagesIds) {
            if (imagesHandler[idTemp].status !== 'removed') {
                imagesIdsWithoutRemoved.push(idTemp)
            }
        }
        imagesIds = imagesIdsWithoutRemoved;
        for (let imageTempId of imagesIds) {
            if (image.id === imageTempId) {
                if (i === imagesIds.length - 1) {
                    nextImageIndex = 0; // vuelve a la primera
                } else {
                    nextImageIndex = i + 1;
                }
            }
            i++;
        }

        setImageSelected(imagesHandler[imagesIds[nextImageIndex]])
    }

    const prevImage = (image) => {


        let prevImageIndex = 0;
        let imagesIds = Object.keys(imagesHandler);
        let imagesIdsWithoutRemoved = [];
        for (let idTemp of imagesIds) {
            if (imagesHandler[idTemp].status !== 'removed') {
                imagesIdsWithoutRemoved.push(idTemp)
            }
        }
        imagesIds = imagesIdsWithoutRemoved;
        let i = 0;
        for (let imageTempId of imagesIds) {
            if (image.id === imageTempId) {
                if (i === 0) {
                    prevImageIndex = imagesIds.length - 1;; // vuelve a la ultima
                } else {
                    prevImageIndex = i - 1;
                }
            }
            i++;
        }

        setImageSelected(imagesHandler[imagesIds[prevImageIndex]])
    }

    const deleteAllImages = () => {
        for (let id of Object.keys(imagesHandler)) {
            if (imagesHandler[id].status != 'removed') {
                if (imagesHandler[id]?.handler?.handlers) {
                    imagesHandler[id].handler.handlers.cancel() // todo: analizar que si la respuesta esta en curso la ignore en la base.
                    if (imagesHandler[id]?.remove) {
                        imagesHandler[id].remove()
                    }
                }
            }
        }
        setImageSelected(null)
    }

    const bindHeaderToEdit = () => {
        setImagesSetHeaderName(props.imagesSet.name)
        setClient(props.imagesSet.client)
        setFarmId(props.imagesSet.farm_id)
        setLotId(props.imagesSet.lot_id)
        setSamplingId(props.imagesSet.sampling_id)
        setEditHeaderMode(true)
    }


    return (
        <div style={{ display: props.visible ? '' : 'none' }}>

            {/** CUSTOM HEAD*/}
            <head>
                <link rel="stylesheet" href="static/css/components/imageupload.css" />
                <xlink rel="stylesheet" href="static/css/components/mappanel.css" />
            </head>
            {/** CUSTOM HEAD*/}
            <div className="container">
                {/*Images {Object.keys(images)?.length},
                Uploading {Object.keys(imagesUploading)?.length},
                ImagesUploaded {Object.keys(imagesUploaded)?.length},
                ImagesError {Object.keys(imagesError)?.length},
                <br/>*/}
                {/*{JSON.stringify(imagesUploading)}
                <br/>
                {JSON.stringify(imagesUploaded)}*/}
                <ul className="nav nav-tabs mb-2 mt-2" role="tablist">
                    <div className="col-md-9 pl-0 mb-2 mb-md-0">
                        <li className="nav-item">
                            <a
                                style={{
                                    color: "#4e5153",
                                    textAlign: "left",
                                    fontWeight: "700",
                                    paddingLeft: "0px !important"
                                }}
                                ref={(el) => {
                                    if (el) {
                                        el.style.setProperty('padding-left', '0px', 'important');
                                    }
                                }}

                                className="nav-link"
                            >
                                {" "}
                                CARGA DE IMÁGENES » {siteMapLabel}
                                {lastDatetimeSaved &&
                                    <button
                                        onClick={() => {
                                            if (successSaved) {
                                                return;
                                            } else {
                                                dispatchToPersistenceWithValues(false, successSavedTryAgainArgs.values)
                                            }
                                        }}
                                        className={"btn btn-" + (successSaved ? 'success' : 'danger') + " ml-2 font-weight-bold btn-2 btn-fs-2 text-2 mb-1"}

                                    >
                                        {lastDatetimeSaved}
                                    </button>
                                }
                                {false &&
                                    <i
                                        onClick={() => {
                                            bindHeaderToEdit()
                                        }}
                                        className={"fa fa-icon fa-edit mr-2 ml-2"}
                                    />
                                }
                                <button
                                    type="button"
                                    style={{ cursor: "text" }}
                                    className={"btn " + statusClass[uploadStatus] + " estado-carga ml-2 font-weight-bold btn-2 btn-fs-2  text-2 mb-1"}
                                >
                                    {" "}
                                    {uploadStatus === 'UPLOADING' &&
                                        <img style={{ width: "18px", height: "18px", marginRight: "10px" }} src={spinner} />
                                    }
                                    {uploadStatus !== 'UPLOADING' &&
                                        <i
                                            style={{ marginRight: "10px" }}
                                            className={"fa fa-icon " + statusIcon[uploadStatus] + " ml-1 mr-1"}
                                        />
                                    }
                                    {uploadStatus !== 'EMPTY' &&
                                        statusLabel[uploadStatus]
                                    }
                                </button>
                            </a>
                        </li>
                    </div>
                    <div align="right" className="col-md-3 pr-0 mb-2 mb-md-0">
                        <a>
                            {" "}
                            <button
                                style={{
                                    backgroundColor: "#e1e1e1",
                                    borderColor: "#e1e1e1",
                                    color: "black"
                                }}
                                onClick={() => {
                                    if (props.onHide) {
                                        props.onHide();
                                    }
                                }}
                                type="button"
                                className="btn btn-primary align-items-center line-height-1 py-1 mb-2 mt-2 mr-1"
                            >
                                {" "}
                                <i
                                    style={{ color: "black" }}
                                    className="lnr lnr-arrow-left-circle mr-2 hidden-xs"
                                    aria-hidden="true"
                                />{" "}
                                <strong>Volver</strong>&nbsp; a listado de sets{" "}
                            </button>
                        </a>
                    </div>
                </ul>
                {showErrorMessage && uploadStatus === 'PAUSED' &&
                    <div className="form-row mt-2 mb-0">
                        <div className="form-group mb-1 col-lg-12">
                            <div
                                className="tab-pane fade pt-2 pb-0 active show"
                                id="default-process"
                                role="tabpanel"
                                aria-labelledby="default-process-tab"
                            >
                                <div className="alert mb-1 alert-danger">
                                    Ha ocurrido un error en el proceso de carga de tus imágenes.
                                    <br />
                                    <strong>Importante: </strong> a continuación podrás reiniciar la carga
                                    de imágenes. No hace falta que cargues todo nuevamente. Sumá solo
                                    aquellas imágenes que no hayan llegado a cargarse:<br />
                                    <b>{imagesErrorList}</b>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 mb-md-0">
                            {/** 
                    <a href="carga-imagenes-detalle.html">
                        <button
                        type="button"
                        className="btn-outline btn-dark altura  align-items-center line-height-1 py-2 mb-0 mt-1 mr-1"
                        >
                        <i
                            style={{ color: "#1c1f21 !important" }}
                            ref={(el) => {
                                if (el) {
                                  el.style.setProperty('color', '#1c1f21', 'important');
                                }
                              }}                                                    
                            className="fa fa-plus-circle mr-1"                            
                        />{" "}
                        Reiniciar carga de imágenes a este Set
                        </button>
                    </a>
                    */}
                        </div>
                    </div>
                }
                {editHeaderMode &&
                    <div className="form-row mt-2 mb-2" style={{ opacity: headerDisabled ? 0.5 : 1, pointerEvents: headerDisabled ? "none" : "" }}>
                        <div className="form-group col-lg-4">
                            <label htmlFor="frmRegisterPassword">
                                <strong>NOMBRE SET</strong>
                                {showWaitPersisting &&
                                    <>
                                        <img
                                            style={{ width: "18px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                            src={spinner}
                                        />
                                        Guardando ...
                                    </>
                                }
                            </label>
                            <input
                                type="text"
                                placeholder={""}
                                id="nombre-set"
                                value={imagesSetHeaderName}
                                onChange={
                                    (e) => {
                                        setImagesSetHeaderName(e.target.value)

                                        let now = new Date().getTime();
                                        setLastKeyPressedImagesSetHeaderName(last => {
                                            last.push(now);
                                            return last;
                                        })
                                        setTimeout(() => {
                                            let values = {
                                                lotId,
                                                farmId,
                                                samplingId,
                                                imagesSetHeaderName,
                                                client
                                            }
                                            dispatchToPersistenceWithValues(true, { ...values, imagesSetHeaderName: e.target.value })
                                            //dispatchToPersistenceWithValues(false,{...values,imagesSetHeaderName:e.target.value})        
                                        }, 500);

                                    }
                                }
                                className="form-control"
                                ref={(el) => {
                                    if (el) {
                                        el.style.setProperty('height', '42px', 'important');
                                    }
                                }}
                                style={{
                                    background: "rgba(0, 0, 0, 0.1)",
                                    opacity: 1,
                                    height: "42px !important",
                                    borderRadius: 4
                                }}
                            />

                        </div>
                    </div>
                }
                {editHeaderMode &&
                    <div className="form-row mt-2 mb-2" style={{ opacity: headerDisabled ? 0.5 : 1, pointerEvents: headerDisabled ? "none" : "" }}>
                        <div className="form-group col-lg-3">
                            <label htmlFor="frmRegisterPassword">
                                <strong>CLIENTE</strong>
                            </label>
                            <select
                                className="form-control  my-select altura"
                                value={client}
                                onChange={handleClientChange}
                            >
                                <option value="">Seleccione</option>
                                {clients.map((client) => (
                                    <option value={client}>{client}</option>
                                ))}

                            </select>
                        </div>
                        <div className="form-group col-lg-3">
                            <label htmlFor="frmRegisterPassword">
                                <strong>CAMPO</strong>
                            </label>
                            <select
                                className="form-control  my-select altura"
                                name="prioridad_e"
                                id="prioridad_e"
                                value={farmId}
                                onChange={handleFarmChange}
                            >
                                <option value="">Seleccione</option>
                                {farms.map((farm) => {
                                    return (<option value={farm.farm_id}>{farm.farm_name}</option>)
                                })}

                            </select>
                        </div>
                        <div className="form-group col-lg-3">
                            <label htmlFor="frmRegisterPassword">
                                <strong>LOTE</strong>
                            </label>
                            <select
                                className="form-control  my-select altura"
                                name="prioridad_e"
                                id="prioridad_e"
                                value={lotId}
                                onChange={handleLotChange}
                            >
                                <option value="">Seleccione</option>
                                {lots.map((lot) => {
                                    return (<option value={lot.lot_id}>{lot.lot_name}</option>)
                                })}
                            </select>
                        </div>
                        <div className="form-group col-lg-3">
                            <label htmlFor="frmRegisterPassword">
                                <strong>MUESTREO</strong>
                            </label>
                            <select
                                className="form-control  my-select altura"
                                name="prioridad_e"
                                id="prioridad_e"
                                value={samplingId}
                                onChange={handleSamplingChange}
                            >
                                <option value="">Seleccione</option>
                                {samplings.map((sampling) => {
                                    return (<option value={sampling.sampling_id}>{sampling.sampling_name} ({sampling.sampling_metadata_date})</option>)
                                })}
                            </select>
                        </div>
                    </div>
                }


                <div className="form-row mt-2 mb-0" style={{ opacity: headerDisabled ? 0.5 : 1, pointerEvents: headerDisabled ? "none" : "" }}>
                    <div className="form-group mb-1 col-lg-12">
                        <label htmlFor="frmRegisterPassword">
                            <strong>TIPOS DE REPORTE SOLICITADOS</strong>
                        </label>
                    </div>
                </div>
                <div className="form-row mt-0 mb-0" style={{ opacity: headerDisabled ? 0.5 : 1, pointerEvents: headerDisabled ? "none" : "" }}>
                    <div
                        align="center"
                        className="form-group mr-3 box-reporte active bg-light-3 col-lg-2"
                    >
                        <img width="40%" src="static/img/ic-conteo.svg" alt="" />
                        <input
                            style={{ marginTop: "5px" }}
                            defaultChecked={true}
                            type="checkbox"
                            checked={true}
                            id="conteo_planas"
                            defaultValue={1}
                            className="form-control"
                        />
                        <p className="mb-0" style={{ fontWeight: "600", color: "#1c1f21" }}>
                            {" "}
                            Conteo de Plantas
                        </p>
                    </div>
                </div>
                {countWithoutKeyFromImagesHandler('removed') > 0 &&
                    <>
                        <div className="row mt-2">
                            <div className="col">
                                <ul
                                    className="nav nav-tabs nav-tabs-big-icons nav-tabs-big-icons-active-colored nav-tabs-primary"
                                    id="tabBigIconsActiveColored"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a onClick={() => { setViewMode('mapPanel') }} className={'nav-link ' + (viewMode === 'mapPanel' ? 'active' : '')}>
                                            <i className="lnr lnr-location" />
                                            Previsualización Coordenadas
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={() => { setViewMode('uploadPanel') }} className={'nav-link ' + (viewMode === 'uploadPanel' ? 'active' : '')}>
                                            <i className="lnr lnr-picture" />
                                            Carga de imágenes
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <p className="mb-3 mt-3">
                            A continuación podrás ver la ubicación geográfica de tus imágenes.
                            Haciendo clic sobre la misma podrás ver una previsualización y descartarla
                            en caso de requerirlo.
                            <br />
                            <strong>Importante:</strong> Una vez confirmado el proceso se iniciará el
                            procesamiento de la información y{" "}
                            <strong>no podrás agregar o quitar imágenes del set. </strong>
                            <br />
                        </p>


                        {(uploadStatus === 'READY' || (countWithKeyFromImagesHandler('exception_upload') > 0) || (countWithKeyFromImagesHandler('error_upload') > 0)) &&
                            <>
                                {
                                    (
                                        (
                                            countWithKeyFromImagesHandler('exception_upload') === 0
                                            &&
                                            countWithKeyFromImagesHandler('error_upload') === 0
                                            &&
                                            (Object.keys(imagesHandler).length - countWithKeyFromImagesHandler('removed') === countWithKeyFromImagesHandler('ready'))
                                        )
                                        ||
                                        (countWithKeyFromImagesHandler('exception_upload') > 0 || countWithKeyFromImagesHandler('error_upload') > 0)
                                    )
                                    &&
                                    <button
                                        type="button"
                                        className="btn btn-success btn-rounded  align-items-center line-height-1 py-2 mb-0 mt-1 mr-1"
                                        //</>disabled={
                                        //    !(
                                        //        countWithKeyFromImagesHandler('exception_upload')===0
                                        //        && 
                                        //        (Object.keys(imagesHandler).length-countWithKeyFromImagesHandler('removed')===countWithKeyFromImagesHandler('ready'))
                                        //    )
                                        //}                        

                                        onClick={() => {
                                            if (countWithKeyFromImagesHandler('exception_upload') === 0 && countWithKeyFromImagesHandler('error_upload') == 0) {
                                                dropzoneRWRef.uploading();
                                            }
                                            else if ((countWithKeyFromImagesHandler('exception_upload') + countWithKeyFromImagesHandler('error_upload') > 0)) {
                                                dropzoneRWRef.reinitUpload();
                                            }

                                            //setTimeout(()=>{setGlobalStatus('READY');},2000);
                                            setViewMode('uploadPanel');
                                            setGlobalCancelUploaderFlag(false);
                                            setGlobalInitUploadFlag(true);
                                        }}
                                    >
                                        <i style={{ color: "white !important" }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('color', 'white', 'important');
                                                }
                                            }}
                                            className="fa fa-check mr-1" />{" "}
                                        {
                                            (
                                                countWithKeyFromImagesHandler('exception_upload') === 0
                                                &&
                                                countWithKeyFromImagesHandler('error_upload') === 0
                                                &&
                                                (Object.keys(imagesHandler).length - countWithKeyFromImagesHandler('removed') === countWithKeyFromImagesHandler('ready'))
                                            ) &&
                                            <>Confirmar e iniciar subida
                                            </>
                                        }
                                        {(countWithKeyFromImagesHandler('exception_upload') > 0 || countWithKeyFromImagesHandler('error_upload') > 0) &&
                                            <>Reiniciar subida para los archivos con error</>
                                        }
                                    </button>
                                }
                            </>
                        }
                        {
                            ((uploadStatus === 'READY' || uploadStatus === 'UPLOADING') && isCompletedTryAgain === false) &&
                            <button
                                type="button"
                                onClick={() => {
                                    deleteAllImages();
                                    setViewMode('uploadPanel');
                                    setGlobalCancelUploaderFlag(true);
                                }}
                                className="btn btn-danger btn-rounded  align-items-center line-height-1 py-2 mb-0 mt-1 mr-1"
                            >
                                <i style={{ color: "white !important" }}
                                    ref={(el) => {
                                        if (el) {
                                            el.style.setProperty('color', 'white', 'important');
                                        }
                                    }}

                                    className="fa fa-upload mr-1" />{" "}
                                Cancelar y subir nuevo set de imágenes
                            </button>
                        }
                        {isCompletedTryAgain &&
                            <button
                                className="btn btn-warning btn-rounded  align-items-center line-height-1 py-2 mb-0 mt-1 mr-1"
                                onClick={() => {
                                    setIsCompleted(true);
                                }}
                            >
                                <i style={{ color: "white !important" }}
                                    ref={(el) => {
                                        if (el) {
                                            el.style.setProperty('color', 'white', 'important');
                                        }
                                    }}
                                    className="fa fa-upload mr-1" />{" "}
                                Error de comunicación al actualizar el estado del Set de Imágenes. Click para reintentar.</button>
                        }
                    </>
                }
            </div>

            <div style={{ minHeight: "400px", display: viewMode == 'mapPanel' ? '' : 'none' }} className="container">
                <div className="row mt-2">
                    <div className="col-md-12 mb-4 mb-md-0">

                        <div className="tab-content" style={{ display: viewMode == 'mapPanel' ? '' : 'none' }} id="tabBigIconsActiveColoredContent">
                            <div
                                className="tab-pane fade text-left mb-4 pt-4 pb-4 show active"
                                id="big-icons-active-colored-process"
                                role="tabpanel"
                                aria-labelledby="big-icons-active-colored-process-tab"
                            >


                                <div
                                    className="mt-3"
                                >
                                    {(viewMode === 'mapPanel') &&
                                        <Leaflet images={imagesHandler} reinitHandler={setReinitHandler} samplingId={samplingId} onClickImage={(val) => { bindImageSelected(val) }} />
                                    }
                                </div>

                                <div
                                    className="accordion p-absolute p-2"
                                    id="accordionExample"
                                    style={{
                                        position: "absolute",
                                        top: "90px",
                                        width: "400px",
                                        textAlign: "left",
                                        paddingLeft: "10px !important",
                                        zIndex: '9999'
                                    }}
                                    ref={(el) => {
                                        if (el) {
                                            el.style.setProperty('padding-left', '10px', 'important');
                                        }
                                    }}
                                >
                                    {imageSelected &&
                                        <div className="card" style={{ background: "transparent" }}>
                                            <div
                                                style={{ padding: "0px" }}
                                                className="card-header box-map bg-white"
                                                id="headingOne"
                                            >
                                                <h2 style={{ textDecoration: "none !important" }}
                                                    ref={(el) => {
                                                        if (el) {
                                                            el.style.setProperty('text-decoration', 'none', 'important');
                                                        }
                                                    }}
                                                    className="mb-0">
                                                    <button
                                                        style={{ textDecoration: "none !important" }}
                                                        ref={(el) => {
                                                            if (el) {
                                                                el.style.setProperty('text-decoration', 'none', 'important');
                                                            }
                                                        }}
                                                        className="btn btn-sm  btn-block text-center shadow-none"
                                                        type="button"
                                                    >
                                                        <strong>Imagen Seleccionada <a onClick={() => {
                                                            setImageSelected(null);
                                                        }}>(cerrar)</a></strong>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div aria-labelledby="headingOne" data-parent="#accordionExample">
                                                <div
                                                    className="card-body box-map bg-white"
                                                    style={{
                                                        marginTop: "3px",
                                                        paddingTop: "8px",
                                                        paddingBottom: "8px",
                                                        height: "420px",
                                                        overflow: "scroll"
                                                    }}
                                                >
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item pl-0">
                                                            <div
                                                                style={{ paddingLeft: "0.65rem !important" }}
                                                                ref={(el) => {
                                                                    if (el) {
                                                                        el.style.setProperty('padding-left', '0.65rem', 'important');
                                                                    }
                                                                }}
                                                                className="form-check"
                                                            >
                                                                <strong>Nombre:</strong> {imageSelected?.file?.name}
                                                                <br />
                                                                <strong>Tamaño:</strong> {formatBytes(imageSelected?.file?.size)}
                                                                <br />
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-grey font-weight-bold btn-1 btn-fs-2 mr-1  text-3 mt-2 mb-2"
                                                                >
                                                                    VISTA PREVIA
                                                                </button>
                                                                <span className="image-frame-wrapper mb-2">
                                                                    <img
                                                                        src={URL.createObjectURL(imageSelected?.file)}
                                                                        className="img-fluid"
                                                                        alt=""
                                                                    />
                                                                </span>
                                                                {['EMPTY', 'READY', 'PAUSED'].includes(uploadStatus) &&
                                                                    <a data-toggle="modal" data-target="#eliminarModal" href="#">
                                                                        {" "}
                                                                        <button
                                                                            style={{ padding: "4px 8px !important" }}
                                                                            ref={(el) => {
                                                                                if (el) {
                                                                                    el.style.setProperty('padding', '4px 8px', 'important');
                                                                                }
                                                                            }}
                                                                            type="button"
                                                                            className="btn btn-danger estado-carga font-weight-bold btn-1 btn-fs-1 mt-2 text-2 mb-0"
                                                                            onClick={() => {
                                                                                imageSelected.remove();
                                                                                setImageSelected(null);

                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            <i
                                                                                style={{ marginRight: "10px" }}
                                                                                className="fa fa-trash ml-1 mr-1"
                                                                            />{" "}
                                                                            ELIMINAR IMAGEN
                                                                        </button>
                                                                    </a>
                                                                }
                                                                <div className="row mt-2">
                                                                    <div className="nav-item col-md-6">
                                                                        <a style={{ cursor: "pointer" }} className="nav-link active" onClick={() => { prevImage(imageSelected) }}>
                                                                            <i className="lnr lnr-arrow-left mr-2" />
                                                                            Ver anterior
                                                                        </a>
                                                                    </div>
                                                                    <div align="right" className="nav-item col-md-6">
                                                                        <a style={{ cursor: "pointer" }} className="nav-link" onClick={() => { nextImage(imageSelected) }}>
                                                                            Ver próxima <i className="lnr lnr-arrow-right" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <section
                className="mb-10 pt-0 pb-5"
                style={{ backgroundColor: "white", padding: "0px 0px", display: viewMode == 'uploadPanel' ? '' : 'none' }}
            >
                <div style={{ minHeight: "400px" }} className="container">
                    <div className="row mt-2">
                        <div className="col-md-12 mb-4 mb-md-0">
                            {countWithoutKeyFromImagesHandler('removed') > 0 &&
                                <div class="mt-4">
                                    <div
                                        style={{ paddingLeft: "0px !important" }}
                                        ref={(el) => {
                                            if (el) {
                                                el.style.setProperty('padding-left', '0px', 'important');
                                            }
                                        }}
                                        className="d-flex flex-nowrap px-2 text-center h-fit align-content-center align-items-center"
                                    >
                                        <h3
                                            style={{ marginRight: "45%", textAlign: "left" }}
                                            className="font-weight-bold text-4"
                                        >
                                            Tu set de imágenes: <br />
                                        </h3>
                                    </div>

                                    <div className="row mt-0">

                                        <div style={{ marginTop: "4px" }} className="col-md-3">
                                            Progreso de carga: ({countWithKeyFromImagesHandler('done')}/{countWithoutKeyFromImagesHandler('removed')})
                                        </div>

                                        {
                                            (() => {
                                                let percent = Math.round(((countWithKeyFromImagesHandler('done') * 100) / countWithoutKeyFromImagesHandler('removed')), 1)
                                                let bg = 'bg-danger';
                                                if (percent === 100) {
                                                    bg = 'bg-success';
                                                }
                                                return (
                                                    <div className="col-md-6">
                                                        <div className="progress-bar-wrapper">
                                                            <div className="progress mb-2">
                                                                <div
                                                                    className={"progress-bar " + bg}
                                                                    role="progressbar"
                                                                    data-to={45}
                                                                    data-delay={500}
                                                                    aria-valuenow={45}
                                                                    aria-valuemin={0}
                                                                    aria-valuemax={100}
                                                                    style={{ width: percent < 20 ? 20 : percent + "%", height: "25px", color: "white" }}
                                                                >
                                                                    <strong>{percent}%</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            )()
                                        }
                                    </div>

                                </div>
                            }

                            {(samplingId !== -1 && samplingId != null) &&
                                <div className={"container pl-0 pr-0"}

                                >
                                    {
                                        (
                                            (
                                                dropzoneEnabled === false ||
                                                !((Object.keys(imagesHandler).length - countWithKeyFromImagesHandler('removed') === countWithKeyFromImagesHandler('ready')))
                                            ) &&
                                            //['UPLOADED', 'REPORT_GENERATED', 'PROCESSING'].includes(uploadStatus)===false
                                            (uploadStatus === 'EMPTY' || uploadStatus === 'READY' || (uploadStatus === 'PAUSED'))
                                        )
                                        &&
                                        <p
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                width: "40px",
                                                height: "40px",
                                                zIndex: "1000"
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: "40px",
                                                    height: "40px"
                                                }}
                                                src={spinner}
                                            />
                                        </p>
                                    }
                                    <div className={"tab-content " +

                                        (
                                            (
                                                (
                                                    dropzoneEnabled === false ||
                                                    !((Object.keys(imagesHandler).length - countWithKeyFromImagesHandler('removed') === countWithKeyFromImagesHandler('ready')))
                                                ) &&
                                                //['UPLOADED', 'REPORT_GENERATED', 'PROCESSING'].includes(uploadStatus)===false
                                                ((uploadStatus === 'EMPTY' || uploadStatus === 'READY' || (uploadStatus === 'PAUSED')))
                                            )
                                                ?
                                                classesEnabledDropzone.disabled
                                                :
                                                classesEnabledDropzone.enabled)

                                    } id="tabColorPrimaryContent">
                                        <div

                                            className="tab-pane fade pt-0 pb-4 active show"
                                            id="color-primary-categoria"
                                            role="tabpanel"
                                            aria-labelledby="color-primary-categoria-tab"
                                        >

                                            <DropzoneRW
                                                globalStatus={globalStatus}
                                                imagesSet={props.imagesSet}
                                                showInput={uploadStatus === 'EMPTY' || uploadStatus === 'READY' || (uploadStatus === 'PAUSED')}
                                                dropZoneRWRef={setDropzoneRWRef}
                                                imagesPersisted={props.imagesPersisted}
                                                updateImagesHandler={setImagesHandler}
                                            />


                                        </div>
                                    </div>
                                </div>
                            }
                            {/** 
                        <div className="row">
                        <div
                            align="center"
                            style={{
                            textAlign: "center",
                            marginTop: "30px !important",
                            margin: "auto"
                            }}
                            ref={(el) => {
                                if (el) {
                                  el.style.setProperty('margin-top', '30px', 'important');
                                }
                              }}
                        >
                            <button
                            style={{ paddingTop: "10px", height: "42px !important" }}
                            ref={(el) => {
                                if (el) {
                                  el.style.setProperty('height', '42px', 'important');
                                }
                              }}
                            className="btn-outline btn-dark altura"
                            id="btn-ver-mas"
                            type="button"
                            >
                            MOSTRAR MÁS
                            </button>
                        </div>
                        </div>
                        */}
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
};

export default ImageUpload;  