import React, { useEffect, useState, useContext } from "react";
import { ApiFetch, SyncApiFetch } from '../../utils/ApiFetch';
import ImageUpload from "./imageupload";
import { v4 as uuidv4 } from 'uuid';
import spinner from '../resources/spinner.gif'
import { statusClass, statusLabel, statusIcon } from './statusConstants'
import { ImagesContext } from '../../utils/ImagesContext'
import { ObjectFlags } from "typescript";
import { useBus, useListener } from 'react-bus'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ImageList = (props) => {
    const bus = useBus()

    // susbscripcion a bus de eventos entre objetos activos
    useListener('changeUploaderSelected', (e) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        onChangeUploaderSelected(e)
    })

    useListener('newUploader', (e) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        newUploaderFromEventMap(e)
    })

    useListener('syncSeguimientoNidera', (e) => {
        if (e === 'processed') {
            //getAllImagesSets();
            //initFields();
        }
    })

    useListener('reloadFieldsAndImagesSets', (e) => {
        if (e === true) {
            setUploadersContext([])
            getAllImagesSets();
            initFields();
        }
    })

    const { setGlobalUploadCount, setUploadersContext, uploadersContext, setSincronizedUploadersContext, initFields, campanaSeleccionada, esCampanaActiva } = useContext(ImagesContext);
    const [listVisible, setListVisible] = useState(true);
    const [newVisible, setNewVisible] = useState(false);
    //const [uploaders, setUploaders] = useState([]);

    const [clients, setClients] = useState([])
    const [farms, setFarms] = useState([])
    const [fields, setFields] = useState([])
    const [samplings, setSamplings] = useState([])
    const [status, setStatus] = useState([])

    const [clientsFiltered, setClientsFiltered] = useState([])
    const [farmsFiltered, setFarmsFiltered] = useState([])
    const [fieldsFiltered, setFieldsFiltered] = useState([])
    const [statusFiltered, setStatusFiltered] = useState([])

    const [filterSelectedStatus, setFilterSelectedStatus] = useState(-1)
    const [filterSelectedField, setFilterSelectedField] = useState(-1)
    const [filterSelectedFarm, setFilterSelectedFarm] = useState(-1)
    const [filterSelectedClient, setFilterSelectedClient] = useState(-1)

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [uploaderToDelete, setUploaderToDelete] = React.useState(null);

    const updateContextForParent = (context) => {
        console.log(context)
    }

    const handleClickOpenDeleteDialog = (uploader) => {
        setUploaderToDelete(uploader)
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const deleteImagesSetSelected = () => {
        ApiFetch('api/images_sets/delete/' + uploaderToDelete.imagesSet.image_set_id, true,
            async (ret) => {
                let obj = await ret.json()
                setOpenDeleteDialog(false);
                setUploadersContext((uploadersContextOld) => {
                    let uploadersContextNew = []
                    for (let uploaderTemp of uploadersContextOld) {
                        if (uploaderTemp.id !== uploaderToDelete.id) {
                            uploadersContextNew.push(uploaderTemp)
                        }
                    }
                    return uploadersContextNew;
                })
            }, (ret) => {
                console.log(ret);
                // todo: implementar
            })
    }

    useEffect(() => {

        //getAllImagesSets();
        //if (props.setContextHandler) {
        //    props.setContextHandler(updateContextForParent)
        //}
        // cuando se ejecute esta funcion solamente existira en este contexto, lo que estaba instanciado al momento de la asignacion
        //subscribeToOnChangeUploaderSelected(onChangeUploaderSelected)

    }, [])

    useEffect(() => {

        setUploadersContext([])
        getAllImagesSets();
        initFields();

        if (props.setContextHandler) {
            props.setContextHandler(updateContextForParent)
        }
        // cuando se ejecute esta funcion solamente existira en este contexto, lo que estaba instanciado al momento de la asignacion
        //subscribeToOnChangeUploaderSelected(onChangeUploaderSelected)

    }, [campanaSeleccionada])



    useEffect(() => {
        // clientes
        let clients = {}
        let clientsRet = []
        for (let uploader of uploadersContext) {
            if (uploader.imagesSet.farm_id !== undefined) {
                clients[uploader.imagesSet.client] = { clientId: uploader.imagesSet.client, clientName: uploader.imagesSet.client }
            }
        }
        for (let id of Object.keys(clients)) {
            clientsRet.push({ ...clients[id] })
        }
        setClients(clientsRet)
        setClientsFiltered(clientsRet)


        // campos
        let farms = {}
        let farmsRet = []
        for (let uploader of uploadersContext) {
            if (uploader.imagesSet.farm_id !== undefined) {
                farms[uploader.imagesSet.farm_id] = { farmId: uploader.imagesSet.farm_id, farmName: uploader.imagesSet.farm_name, clientId: uploader.imagesSet.client }
            }
        }
        for (let id of Object.keys(farms)) {
            farmsRet.push({ ...farms[id] })
        }
        setFarms(farmsRet)

        // lotes
        let fields = {}
        let fieldsRet = []
        for (let uploader of uploadersContext) {
            if (uploader.imagesSet.farm_id !== undefined) {
                fields[uploader.imagesSet.lot_id] = { fieldId: uploader.imagesSet.lot_id, fieldName: uploader.imagesSet.lot_name, farmId: uploader.imagesSet.farm_id, status: uploader.status }
            }
        }
        for (let id of Object.keys(fields)) {
            fieldsRet.push({ ...fields[id] })
        }
        setFields(fieldsRet)

        // status
        let status = {}
        let statusRet = []
        for (let uploader of uploadersContext) {
            status[uploader.status] = { status: uploader.status }
        }
        for (let id of Object.keys(status)) {
            statusRet.push({ ...status[id] })
        }
        setStatus(statusRet)

        setUploadersContext(uploadersContext)

        //if (Object.keys(uploadersContext).length>0) {
        //    bus.emit('refreshReports', true)
        //}

    }, [JSON.stringify(uploadersContext)])


    useEffect(() => {
        filterFarms(filterSelectedClient)
    }, [JSON.stringify(farms), JSON.stringify(farmsFiltered), JSON.stringify(clients), JSON.stringify(clientsFiltered)])

    useEffect(() => {
        filterFields(filterSelectedFarm)
    }, [JSON.stringify(farms), JSON.stringify(farmsFiltered), JSON.stringify(clients), JSON.stringify(clientsFiltered), JSON.stringify(fields), JSON.stringify(fieldsFiltered)])


    useEffect(() => {
        filterStatus(filterSelectedField)
    }, [JSON.stringify(status), JSON.stringify(statusFiltered), JSON.stringify(fields), JSON.stringify(fieldsFiltered), JSON.stringify(clients), JSON.stringify(clientsFiltered)])


    const newUploaderFromEventMap = (args) => {
        for (let uploaderTemp of uploadersContext) {
            uploaderTemp.visible = false;
        }
        newImageSetWithHeader(['plant_count'], args.samplingId)
    }

    const onChangeUploaderSelected = (uploader) => {

        for (let uploaderTemp of uploadersContext) {
            uploaderTemp.visible = false;
        }
        uploader.visible = true;
        uploader.mode = 'EDIT';
        setNewVisible(true);
        setListVisible(false);
    }

    const handleFilterSelectedClient = (e) => {
        setFilterSelectedClient(e.target.value)
        setFilterSelectedFarm(-1)
        setFilterSelectedField(-1)
        setFilterSelectedStatus(-1)
        filterFarms(e.target.value)
    }

    const handleFilterSelectedStatus = (e) => {
        setFilterSelectedStatus(e.target.value)
    }

    const handleFilterSelectedField = (e) => {
        setFilterSelectedField(e.target.value)
        setFilterSelectedStatus(-1)
        filterStatus(e.target.value)
    }

    const handleFilterSelectedFarm = (e) => {
        setFilterSelectedFarm(e.target.value)
        setFilterSelectedField(-1)
        setFilterSelectedStatus(-1)
        filterFields(e.target.value)
    }

    const filterFarms = (clientId) => {
        let farmsFiltered = []
        if (parseInt(clientId) === -1) {

            let clientsId = []
            for (let client of clientsFiltered) {
                clientsId.push(client.clientId)
            }

            for (let farm of farms) {
                if (clientsId.includes(farm.clientId)) {
                    farmsFiltered.push(farm)
                }
            }

            setFarmsFiltered(farmsFiltered);
        } else {
            for (let farm of farms) {
                if (farm.clientId === clientId) {
                    farmsFiltered.push(farm)
                }
            }
            setFarmsFiltered(farmsFiltered);
        }
    }

    const SpinnerTag = (props) => (
        <img
            style={{
                width: props.size + "px",
                height: props.size + "px",
                marginRight: "0px",
            }}
            src={spinner}
        />
    )

    const filterFields = (farmId) => {
        let fieldsFiltered = []
        if (parseInt(farmId) === -1) {

            let farmsId = []
            for (let farm of farmsFiltered) {
                farmsId.push(parseInt(farm.farmId))
            }

            for (let field of fields) {
                if (farmsId.includes(field.farmId)) {
                    fieldsFiltered.push(field)
                }
            }

            setFieldsFiltered(fieldsFiltered);
        } else {
            for (let field of fields) {
                if (field.farmId === parseInt(farmId)) {
                    fieldsFiltered.push(field)
                }
            }
            setFieldsFiltered(fieldsFiltered);
        }
    }

    const filterStatus = (fieldId) => {
        let statusFiltered = []
        if (parseInt(fieldId) === -1) {


            let fieldsId = []
            for (let field of fieldsFiltered) {
                fieldsId.push(parseInt(field.fieldId))
            }

            let statusTemp = {}
            for (let uploader of uploadersContext) {
                if (fieldsId.includes(uploader.imagesSet.lot_id)) {
                    statusTemp[uploader.status] = { status: uploader.status }
                }
            }
            for (let statusRow of Object.keys(statusTemp)) {
                statusFiltered.push(statusTemp[statusRow])
            }

            setStatusFiltered(statusFiltered);
        } else {

            let statusTemp = {}
            for (let uploader of uploadersContext) {
                if (parseInt(fieldId) === uploader.imagesSet.lot_id) {
                    statusTemp[uploader.status] = { status: uploader.status }
                }
            }
            for (let statusRow of Object.keys(statusTemp)) {
                statusFiltered.push(statusTemp[statusRow])
            }

            setStatusFiltered(statusFiltered);
        }
    }

    const updateImagesSetHeader = async (id, arg) => {

        setUploadersContext((uploaders) => {
            for (let uploader of uploaders) {
                if (uploader.imagesSet.image_set_id === id) {
                    uploader.imagesSet.name = arg.name;
                    uploader.imagesSet.lot_id = arg.lotId;
                    uploader.imagesSet.lot_name = arg.lotName;
                    uploader.imagesSet.farm_id = arg.farmId;
                    uploader.imagesSet.farm_name = arg.farmName;
                    uploader.imagesSet.sampling_id = arg.samplingId
                    uploader.imagesSet.client = arg.clientName
                }
            }
            return uploaders;
        })

    }

    const getAllImagesSets = async () => {
        bus.emit('syncImagesSets', true)
        ApiFetch('api/images_sets/' + campanaSeleccionada,
            true,
            async (res) => {

                let ret = await res.json()
                //let uploadersTemp = [...uploadersContext];            
                let uploadersTemp = [];
                for (let obj of ret) {

                    let imagesLength = 0;
                    let imagesUploadLength = 0;
                    let imagesErrorLength = 0;
                    let imagesPersisted = [];
                    for (let imageObj of obj.Images) {
                        if (imageObj.is_uploaded === 1) {
                            imagesUploadLength++;
                            imagesPersisted.push(imageObj)
                        } else {
                            imagesErrorLength++;
                        }
                        imagesLength++;
                    }
                    obj.ImagesPersisted = imagesPersisted;


                    uploadersTemp.push(
                        {
                            imagesSet: obj,
                            id: obj.image_set_id,
                            visible: false,
                            //createReactObject: Date.now(),
                            mode: 'EDIT',
                            status: 'UPLOADED',
                            statusCountPersisted: {
                                imagesLength: imagesUploadLength,
                                imagesUploadingLength: 0,
                                imagesUploadLength: imagesUploadLength,
                                imagesErrorLength: imagesErrorLength
                            },
                            statusCount: {
                                imagesLength: 0,
                                imagesUploadingLength: 0,
                                imagesUploadLength: 0,
                                imagesErrorLength: 0
                            }
                        }
                    );

                }
                setUploadersContext(uploadersTemp);
                // lo actualizo a parte, para actualizar los spinners. Caso contrario arranca mostrando cero.
                setSincronizedUploadersContext(true);
                console.log(ret);
                bus.emit('syncImagesSets', false)
                bus.emit('refreshReports', true)

            }, (ret) => {
                console.log(ret);
                bus.emit('syncImagesSets', false)
            })
    }

    const newImageSet = () => {
        setListVisible(false);
        ApiFetch('api/init_images_set/' + campanaSeleccionada, true,
            async (ret) => {
                let obj = await ret.json()
                console.log(ret);

                let uploadersTemp = [...uploadersContext];
                let uploaderTemp = {
                    imagesSet: obj,
                    id: obj.image_set_id,
                    visible: true,
                    mode: 'NEW',
                    status: 'EMPTY',
                    statusCount: {
                        imagesLength: 0,
                        imagesUploadingLength: 0,
                        imagesUploadLength: 0,
                        imagesErrorLength: 0
                    },
                    statusCountPersisted: {
                        imagesLength: 0,
                        imagesUploadingLength: 0,
                        imagesUploadLength: 0,
                        imagesErrorLength: 0
                    }
                }
                uploadersTemp.push(
                    uploaderTemp
                );
                setUploadersContext(uploadersTemp);
                setNewVisible(true);

            }, (ret) => {
                console.log(ret);
            })
    }

    const newImageSetWithHeader = (reportsTypes, sampling_id, lot_id, lot_name, farm_id) => {

        setListVisible(false);
        ApiFetch('api/init_images_set_with_header/' + campanaSeleccionada, true,
            async (ret) => {
                let obj = await ret.json()
                let uploadersTemp = [...uploadersContext];
                let uploaderTemp = {
                    imagesSet: obj,
                    id: obj.image_set_id,
                    visible: true,
                    mode: 'EDIT',
                    status: 'EMPTY',
                    statusCount: {
                        imagesLength: 0,
                        imagesUploadingLength: 0,
                        imagesUploadLength: 0,
                        imagesErrorLength: 0
                    },
                    statusCountPersisted: {
                        imagesLength: 0,
                        imagesUploadingLength: 0,
                        imagesUploadLength: 0,
                        imagesErrorLength: 0
                    }
                }
                uploadersTemp.push(
                    uploaderTemp
                );
                setUploadersContext(uploadersTemp);
                setNewVisible(true);
            }
            ,
            (ret) => {
                console.log(ret);
            }
            ,
            {
                method: "POST",
                body: JSON.stringify({ reportsTypes: reportsTypes, samplingId: sampling_id }),
            })
    }

    const onStatusChange = (id, status, statusCount) => {
        let uploadersTemp = [...uploadersContext];
        let totalImagesLength = 0;
        let totalImagesUploadedLength = 0;
        let totalImagesUploadingLength = 0;
        console.log("uploaderTemp.status", "init")
        for (let uploaderTemp of uploadersTemp) {
            if (uploaderTemp.id === id) {
                uploaderTemp.status = status
                uploaderTemp.statusCount = statusCount
            }
            // todo: ajustar y centralizar todo en el imagelist.
            if (uploaderTemp.status === 'UPLOADING' || uploaderTemp.status === 'PAUSED') {
                totalImagesLength += uploaderTemp.statusCount.imagesLength;
                totalImagesUploadedLength += uploaderTemp.statusCount.imagesUploadLength;
                totalImagesUploadingLength += uploaderTemp.statusCount.imagesUploadingLength + uploaderTemp.statusCount.imagesErrorLength
            }
        }
        setGlobalUploadCount({ total: totalImagesLength, uploaded: totalImagesUploadedLength, uploading: totalImagesUploadingLength })
        setUploadersContext(uploadersTemp);
    }

    const onModeChange = (id, mode) => {
        let uploadersTemp = [...uploadersContext];
        for (let uploaderTemp of uploadersTemp) {
            if (uploaderTemp.id === id) {
                uploaderTemp.mode = mode
            }
        }
        setUploadersContext(uploadersTemp);
    }

    const onUploaderHide = () => {
        setNewVisible(false);
        setListVisible(true);
        let uploadersTemp = [...uploadersContext];
        for (let uploaderTemp of uploadersTemp) {
            uploaderTemp.visible = false;
        }
        setUploadersContext(uploadersTemp);
    }

    return (
        <div style={{ display: props.visible ? '' : 'none' }}>
            <div style={{ display: listVisible ? '' : 'none' }}>
                {/** CUSTOM HEAD*/}
                <head>
                    <link rel="stylesheet" href="static/css/components/imagelist.css" />
                </head>
                {/** CUSTOM HEAD*/}

                <div className="container">
                    <ul className="nav nav-tabs mb-2 mt-2" role="tablist">
                        <li className="nav-item">
                            <a
                                style={{
                                    color: "#4e5153",
                                    textAlign: "left",
                                    fontWeight: "700",
                                    paddingLeft: "0px !important"
                                }}
                                ref={(el) => {
                                    if (el) {
                                        el.style.setProperty('padding-left', '0px', 'important');
                                    }
                                }}
                                className="nav-link"
                            >
                                {" "}
                                CARGA DE IMÁGENES{" "}
                            </a>
                        </li>
                    </ul>
                    <div
                        style={{ paddingLeft: "0px !important", marginTop: "10px" }}
                        ref={(el) => {
                            if (el) {
                                el.style.setProperty('padding-left', '0px', 'important');
                            }
                        }}
                        align="left"
                        className="row"
                    >
                        <div style={{ paddingLeft: "5px !important" }}
                            ref={(el) => {
                                if (el) {
                                    el.style.setProperty('padding-left', '5px', 'important');
                                }
                            }}
                            className="col-md-12">
                            <div
                                style={{ marginTop: "-3px !important" }}
                                className="mt-1 pl-2 mb-1"
                            >
                                <strong>
                                    <i
                                        style={{ color: "#1c1f21 !important" }}
                                        ref={(el) => {
                                            if (el) {
                                                el.style.setProperty('color', '#1c1f21', 'important');
                                            }
                                        }}
                                        className="fa fa-info-circle mr-1"
                                    />{" "}
                                    Importante:{" "}
                                </strong>{" "}
                                para optimizar el funcionamiento de la plataforma alojaremos tus sets
                                de imágenes durante 60 días desde la fecha de subida. <br /> Te
                                recomendados iniciar la carga de imágenes con buena conexión. Al
                                completar la subida podrás solicitar los reportes.{" "}
                                {false &&
                                    <strong>
                                        Te notificaremos por mail cuando el proceso finalice.{" "}
                                    </strong>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-8 mb-4 mb-md-0">
                            <a>
                                {esCampanaActiva() &&
                                    <button
                                        type="button"
                                        className="btn floating-buttons-y align-items-center line-height-1 py-2 mb-w mt-1 mr-3"
                                        onClick={() => {
                                            newImageSet();
                                        }}
                                    >
                                        {" "}
                                        <i className="fas fa-plus-circle mr-1" /> Nuevo set de imágenes{" "}
                                    </button>
                                }
                            </a>
                        </div>
                        {uploadersContext.length > 0 && false &&
                            <div align="right" className="col-md-4 mt-3 pr-0 mb-2 mb-md-0">
                                <a
                                    href="#"
                                    style={{ cursor: "text" }}
                                    className="btn btn-success align-items-center line-height-1 mr-1 py-1 mb-1 mt-0 "
                                    title="Detalle"
                                >
                                    <i
                                        style={{ marginRight: "10px", fontWeight: "600" }}
                                        className="lnr lnr-chart-bars ml-1 mr-1"
                                    />
                                </a>{" "}
                                Set con Reportes procesados
                            </div>
                        }
                    </div>
                </div>
                {uploadersContext.length > 0 &&
                    <section
                        className="mb-10 pt-4 pb-5"
                        style={{ backgroundColor: "white", padding: "0px 0" }}
                    >
                        <div style={{ minHeight: "400px" }} className="container">
                            <div className="row mt-2">
                                <div className="col-md-12 mb-4 mb-md-0">
                                    <div
                                        style={{ paddingLeft: "0px !important" }}
                                        ref={(el) => {
                                            if (el) {
                                                el.style.setProperty('paddingLeft', '0px', 'important');
                                            }
                                        }}
                                        className="d-flex flex-nowrap px-2 text-center h-fit align-content-center align-items-center"
                                    >
                                        <h3
                                            style={{ marginRight: "45%", textAlign: "left" }}
                                            className="font-weight-bold text-4"
                                        >
                                            Tus sets de imágenes: <br />
                                        </h3>
                                    </div>
                                    <div
                                        style={{ paddingLeft: "0px !important" }}
                                        ref={(el) => {
                                            if (el) {
                                                el.style.setProperty('paddingLeft', '0px', 'important');
                                            }
                                        }}
                                        align="left"
                                        className="row"
                                    >
                                        <div
                                            style={{ paddingLeft: "5px !important" }}
                                            ref={(el) => {
                                                if (el) {
                                                    el.style.setProperty('paddingLeft', '5px', 'important');
                                                }
                                            }}
                                            className="col-md-12"
                                        >
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                style={{ borderBottom: "0px !important" }}
                                                                ref={(el) => {
                                                                    if (el) {
                                                                        el.style.setProperty('border-bottom', '0px', 'important');
                                                                    }
                                                                }}
                                                            >
                                                                Clientes <select
                                                                    className="form-control my-select altura"
                                                                    name="eje"
                                                                    id="eje"
                                                                    value={filterSelectedClient}
                                                                    onChange={handleFilterSelectedClient}
                                                                >
                                                                    <option value={-1}>Todos</option>
                                                                    {clientsFiltered.map((client) => (
                                                                        <option value={client.clientId}>{client.clientName}</option>
                                                                    ))}
                                                                </select>
                                                            </th>
                                                            <th
                                                                style={{ borderBottom: "0px !important" }}
                                                                ref={(el) => {
                                                                    if (el) {
                                                                        el.style.setProperty('border-bottom', '0px', 'important');
                                                                    }
                                                                }}
                                                            >
                                                                Campos
                                                                <select
                                                                    className="form-control my-select altura"
                                                                    name="Campo"
                                                                    id="campo"
                                                                    value={filterSelectedFarm}
                                                                    onChange={handleFilterSelectedFarm}
                                                                >
                                                                    <option value={-1}>Todos</option>
                                                                    {farmsFiltered.map((farm) => (
                                                                        <option value={farm.farmId}>{farm.farmName}</option>
                                                                    ))}
                                                                </select>
                                                            </th>
                                                            <th
                                                                style={{ borderBottom: "0px !important" }}
                                                                ref={(el) => {
                                                                    if (el) {
                                                                        el.style.setProperty('border-bottom', '0px', 'important');
                                                                    }
                                                                }}
                                                            >
                                                                Lotes<select
                                                                    className="form-control my-select altura"
                                                                    name="prioridad"
                                                                    id="prioridad"
                                                                    value={filterSelectedField}
                                                                    onChange={handleFilterSelectedField}
                                                                >
                                                                    <option value={-1}>Todos</option>
                                                                    {fieldsFiltered.map((field) => (
                                                                        <option value={field.fieldId}>{field.fieldName}</option>
                                                                    ))}
                                                                </select>
                                                            </th>
                                                            <th
                                                                style={{ borderBottom: "0px !important" }}
                                                                ref={(el) => {
                                                                    if (el) {
                                                                        el.style.setProperty('border-bottom', '0px', 'important');
                                                                    }
                                                                }}
                                                            >
                                                                Estados<select
                                                                    className="form-control my-select altura"
                                                                    name="estado"
                                                                    id="Estado"
                                                                    value={filterSelectedStatus}
                                                                    onChange={handleFilterSelectedStatus}
                                                                >
                                                                    <option value={-1}>Todos</option>
                                                                    {statusFiltered.map((statusRow) => (
                                                                        <option value={statusRow.status}>{statusLabel[statusRow.status]}</option>
                                                                    ))}
                                                                </select>
                                                            </th>
                                                            <th
                                                                style={{ borderBottom: "0px !important", display: "none" }}
                                                                ref={(el) => {
                                                                    if (el) {
                                                                        el.style.setProperty('border-bottom', '0px', 'important');
                                                                    }
                                                                }}
                                                            >
                                                                <input
                                                                    placeholder="Buscar por cliente"
                                                                    onkeypress="javascript: if(event.keyCode == 13) buscar();"
                                                                    type="text"
                                                                    name="texto"
                                                                    className="form-control"
                                                                    style={{
                                                                        width: "100%",
                                                                        maxWidth: "250px",
                                                                        display: "inline-block",
                                                                        background: "rgba(0, 0, 0, 0.1)",
                                                                        opacity: "1",
                                                                        height: "auto !important",
                                                                        fontSize: "13px",
                                                                        borderRadius: "0.25rem",
                                                                        padding: "10px"
                                                                    }}
                                                                    ref={(el) => {
                                                                        if (el) {
                                                                            el.style.setProperty('height', 'auto', 'important');
                                                                        }
                                                                    }}
                                                                />
                                                            </th>
                                                            <th
                                                                style={{ maxWidth: "80px", borderBottom: "0px !important" }}
                                                                ref={(el) => {
                                                                    if (el) {
                                                                        el.style.setProperty('border-bottom', '0px', 'important');
                                                                    }
                                                                }}
                                                            >
                                                                {/*<a
                                        href="javascript:void(0);"
                                        onclick="buscar();"
                                        style={{ marginTop: "-1px" }}
                                        className="btn btn-dark btn-h-1 btn-v-3b font-weight-semibold text-0 fx animated wow fadeInUp"
                                        >
                                        BUSCAR
                                        </a>*/}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                        <p />
                                    </div>
                                    <div className="shop-cart mt-0">
                                        <div className="table-responsive">
                                            <table className="shop-cart-table w-100">
                                                <thead>
                                                    <tr>
                                                        <th className="product">
                                                            <strong>Nombre Set / Cliente</strong>
                                                        </th>
                                                        <th className="product"> Campo </th>
                                                        <th style={{ minWidth: "80px" }} className="product">
                                                            {" "}
                                                            Lote{" "}
                                                        </th>
                                                        <th className="product">
                                                            <strong>
                                                                Cantidad de <br />
                                                                imágenes totales{" "}
                                                                <i className="fa fa-sort" aria-hidden="true" />
                                                            </strong>
                                                        </th>
                                                        <th style={{ minWidth: "80px" }} className="product">
                                                            <strong>
                                                                Imágenes <br />
                                                                subidas{" "}
                                                            </strong>
                                                        </th>
                                                        <th style={{ minWidth: "80px" }} className="product">
                                                            <strong>
                                                                Imágenes <br />
                                                                rechazadas{" "}
                                                            </strong>
                                                        </th>
                                                        <th className="product">
                                                            <strong>Estado</strong>
                                                        </th>
                                                        <th style={{ maxWidth: "50px" }} className="product">
                                                            <strong />
                                                        </th>
                                                        <th style={{ maxWidth: "50px" }} className="product">
                                                            <strong />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {uploadersContext.map((uploader) => {
                                                        if (uploader.status === filterSelectedStatus || parseInt(filterSelectedStatus) === -1) {
                                                            if (parseInt(uploader.imagesSet.lot_id) === parseInt(filterSelectedField) || parseInt(filterSelectedField) === -1) {
                                                                if (parseInt(uploader.imagesSet.farm_id) === parseInt(filterSelectedFarm) || parseInt(filterSelectedFarm) === -1) {
                                                                    if (uploader.imagesSet.client === filterSelectedClient || parseInt(filterSelectedClient) === -1) {
                                                                        if (esCampanaActiva() || uploader.status === 'REPORT_GENERATED') {
                                                                            return (
                                                                                <tr className="cart-item" style={{ cursor: "default" }}>
                                                                                    <td className="product">
                                                                                        <a>
                                                                                            <strong>{uploader?.imagesSet?.name}</strong>
                                                                                            <br />
                                                                                            <button
                                                                                                style={{
                                                                                                    backgroundColor: "#e1e1e1",
                                                                                                    borderColor: "#e1e1e1",
                                                                                                    color: "black",
                                                                                                    fontWeight: "600",
                                                                                                    cursor: "text"
                                                                                                }}
                                                                                                type="button"
                                                                                                className="btn btn-primary align-items-center line-height-1 py-1 mb-0 mt-2 mr-1"
                                                                                            >
                                                                                                {uploader.imagesSet.client}
                                                                                            </button>
                                                                                        </a>
                                                                                    </td>
                                                                                    <td className="product">
                                                                                        <a>{uploader.imagesSet.farm_name}</a>
                                                                                    </td>
                                                                                    <td className="product">
                                                                                        <a>{uploader.imagesSet.lot_name}</a>
                                                                                    </td>
                                                                                    <td className="product">
                                                                                        <a>{uploader.statusCount?.imagesLength + uploader.statusCountPersisted?.imagesLength}</a>
                                                                                    </td>
                                                                                    <td className="product">
                                                                                        <a>{uploader.statusCount?.imagesUploadLength + uploader.statusCountPersisted?.imagesUploadLength}</a>
                                                                                    </td>
                                                                                    <td className="product">
                                                                                        <a>{uploader.statusCount?.imagesErrorLength + uploader.statusCountPersisted?.imagesErrorLength}</a>
                                                                                    </td>
                                                                                    <td className="product">
                                                                                        {
                                                                                            //uploader.status
                                                                                        }
                                                                                        <button
                                                                                            type="button"
                                                                                            style={{ cursor: "text" }}
                                                                                            className={"btn " + statusClass[uploader.status] + " estado-carga font-weight-bold btn-2 btn-fs-2  text-2 mb-1"}
                                                                                        >
                                                                                            {" "}
                                                                                            {uploader.status === 'UPLOADING' &&
                                                                                                <img style={{ width: "18px", height: "18px", marginRight: "10px" }} src={spinner} />
                                                                                            }
                                                                                            {uploader.status !== 'UPLOADING' &&
                                                                                                <i
                                                                                                    style={{ marginRight: "10px" }}
                                                                                                    className={"fa fa-icon " + statusIcon[uploader.status] + "  ml-1 mr-1"}
                                                                                                />
                                                                                            }
                                                                                            {statusLabel[uploader.status]}

                                                                                        </button>

                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            whiteSpace: "nowrap",
                                                                                            verticalAlign: "middle",
                                                                                            textAlign: "center"
                                                                                        }}
                                                                                        className="d-none pl-0 pr-0 product d-md-table-cell"
                                                                                    >
                                                                                        <a style={{ verticalAlign: "top" }}></a>
                                                                                        <a
                                                                                            onClick={() => {
                                                                                                uploader.visible = true;
                                                                                                uploader.mode = 'EDIT';
                                                                                                setNewVisible(true);
                                                                                                setListVisible(false);
                                                                                            }}
                                                                                            className="btn btn-info align-items-center line-height-1 mr-2 py-2 mb-1 mt-1 "
                                                                                            title="Detalle"
                                                                                        >
                                                                                            <i
                                                                                                style={{ marginRight: "10px" }}
                                                                                                className={"fa " + (['EMPTY', 'READY', 'PAUSED'].includes(uploader.status) ? "fa-edit" : "fa-eye") + " ml-1 mr-1"}
                                                                                            />
                                                                                        </a>
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            whiteSpace: "nowrap",
                                                                                            verticalAlign: "middle",
                                                                                            textAlign: "center"
                                                                                        }}
                                                                                        className="d-none  pl-0 pr-0 product d-md-table-cell"
                                                                                    >

                                                                                        {['EMPTY', 'READY', 'PAUSED'].includes(uploader.status) &&
                                                                                            <a
                                                                                                data-toggle="modal"
                                                                                                data-target="#eliminarModal"
                                                                                                href="#"
                                                                                                className="btn btn-danger align-items-center line-height-1 py-2 mb-1 mt-1 "
                                                                                                title="Eliminar"
                                                                                                onClick={() => {
                                                                                                    handleClickOpenDeleteDialog(uploader);
                                                                                                }}
                                                                                            >
                                                                                                <i
                                                                                                    style={{ marginRight: "10px" }}
                                                                                                    className="fa fa-trash ml-1 mr-1"
                                                                                                />
                                                                                            </a>
                                                                                        }
                                                                                    </td>
                                                                                </tr>

                                                                            )
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {uploadersContext.length === 0 &&
                    <div style={{ minHeight: "250px" }}></div>
                }

            </div>
            {
                (newVisible === false && listVisible === false)
                &&
                <div className="container">
                    <div style={{ minHeight: "250px", width: "100%", marginTop: "50px" }}>
                        <p
                            style={{
                                textAlign: "center",
                                top: "50%",
                                transform: "translateY(-50%)",
                                zIndex: 0,
                                display: "",
                            }}
                        >
                            <SpinnerTag size="40" />
                        </p>
                    </div>
                </div>

            }
            <div style={{ display: newVisible ? '' : 'none' }}>
                {uploadersContext.map((uploader) => (
                    <>
                        <ImageUpload
                            visible={uploader.visible}
                            mode={uploader.mode}
                            imagesSet={uploader.imagesSet}
                            status={uploader.status}
                            statusCountPersisted={uploader.statusCountPersisted}
                            onStatusChange={onStatusChange}
                            imagesPersisted={uploader.imagesSet?.ImagesPersisted}
                            onImagesSetHeaderChange={(id, args) => { updateImagesSetHeader(id, args) }}
                            onHide={onUploaderHide} />
                    </>
                ))}
            </div>


            <Dialog
                open={openDeleteDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDeleteDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"¿Estás seguro?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Se perderán los atributos y las imágenes cargadas para este set de imágenes
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
                    <Button onClick={
                        () => {
                            deleteImagesSetSelected();
                        }
                    }>Aceptar</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default ImageList;  