// components/simple-dropzone.component.js
import React, { useEffect, useState } from "react";
import DropzoneR from "./DropzoneR";
import exifr from "exifr";
import { ApiFetch, SyncApiFetch } from "../../utils/ApiFetch";

const Uploader = (props) => {
  const [imagesC, setImagesC] = useState({});
  const [exteriorErrorFiles, setExteriorErrorFiles] = useState([]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleTabClosing);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleTabClosing);
    };
  });

  useEffect(() => {
    if (props.updateImagesHandler) {
      props.updateImagesHandler(imagesC);
    }
    if (props.dropZoneRWRef) {
      props.dropZoneRWRef({
        uploading: initUpload,
        reinitUpload: reinitUpload,
      });
    }
  }, [JSON.stringify(imagesC)]);

  const initUpload = async () => {
    for (let id of Object.keys(imagesC)) {
      let handler = imagesC[id]?.handler?.handlers;
      if (handler === "undefined") continue;
      if (imagesC[id].status != "removed") {
        handler.cancel();
        handler.restart();
      }
    }
  };

  const reinitUpload = async () => {
    for (let id of Object.keys(imagesC)) {
      let handler = imagesC[id].handler.handlers;
      if (imagesC[id].status === "exception_upload" || imagesC[id].status === "error_upload") {
        handler.cancel();
        handler.restart();
      }
    }
  };

  const removeImage = (idArg) => {
    for (let id of Object.keys(imagesC)) {
      if (id === idArg) {
        imagesC[id].handler.handlers.remove();
      }
    }
  };

  const handleTabClosing = () => {
    //removePlayerFromGame();
  };

  const alertUser = (event) => {
    //setViewModal(true);
    event.preventDefault();
    event.returnValue = "";
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleChangeStatusN = async (handlers, status, meta, file, survey) => {
    let persistedImage = meta.persistedImage;
    let latlon = await exifr.gps(file);

    if (status === "uploading") {
      /*let args = {
        image_set_id: props.imagesSet.image_set_id,
        image_size_bytes: meta.size,
        actual_name: meta.name,
        s3_name: meta.s3FileName,
        image_location: latlon,
      };

      let imageInitializedApi = false;
      try {
        persistedImage = await SyncApiFetch("api/init_image", true, {
          method: "POST",
          body: JSON.stringify(args),
        });
        imageInitializedApi = true;
      } catch (e) {
        imageInitializedApi = false;
        handlers.cancel(); // todo: implementar mensajeria
      }*/

      setExteriorErrorFiles((files) => {
        let filesTemp = [];
        for (let file of files) {
          if (file != meta.id) {
            filesTemp.push(file);
          }
        }
        return filesTemp;
      });
    }

    let confirmedImage = null;
    if (status === "done") {
      try {
        confirmedImage = await SyncApiFetch(
          "api/done_upload_image/" + imagesC[meta.id].persistedImage.image_id,
          true,
          {
            method: "GET",
          }
        );
        if (confirmedImage.status === 0) {
          throw "error";
        }
      } catch (e) {
        confirmedImage = null;
      }
    }
    if (status === "exception_upload") {
      await delay(2000);
      // todo: sacar todos los llamados asincronicos de esta funcion
    }

    if (status === "ready") {
      for (let imageTemp of Object.keys(imagesC)) {
        let name = imagesC[imageTemp].handler?.meta?.name;
        let statusTemp = imagesC[imageTemp]?.status;
        if (statusTemp === "removed") {
          continue;
        }
        if (name === meta.name) {
          console.log("elimino");
          handlers.remove();
        }
      }
      if (latlon === undefined) {
        // si no tiene imagen con latlon tambien la elimina
        handlers.remove();
      }
    }

    setImagesC((imagesH) => {
      let imagesC = { ...imagesH };
      if (imagesC[meta.id] === undefined) {
        imagesC[meta.id] = {};
      }

      imagesC[meta.id].status = status;
      if (status === "uploading") {
        if (imagesC[meta.id].persistedImage == null) {
          imagesC[meta.id].persistedImage = persistedImage;
        }
      }
      if (status === "done") {
        if (confirmedImage === null) {
          imagesC[meta.id].status = "exception_upload";
          setExteriorErrorFiles((files) => {
            files.push(meta.id); // lo agrego a esta coleccion para que el preview del dropzone pueda darle tratamiento visual
            return files;
          });
        }
        /*if (imagesC[meta.id]) {
          ApiFetch(
            "api/done_upload_image/" + imagesC[meta.id].persistedImage.image_id,
            true,
            async (ret) => {
              // todo: implementar
              
            },
            (ret) => {
              console.log(ret);
              // todo: implementar
            }
          );
        }*/
      }
      if (status === "ready") {
        imagesC[meta.id].handler = {
          ...imagesC[meta.id],
          meta,
          file,
          handlers,
        };
        if (file && file.name) {
          imagesC[meta.id] = {
            ...imagesC[meta.id],
            latlon: latlon,
            id: meta.id,
            file: file,
            persistedImage: null,
            remove: () => {
              removeImage(meta.id);
            },
          };
        }
      }
      return imagesC;
    });
  };

  return (
    <>
      <DropzoneR
        survey={props.imagesSet.image_set_id}
        showInput={props.showInput}
        exteriorErrorFiles={exteriorErrorFiles}
        handleChangeStatus={handleChangeStatusN}
        imagesPersisted={props.imagesPersisted}
      />
    </>
  );
};

export default Uploader;
