import React, { createContext, useState, useEffect } from "react";
import {
  ApiFetchWithoutSession,
  SyncApiFetchWithoutSession,
  SyncApiFetch,
} from "./ApiFetch";
import { useBus, useListener } from "react-bus";
const { REACT_APP_API_URL, REACT_APP_SSO } = process.env;

// Creamos el contexto
const ImagesContext = createContext();

// Creamos el componente proveedor
function ImagesContextProvider(props) {
  const bus = useBus();
  const [globalUploadCount, setGlobalUploadCount] = React.useState({
    total: 0,
    uploaded: 0,
    uploading: 0,
  });


  const [fields, setFields] = React.useState([]);
  const [fieldsErrors, setFieldsErrors] = React.useState([])
  const [globalCancelUploaderFlag, setGlobalCancelUploaderFlag] = React.useState(false);
  const [globalInitUploadFlag, setGlobalInitUploadFlag] = React.useState(false);
  const [lastSyncSeguimientoNidera, setLastSyncSeguimientoNidera] =
    React.useState(null);
  const [uploadersContext, setUploadersContext] = React.useState([]);
  const [sincronizedUploadersContext, setSincronizedUploadersContext] =
    React.useState(false);
  const [onChangeMenuSuscriptor, setOnChangeMenuSuscriptor] = React.useState(
    []
  );
  const [
    onChangeUploaderSelectedSuscriptor,
    setOnChangeUploaderSelectedSuscriptor,
  ] = React.useState([]);

  // campañas activas. Se declaran en el front, porque es donde inicia los llamados tempranos.
  const [campanas, setCampanas] = useState([
    { anio: 2023, label: '23-24', activa: false }, { anio: 2024, label: '24-25', activa: true }
  ])
  const [campanaSeleccionada, setCampanaSeleccionada] = useState(2024)
  // inicialización Global
  useEffect(() => {
    // todo: implementar reintentos
    // initFields();
  }, []);

  useEffect(() => {
    // todo: implementar reintentos
    // initFields();
  }, [campanaSeleccionada]);

  const esCampanaActiva = () => {
    let ret = false;
    for (let campana of campanas) {
      if (campana.anio === parseInt(campanaSeleccionada)) {
        if (campana.activa) {
          ret = true;
        }
      }
    }
    return ret;
  }


  const initFields = async () => {
    bus.emit("syncFields", true);
    try {
      let ret = await SyncApiFetch("api/fields/" + campanaSeleccionada, true, {
        method: "GET",
      });
      bus.emit("syncFields", false);
      //bus.emit("reloadMap",true); 
      setFieldsErrors(ret.lotsErrors)
      return setFields(ret.rows);
    } catch (e) {
      //todo: implementar
      console.log(e);
      if (e?.status == 401) {
        localStorage.removeItem("nideravuela2_jwt_token");
        window.location.replace(REACT_APP_SSO);
        return false;
      } else {
        bus.emit("syncFields", false);
        return false;
      }
    }
  };

  return (
    <ImagesContext.Provider
      value={{
        globalUploadCount,
        setGlobalUploadCount,
        fields,
        fieldsErrors,
        uploadersContext,
        lastSyncSeguimientoNidera,
        setLastSyncSeguimientoNidera,
        setUploadersContext,
        sincronizedUploadersContext,
        setSincronizedUploadersContext,
        initFields,
        globalCancelUploaderFlag,
        setGlobalCancelUploaderFlag,
        setGlobalInitUploadFlag,
        globalInitUploadFlag,
        esCampanaActiva,
        campanaSeleccionada, campanas, setCampanas, setCampanaSeleccionada
      }}
    >
      {props.children}
    </ImagesContext.Provider>
  );
}

export { ImagesContext, ImagesContextProvider };
