const statusClass = {
  EMPTY: "btn-light",
  READY: "btn-info",
  UPLOADING: "btn-warning",
  UPLOADED: "btn-success",
  PAUSED: "btn-danger",
  REPORT_GENERATED: "btn-info",
  PROCESSING: "btn-warning",
  WITHOUT_IMAGESSET: "btn-light",
  PROCESSED_WITHOUT_IMAGESSET: "btn-light",
};
const statusLabel = {
  EMPTY: "Borrador",
  READY: "Listo para subir",
  UPLOADING: "Cargando",
  UPLOADED: "Carga finalizada",
  PAUSED: "Pausada",
  WITHOUT_IMAGESSET: "Sin imágenes asociadas",
  PROCESSED_WITHOUT_IMAGESSET: "Sin imágenes asociadas",
  REPORT_GENERATED: "Reporte Generado",
  PROCESSING: "Generando Reporte",
};

const statusIconLabel = {
  WITHOUT_IMAGESSET: {
    color: "#dc3545",
    icon: "lnr-upload",
    labelAction: "Cargar sets de imágenes",
  },
  PROCESSED_WITHOUT_IMAGESSET: {
    color: "#dc3545",
    icon: "lnr-upload",
    labelAction: "Cargar sets de imágenes",
  },
  EMPTY: { color: "gray", icon: "lnr-upload", labelAction: "Cargar Imágenes" },
  READY: {
    color: "lightblue",
    icon: "lnr-upload",
    labelAction: "Iniciar carga de imágenes",
  },
  UPLOADING: {
    color: "#e08c0a",
    icon: "lnr-history",
    labelAction: "Ver estado de carga",
  },
  PAUSED: {
    color: "yellow",
    icon: "lnr-history",
    labelAction: "Restaurar el proceso de carga",
  },
  UPLOADED: {
    color: "#2258af",
    icon: "lnr-chart-bars",
    labelAction: "Ver reportes asociados",
  },
  PROCESSING: {
    color: "green",
    icon: "lnr-chart-bars",
    labelAction: "Ver reportes asociados",
  },
  REPORT_GENERATED: {
    color: "violet",
    icon: "lnr-chart-bars",
    labelAction: "Ver reportes asociados",
  },
};

const statusIcon = {
  WITHOUT_IMAGESSET: "fa-0",
  PROCESSED_WITHOUT_IMAGESSET: "fa-0",
  EMPTY: "fa-0",
  READY: "fa-play",
  UPLOADING: "fa-spinner",
  UPLOADED: "fa-check",
  PAUSED: "fa-pause",
  PROCESSING: "fa-play",
  REPORT_GENERATED: "fa-check",
};

const imageStatusIcon = {
  uploading: "fa-spinner",
  done: "fa-check",
  exception_upload: "fa-pause",
  ready: "fa-play",
  saved: "fa-save",
  getting_upload_params: "fa-spinner",
  preparing: "fa-spinner",
};

const imageStatusLabel = {
  uploading: "Cargando ...",
  done: "Carga finalizada",
  exception_upload: "Error de Transmisión",
  saved: "Guardada",
  ready: "Listo para subir",
  getting_upload_params: "Iniciando Comunicación",
  preparing: "Analizando Imagen",
};

const reportStatusLabel = {
  'OK': "Procesado",
  'NOT_PROCESSED': "Sin Procesar",
  'ERROR': "Error",
  'PROCESSING': "En Proceso ",  
};

const imageStatusClass = {
  saved: "btn-success",
  ready: "btn-info",
  uploading: "btn-warning",
  done: "btn-success",
  exception_upload: "btn-danger",
  getting_upload_params: "btn-info",
  preparing: "btn-info",
};

export {
  statusClass,
  statusLabel,
  statusIcon,
  imageStatusIcon,
  imageStatusLabel,
  imageStatusClass,
  statusIconLabel,
  reportStatusLabel
};
