import React, { useEffect, useState, useRef } from "react";
import * as L from "leaflet";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Select,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import { imageMetadataFields } from "./config";
const { REACT_APP_API_URL_GTM } = process.env;

const MyLeaflet = (props) => {
  var map = useRef(null);
  var unitFactor = 1000;
  var imageLayer = null;
  var bounds_plants_layer = useRef(null);
  var lines_layer = null;
  var centroid_layer = null;
  const [images, setImages] = useState([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageNumber, setSelectedImageNumber] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageMetadata, setImageMetadata] = useState(null);

  
  // INICIALIZACION
  //================================================================================
  useEffect(() => {
    setTimeout(() => {
      getImages();
    }, 500);
  }, []);

  let getImages = async () => {
    
    fetch(REACT_APP_API_URL_GTM + "rasters/getAll/"+props.imagesSetId, { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setImages(data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);
      setSelectedImageNumber(0);
    }
  }, [images]);

  // CAMBIO DE IMAGEN
  //================================================================================

  useEffect(() => {
    if (selectedImage !== null) {
      if (selectedImage.status==='OK'){
        fetch(REACT_APP_API_URL_GTM + "rasters/getImage/"+props.imagesSetId+'/'+selectedImage?.aws_name, { method: "GET" })
        .then((response) => {
            return response.text()
              
        })
        .then((data) => {
          setSelectedImageUrl(data)
            
        }).catch((error) => {
            console.error("error", error);
          });


      }else{ //SI LA IMAGEN NO TERMINO SU PROCESAMIENTO OK
      
        fetch(REACT_APP_API_URL_GTM + "rasters/getImageRaw/"+props.imagesSetId+'/'+selectedImage?.aws_name, { method: "GET" })
        .then((response) => {
            return response.text()
              
        })
        .then((data) => {
          setSelectedImageUrl(data)
            
        }).catch((error) => {
            console.error("error", error);
          });

      }


      fetch(REACT_APP_API_URL_GTM + "rasters/getDetectionData", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ imageId: selectedImage?.id }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setImageData(data);
          setImageMetadata(data.metadata);
        })
        .catch((error) => {
          console.error("error", error);
        });



    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedImage !== null && imageData !== null && selectedImageUrl !== null) {
      initMap(selectedImage, imageData,selectedImageUrl);
    }
  }, [imageData,selectedImageUrl]);

  const changeImageSelect = (value) => {
    if (anyChanges()) {
      setAlertMessage("Hay cambios sin guardar!");
      setOpenAlert(true);
    } else {
      setSelectedImage(images[value]);
      setSelectedImageNumber(value);
    }
  };

  const changeImageButton = (move) => {
    if (selectedImage !== null) {
      if (anyChanges()) {
        setAlertMessage("Hay cambios sin guardar!");
        setOpenAlert(true);
      } else {
        let a = move === "prev" ? -1 : 1;
        let newKey = selectedImageNumber + a;
        if (newKey >= 0 && newKey < images.length) {
          let newImage = images[newKey];
          newImage["key"] = newKey;
          setSelectedImage(newImage);
          setSelectedImageNumber(newKey);
        }
      }
    }
  };

  const initMap = (img, data,imgUrl) => {
    //if (selectedImageUrl!=null) {
      if (map.current !== null) {
        map.current.off();
        map.current = map.current.remove();
        map.current = null;
      }
      document.getElementById("leafletMap").innerHTML =
        "<div id='map_gtm' style='width: 100%; height: 600px;'></div>";

      map.current = L.map("map_gtm").setView([0, 0], 1);

      // IMAGEN DE BASE
      let imageBounds = [
        [0, 0],
        [img.size.height / unitFactor, img.size.width / unitFactor],
      ];
      //let imgUrl = selectedImageUrl //REACT_APP_API_URL_GTM + "rasters/" + img["name"];
      imageLayer = L.imageOverlay(imgUrl, imageBounds);
      imageLayer.addTo(map.current);
      map.current.fitBounds(imageBounds);

      //OVERLAYS
      bounds_plants_layer.current = L.layerGroup();
      lines_layer = L.layerGroup();
      centroid_layer = L.layerGroup();
      var overlays = {
        "detecciones": bounds_plants_layer.current,
        "surcos": lines_layer,
        "centroides det.": centroid_layer,
        "ptas agregadas": window.drawnItems,
      };
      L.control.layers(null, overlays).addTo(map.current);

      bounds_plants_layer.current.removeLayer(map.current);
      lines_layer.removeLayer(map.current);
      centroid_layer.removeLayer(map.current);

      // ELEMENTO PARA DIBUJAR - VER public/static/leaflet/js/custom.js
      window.addDraw(map.current);
      map.current.addLayer(window.drawnItems);

      // CARGO LOS ELEMENTOS A VER EN LA IMAGEN

      if (data && map.current !== null) {
        let lines = data.img_rows.map(
          (r) => (img.size.height - r.max_px) / unitFactor
        );
        let centroids = data.plants.map((p) => [
          (img.size.height - p.centroid[0]) / unitFactor,
          p.centroid[1] / unitFactor,
        ]);
        let plant_bounds = data.plants.map((p) => {
          return {
            bounds: [
              [
                (img.size.height - p.bbox[0]) / unitFactor,
                p.bbox[1] / unitFactor,
              ],
              [
                (img.size.height - p.bbox[2]) / unitFactor,
                p.bbox[3] / unitFactor,
              ],
            ],
            id: p.id,
            confidence: p.confidence,
          };
        });

        //BOUNDS
        plant_bounds.map((b) =>
          bounds_plants_layer.current.addLayer(
            L.rectangle(b.bounds, {
              color: b.confidence > 0.6 ? "#0b07e9aa" : "#1ac2ecaa",
              fillColor:"#fff0", 
              weight: 2,
            }).on("click", function (e) {
              console.log("confidence", b.confidence);
              //askForDeletion(b.id, e.target._leaflet_id);
              deleteWithoutAsk(b.id, e.target._leaflet_id)
            })
          )
        );

        //LINES
        lines.map((l) => {
          var start = L.marker([l, 0]);
          var end = L.marker([l, selectedImage.size.width / unitFactor]);
          var line = L.polyline([start.getLatLng(), end.getLatLng()], {
            color: "#ff0a",
          });
          lines_layer.addLayer(line);
        });
        //CENTROIDS
        var centroidIcon = L.icon({
          iconUrl: "centroid-marker.png",
          iconSize: [6, 6],
          iconAnchor: [3, 3],
        });

        centroids.map((c) =>
          centroid_layer.addLayer(L.marker(c, { icon: centroidIcon }))
        );

        bounds_plants_layer.current.addTo(map.current);
        lines_layer.addTo(map.current)
      }
    //}
  };

  // SNACK DE ALERTA
  //================================================================================

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const anyChanges = () => {
    console.log('debug: ', 
      "delPlants",
      deletedPlants.length,
      "drawn",
      window.drawnItems.getLayers().length,
      "metadata",
      JSON.stringify(imageData?.metadata) === JSON.stringify(imageMetadata)
    );
    return (
      deletedPlants.length > 0 ||
      window.drawnItems.getLayers().length > 0 ||
      JSON.stringify(imageData?.metadata) !== JSON.stringify(imageMetadata)
    );
  };

  const saveModifications = () => {
    let newImageData = { ...imageData };
    let nuevos_layers = window.drawnItems.getLayers();
    let newPlants = [];
    let maxId = Math.max(...newImageData.plants.map((p) => p.id));

    nuevos_layers.map((l) => {
      maxId++;
      var objects = l.getBounds();
      var sw = objects.getSouthWest();
      var ne = objects.getNorthEast();
      newPlants.push({
        id: maxId,
        bbox: [
          parseInt(selectedImage.size.height - ne.lat * unitFactor),
          parseInt(sw.lng * unitFactor),
          parseInt(selectedImage.size.height - sw.lat * unitFactor),
          parseInt(ne.lng * unitFactor),
        ],
        centroid: [
          parseInt(
            (selectedImage.size.height -
              ne.lat * unitFactor +
              (selectedImage.size.height - sw.lat * unitFactor)) /
              2
          ),
          parseInt((sw.lng * unitFactor + ne.lng * unitFactor) / 2),
        ],
        area: null,
        confidence: 1,
        row: null,
        source: "manual",
      });
    });

    newImageData.plants = newImageData.plants.concat(newPlants);
    console.log('debug deleted plants all', deletedPlants)
    for (let d of deletedPlants) {
      console.log('debug deleted plant', d)
      newImageData.plants_eliminated.push(
        newImageData.plants.filter((p) => p.id === d)
      );
      newImageData.plants = newImageData.plants.filter((p) => p.id !== d);
    }
    newImageData.processes.push({
      type: "Manual Correction",
      timeStamp: new Date().toLocaleString("en-GB", { timeZone: "UTC" }),
    });

    newImageData["metadata"] = { ...imageMetadata };
    console.log('debug newImageData',newImageData)
    fetch(REACT_APP_API_URL_GTM + "rasters/updateDetectionData", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        selectedImage: selectedImage,
        imageData: newImageData,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setDeletedPlants([]) // deletedPlants=[] //;
          window.resetDraw();
          setSelectedImage({ ...selectedImage });
          // Borra los cambios guardados y los vuelve a traer desde la api!
        } else {
          throw "Error en la API";
        }
      })
      .catch((error) => {
        setAlertMessage("No se pudo guardar la info");
        setOpenAlert(true);
        console.error("error", error);
      });
  };

  const cancelModifications = () => {
    console.log("Cancelo!!!");
    setDeletedPlants([]);// deletedPlants=[] //
    window.deleteAllRectangle();
    setImageData({ ...imageData }); //Recargo la imagen y la info
    setImageMetadata({ ...imageMetadata.metadata });
  };

  const updateMetadata = (field, value) => {
    let newMetadata = {};
    if (imageMetadata !== null) {
      newMetadata = { ...imageMetadata };
    }
    newMetadata[field] = value;
    setImageMetadata(newMetadata);
  };

  const getMetadataField = (field) => {
    try {
      return imageMetadata[field];
    } catch {
      return "";
    }
  };
  // VOY ANOTANDO CUALES SON LAS PLANTAS DETECTADAS A GUARDAR Y LAS SACO DEL MAPA
  // ============================================================================
  const [deletedPlants, setDeletedPlants] = useState([]);
  // const [deleteCandidate, setDeleteCandidate] = useState({});
  // const deletedPlants=[]
  // const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  function deleteWithoutAsk(plantId, leaflet_id) {

    setDeletedPlants( deletedPlants => {
        return [...deletedPlants, plantId];
    });
    bounds_plants_layer.current.eachLayer((l) => {
      if (l._leaflet_id === leaflet_id) {
        bounds_plants_layer.current.removeLayer(l);
      }
    });
  
  
  }

  // function askForDeletion(plantId, leaflet_id) {
  //   setDeleteCandidate({ plantId, leaflet_id });
  //   setOpenDeleteDialog(true);
  // }

  // const deleteDetectedPlant = () => {
  //   let deletedPlants_ = [...deletedPlants];
  //   deletedPlants_.push(deleteCandidate.plantId);
  //   setDeletedPlants(deletedPlants_);
  //   bounds_plants_layer.current.eachLayer((l) => {
  //     if (l._leaflet_id === deleteCandidate.leaflet_id) {
  //       bounds_plants_layer.current.removeLayer(l);
  //     }
  //   });
  //   setDeleteCandidate(null);
  //   handleCloseDeleteDialog();
  // };

  // const handleCloseDeleteDialog = () => {
  //   setOpenDeleteDialog(false);
  // };

  // TECLAS RÁPIDAS PARA EDITAR

  document.addEventListener("keypress", function (event) {
    console.log(event.key);
    if (event.key === "p") {
      //P
      window.initDrawPolygon();
    }
    // if (event.key === "e") {
    //   //E
    //   window.initDelete();
    // }
  });

  return (
    <>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.3/dist/leaflet.css"
        integrity="sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI="
        crossOrigin=""
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.4.2/leaflet.draw.css"
      />
      {/*  SELECCIONAR IMAGEN + GUARDAR + CANCELAR 
      ======================================================================================================================
      */}
      <div
        style={{
          display: "flex",
          width: "100%,",
          borderBottom: "1px solid #aaa",
        }}
      >
        <div style={{ display: "flex", margin: "10px" }}>
          <FormControl  style={{ margin: "5px", width: "400px" }}>
            <InputLabel id="demo-simple-select-label">
              Seleccione una Imagen
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedImageNumber !== null ? selectedImageNumber : ""}
              label="Image"
              onChange={(val) => {
                changeImageSelect(val.target.value);
              }}
            >
              {images.map((item, key) => (
                <MenuItem key={key} value={key} name={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            disabled={selectedImageNumber === 0}
            style={{ margin: "5px" }}
            onClick={() => changeImageButton("prev")}
          >
            {" "}
            &#60;prev{" "}
          </Button>
          <div
            style={{
              display: "flex",
              transform: "translateY(25%)",
              justifyContent: "center",
              width: "75px",
              height: "40px",
              margin: "5px",
            }}
          >
            {selectedImage === null
              ? "   "
              : selectedImageNumber + 1 + "/" + images.length}
          </div>
          <Button
            variant="outlined"
            disabled={selectedImageNumber === images.length - 1}
            style={{ margin: "5px" }}
            onClick={() => changeImageButton("next")}
          >
            {" "}
            next&#62;{" "}
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              margin: "5px",
              padding: "10px",
              backgroundColor: "#ffb",
              fontSize: "0.7rem",
              borderRadius: "10px",
            }}
          >
            {" "}
            Eliminar detección (click){" "}
          </div>
          <div
            style={{
              margin: "5px",
              padding: "10px",
              backgroundColor: "#fbb",
              fontSize: "0.7rem",
              borderRadius: "10px",
            }}
          >
            {" "}
            Agregar planta (P){" "}
          </div>
        </div>
        
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
            width: "100%",
          }}
        >
          <div style={{width:'1px', marginRight:'25px', marginLeft:'25px', borderRight:'1px solid gray'}}></div>
          <Button
            variant="outlined"
            color="error"
            style={{ margin: "5px" }}
            onClick={() => cancelModifications()}
          >
            {" "}
            Cancelar CAMBIOS IMG{" "}
          </Button>
          <Button
            variant="outlined"
            color="success"
            style={{ margin: "5px" }}
            onClick={() => saveModifications()}
          >
            {" "}
            Guardar CAMBIOS IMG{" "}
          </Button>
          <div style={{width:'1px', marginRight:'25px', marginLeft:'25px', borderRight:'1px solid gray'}}></div>
          <Button
            variant="outlined"
            color="info"
            style={{ margin: "5px" }}
            onClick={() => props.handleClose() }
          >
            {" "}
            FINALIZAR CHEQUEO IMGSET{" "}
          </Button>
        </div>
      </div>
      {/*  PROCESOS REALIZADOS 
      ======================================================================================================================
      */}
      <div
        style={{
          width: "100%",
          paddingTop: '5px',
          paddingBottom: '5px',
          borderBottom: "1px solid #aaa",
          //padding: "10px",
          
        }}
      >
        {imageData?.processes != null ? (
          <div style={{ display: "flex" }}>
            {imageData.processes.map((p, i) => (
              <>
                {i !== 0 && (
                  <div
                    style={{
                      fontSize: "0.8rem",
                      color: "#55c",
                      padding: "4px",
                      marginRight: "5px",
                    }}
                  >
                    {" "}
                    {">"}{" "}
                  </div>
                )}
                <div
                  title={p.timeStamp}
                  style={{
                    backgroundColor: "#2c50c7",
                    fontSize: "0.8rem",
                    color: "#fff",
                    padding: "4px",
                    marginRight: "5px",
                    borderRadius: "7px",
                  }}
                >
                  {p.type}
                </div>
              </>
            ))}
            <div
              style={{
                fontSize: "0.8rem",
                color: "#55c",
                padding: "4px",
                marginRight: "5px",
              }}
            >
              {" "}
              {">"}{" "}
            </div>
            {imageData.status === "OK" ? (
              <div
                style={{
                  backgroundColor: "#11b347",
                  fontSize: "0.8rem",
                  color: "#fff",
                  padding: "4px",
                  marginRight: "5px",
                  borderRadius: "7px",
                }}
              >
                OK{" "}
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#a82323",
                  fontSize: "0.8rem",
                  color: "#fff",
                  padding: "4px",
                  marginRight: "5px",
                  borderRadius: "7px",
                }}
              >
                imageData.error
              </div>
            )}
          </div>
        ) : (
          <div>Sin procesos</div>
        )}
      </div>

    {/* MAPA y METADATOS
    ======================================================================================================================
    */}
    <div style={{
      display:'flex',
    }}>
      {selectedImage === null ? (
        <p>Seleccione una imagen </p>
      ) : (
        <div id="leafletMap" style={{width: '85%'}}> 
          <div
            id="map_gtm"
            style={{
              textAlign: "center",
              borderRadius: "0px",
              height: "800px",
              border: "1px solid",
            }}
          ></div>
        </div>
      )}
      <div style={{width: '15%'}}>
        <FormControl size="small" style={{ marginBottom: "5px" }}>
          <Typography>
            {Object.keys(imageMetadataFields).map((field) => (
              <FormControl
                size="small"
                style={{ margin: "5px", width: "250px" }}
              >
                <InputLabel id="demo-simple-select-label">{field}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={getMetadataField(field)}
                  size="small"
                  onChange={(val) => {
                    updateMetadata(field, val.target.value);
                  }}
                >
                  {imageMetadataFields[field].map((item, key) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
                
              </FormControl>
            ))}
            <FormControl size="small" style={{ marginTop: "5px" }}>
            <InputLabel id="demo-simple-select-label">Comentarios</InputLabel>
            <TextField
              style={{ width: "100%", paddingLeft:"5px", paddingTop: "30px" }}
              size='small'
              id="outlined-multiline-static"
              //label="Comentarios"
              value={getMetadataField("comments")}
              onChange={(val) => {
                updateMetadata("comments", val.target.value);
              }}
              //multiline
              //rows={1}
            />
            </FormControl>
          </Typography>
        </FormControl>
        </div>
      </div>

      {/* MODALES Y SNACKS ========================================================================== */}

      {/* <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Desea borrar esta detección?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
          <Button onClick={deleteDetectedPlant}>Borrar</Button>
        </DialogActions>
      </Dialog> */}

      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        sx={{ width: "100%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MyLeaflet;
